import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss']
})
export class YesNoDialogComponent {

  message;
  yesText = 'כן';
  noText = 'לא';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<YesNoDialogComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog) {

    this.message = data.message;
    this.yesText = data.yesText || 'כן';
    this.noText = data.noText || 'לא';
  }


  answer(isYes) {
    this.dialogRef.close(isYes);
  }

  ngOnInit() {
  }

}
