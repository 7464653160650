import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent {

  data: any;

  imageUrl;

  constructor(@Inject(MAT_DIALOG_DATA) public d: any, public dialogRef: MatDialogRef<ImageDialogComponent>,
              public httpService: HttpService) {


    if (d && d.imageUrl) {
      this.imageUrl = d.imageUrl;
    }
  }

  imageChangedEvent: any = '';
  croppedImage;

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  /*fileChangeListener($event) {
    let image: any = new Image();
    let file: File = $event.target.files[0];
    let myReader: FileReader = new FileReader();
    let that = this;
    myReader.onloadend = function(loadEvent: any) {
      image.src = loadEvent.target.result;
      that.cropper.setImage(image);
    };

    myReader.readAsDataURL(file);
  }

  async uploadFile() {
    // console.log(this.data);
    // console.log(this.data.image);

    let file = this.dataURLtoFile(this.data.image, 'cropped');
    console.log(file);
    this.dialogRef.close(file);
  }

  close() {
    this.dialogRef.close();
  }


*/
  save() {
    // console.log(this.croppedImage);
    let file = this.dataURLtoFile(this.croppedImage, 'cropped');
    // console.log(file);
    this.dialogRef.close(file);
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }
}
