<button *ngIf="false" class="dialogCloseButton" (click)="close()">X</button>
<app-loader *ngIf="showLoader"></app-loader>

<div class="bg-success d-block text-center p-2">
  <h4 class="text-white">{{getTitle()}}</h4>
</div>

<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <div class="d-flex flex-column justify-content-center align-items-center p-3" style="direction: rtl">

    <label *ngIf="defaultPercentage">{{getCurrentPercentage()}}</label>
    <label *ngIf="defaultHabitCommission">{{getCurrentHabitFunding()}}</label>

    <div *ngIf="isOffline" class="list-group m-0 p-0  mt-3">
      <li *ngIf="false" class="list-group-item active text-white text-center" style="direction: rtl">קמפיין עבור קניות
        בחנות
      </li>

      <div class="row">
        <div class="col-12 text-center">
          <div class="d-flex flex-column align-items-center mt-2 justify-content-center">
            <input *ngIf="false" [max]="100" [min]="0" type="number" class="form-control w-75"
                   [(ngModel)]="offlineDiscount" placeholder="עמלת הביט">

            <p class="ml-2 p-0 m-0">הגדל עמלת הביט לקמפיין זה</p>

            <select [(ngModel)]="selectedPercentage" (change)="onChange($event.target.value)"
                    class="ml-2 form-control w-50">
              <option *ngFor="let p of percentages" [value]="p.value">{{p.title}}</option>
            </select>

            <label>{{getExpectedFuturePayments()}}</label>


            <div class="d-flex flex-row mt-3">
              <label class="mr-2 ml-2">מצב תמונה מלאה</label>
              <mat-slide-toggle [(ngModel)]="isFullPageImage" color="primary"></mat-slide-toggle>
            </div>


          </div>

        </div>
      </div>
    </div>


    <ul *ngIf="!isOffline" class="list-group m-0 p-0 mt-3">
      <li *ngIf="false" class="list-group-item active text-white text-center" style="direction: rtl">קמפיין עבור קניות
        אונליין
      </li>
      <li class="list-group-item">

        <div class="row">

          <div class="col-12 text-center">
            <label>הוסף מוצר</label>

            <div class="d-flex flex-row align-items-center justify-content-start">
              <app-search hint="הוסף מוצר לקמפיין" class="w-50 ml-3" [customers]="false" [products]="true"
                          (itemSelected)="newProduct = $event"></app-search>

              <select [(ngModel)]="selectedPercentage" (change)="onChange($event.target.value)"
                      class="ml-2 form-control w-50">
                <option *ngFor="let p of percentages" [value]="p.value">{{p.title}}</option>
              </select>

              <!--<mat-form-field *ngIf="false" class="text-right ml-3">
                <input  #discount [max]="100" [min]="0" type="number"
                       matInput [(ngModel)]="offlineDiscount" placeholder="אחוז החזר הביט">
              </mat-form-field>-->


            </div>
          </div>

          <div class="col-12 text-center mt-2">
            <label>הוסף קטגוריה</label>

            <div class="d-flex flex-row align-items-center justify-content-start">

              <mat-form-field class="text-right ml-3 w-50">
                <mat-select #select required>
                  <mat-option>בחר קטגוריה</mat-option>
                  <mat-option *ngFor="let category of categories" value="{{category.id}}">{{category.name}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="text-right ml-3">
                <input #discount [max]="100" [min]="0" type="number"
                       matInput [(ngModel)]="offlineDiscount" placeholder="אחוז החזר הביט">
              </mat-form-field>


              <button (click)="addCategory(select.value, discount.value)" class="btn btn-success btn-sm">הוסף</button>

            </div>
          </div>


          <div class="col-12 text-center">
            <div class="d-flex flex-column align-items-center justify-content-center mt-4">

              <p class="m-0 p-0">מוצרים\קטגוריות שהוספת לקמפיין</p>


              <button *ngIf="!isOffline" (click)="addProduct(discount.value)" class="btn btn-success btn-sm mt-4">הוסף
              </button>


            </div>
          </div>

        </div>


      </li>

    </ul>

    <div class="flex-column align-items-center justify-content-center">


      <div class="d-flex flex-column align-items-center mt-2 justify-content-center">
        <label class="m-0 p-0 ml-3">זמן תחילת קמפיין</label>
        <ejs-datetimepicker class="mt-2" [(ngModel)]="startDate"></ejs-datetimepicker>

        <!--<input type="datetime-local" class="form-control input-width"
               [(ngModel)]="startDate">-->
      </div>

      <div class="d-flex flex-column align-items-center mt-4 justify-content-center">
        <label class="m-0 p-0 ml-3 ">זמן סיום קמפיין</label>
        <ejs-datetimepicker class="mt-2" [(ngModel)]="endDate"></ejs-datetimepicker>

        <!--<input type="datetime-local" class="form-control input-width"
               [(ngModel)]="endDate">-->
      </div>

    </div>

    <button *ngIf="isOffline" (click)="createCampaignOffline()"
            class="btn btn-success mr-2 mt-4">{{isEdit ? 'עדכן' : 'צור קמפיין'}}</button>


    <button *ngIf="!isOffline" (click)="createCampaignOnline()" class="btn btn-success mt-4">צור קמפיין
    </button>
  </div>
</div>
