<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <div class="d-flex flex-column justify-content-center p-3" style="direction: rtl">


    <!-- details -->

    <div class="row">

      <h4>{{message}}</h4>

    </div>


    <div class="d-flex  align-items-center justify-content-center">
      <button (click)="answer(true)" class="btn btn-primary m-2 btn-block">{{yesText}}</button>
      <button (click)="answer(false)" class="btn btn-danger m-2 btn-block">{{noText}}</button>

    </div>

  </div>
</div>
