<button class="dialogCloseButton" (click)="close()">X</button>


<div *ngIf="isNew" class="bg-success d-block text-center p-2">
  <h4 class="text-white">פעולה חדשה</h4>
</div>

<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <div class="d-flex flex-column justify-content-center p-3" style="direction: rtl">


    <!-- Chain details -->

    <div class="row">

      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li
            class="list-group-item active text-white text-center" style="direction: rtl">
            <!--
                        <p class="p-0 m-0">{{getTitle()}}</p>
            -->
            <p class="p-0 m-0">פרטי העברה: <span class="font-weight-bolder h4">{{transfer.supplier.name}}</span></p>
            <div class="alert alert-primary text-center mt-3" role="alert">
              <p class="p-0 m-0">העברה על סך {{formatPrice(transfer.amount)}}</p>
            </div>
          <li class="list-group-item">

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">סכום העברה</label>
              <input class="form-control input-width-sm" [ngModel]="paidAmount">
            </div>


            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">אסמכתא</label>
              <input class="form-control input-width-sm" [(ngModel)]="transfer.reference">
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">תאריך העברה</label>
              <input type="date" class="form-control input-width-sm"
                     [(ngModel)]="transfer.transferDate">
            </div>
          </li>

        </ul>


        <div class="alert alert-primary text-center" role="alert">
          <p class="p-0 m-0">{{getBankDetails()}}</p>
        </div>
      </div>

    </div>


    <div class="row mt-3">

      <div class="col-12">
        <button *ngIf="transfer.status === 'open'" (click)="save(true)" class="btn btn-primary btn-block">סגור תשלום
        </button>
      </div>

      <div class="col-12 mt-2">
        <button (click)="save()" class="btn btn-success btn-block">שמירה</button>
      </div>
    </div>

  </div>
</div>
