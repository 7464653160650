<div class="container-fluid">

  <app-loader *ngIf="showLoader"></app-loader>

  <div *ngIf="statusResponse" class="row mt-2 pl-3 pr-3">

    <div *ngIf="shouldShowOnlineOrders()" class="col mt-3">
      <div class="my-card card-bg p-2" style="cursor: pointer" routerLink="/online-orders">
        <div class="d-flex flex-row align-content-center">
          <mat-icon *ngIf="false" aria-hidden="false" class="align-self-center text-white mat-icon-50">credit_card
          </mat-icon>


          <div class="d-flex flex-column justify-content-center flex-fill text-center">
            <h5 class="text-white text-center m-0">קניות אונליין</h5>
            <p
              class="m-0 text-sm-center small rtl"
              style="direction: rtl">{{getCountText(statusResponse.onlineOrders.count, statusResponse.onlineOrders.futurePaymentsCount) }}
            </p>

            <div class="d-flex flex-column mt-2">

              <div class="row">
                <div class="col" *ngIf="false">
                  <h6 class="text-white">פעולות</h6>
                  <h4 class="text-white">{{statusResponse.onlineOrders.count}}</h4>
                </div>


                <div class="col">
                  <h6 class="text-white">רווח</h6>
                  <h4 class="text-white">{{statusResponse.onlineOrders.profit}}₪</h4>
                </div>

                <div class="col">
                  <h6 class="text-white">הכנסות</h6>
                  <h4 class="text-white">{{statusResponse.onlineOrders.total}}₪</h4>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col">
                  <h6 class="text-white">מומנו</h6>
                  <h4 class="text-white">{{statusResponse.onlineOrders.futurePaymentsTotal}}₪</h4>
                </div>

                <div class="col" *ngIf="false">
                  <h6 class="text-white">פעולות עם מימון</h6>
                  <h4 class="text-white">{{statusResponse.onlineOrders.futurePaymentsCount}}</h4>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="shouldShowOfflineOrders()" class="col mt-3" style="cursor: pointer">
      <div class="my-card card-bg p-2" routerLink="/offline-orders">
        <div class="d-flex flex-row align-content-center">
          <mat-icon *ngIf="false" aria-hidden="false" class="align-self-center text-white mat-icon-50">credit_card
          </mat-icon>


          <div class="d-flex flex-column justify-content-center flex-fill text-center">
            <h5 class="text-white text-center m-0">קניות בחנות</h5>
            <p
              class="m-0 text-sm-center small rtl"
              style="direction: rtl">{{getCountText(statusResponse.offlineOrders.count, statusResponse.offlineOrders.futurePaymentsCount) }}
            </p>

            <div class="d-flex flex-column mt-2">

              <div class="row">
                <div class="col" *ngIf="false">
                  <h6 class="text-white">פעולות</h6>
                  <h4 class="text-white">{{statusResponse.offlineOrders.count}}</h4>
                </div>

                <div class="col">
                  <h6 class="text-white">רווח</h6>
                  <h4 class="text-white">{{statusResponse.offlineOrders.profit}}₪</h4>
                </div>

                <div class="col">
                  <h6 class="text-white">הכנסות</h6>
                  <h4 class="text-white">{{statusResponse.offlineOrders.total}}₪</h4>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col">
                  <h6 class="text-white">מומנו</h6>
                  <h4 class="text-white">{{statusResponse.offlineOrders.futurePaymentsTotal}}₪</h4>
                </div>

                <div class="col" *ngIf="false">
                  <h6 class="text-white">פעולות עם מימון</h6>
                  <h4 class="text-white">{{statusResponse.offlineOrders.futurePaymentsCount}}</h4>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="isAdmin()" class="col mt-3">
      <div class="my-card card-bg p-2" style="cursor: pointer" routerLink="/user-to-user">
        <div class="d-flex flex-row align-content-center">
          <mat-icon *ngIf="false" aria-hidden="false" class="align-self-center text-white mat-icon-50">credit_card
          </mat-icon>


          <div class="d-flex flex-column justify-content-center flex-fill text-center">
            <h5 class="text-white text-center m-0">העברות בין משתמשים</h5>
            <p
              class="m-0 text-sm-center small rtl"
              style="direction: rtl">{{getCountText(statusResponse.userToUserTransfers.count, statusResponse.userToUserTransfers.futurePaymentsCount) }}</p>
            <div class="d-flex flex-column mt-2">

              <div class="row">
                <div class="col" *ngIf="false">
                  <h6 class="text-white">פעולות</h6>
                  <h4 class="text-white">{{statusResponse.userToUserTransfers.count}}</h4>
                </div>

                <div class="col">
                  <h6 class="text-white">מומנו</h6>
                  <h4 class="text-white">{{statusResponse.onlineOrders.futurePaymentsTotal}}₪</h4>
                </div>

                <div class="col">
                  <h6 class="text-white">סה"כ </h6>
                  <h4 class="text-white">{{statusResponse.userToUserTransfers.total}}₪</h4>
                </div>


              </div>

              <div class="row mt-2">


                <div class="col" *ngIf="false">
                  <h6 class="text-white">פעולות עם מימון</h6>
                  <h4 class="text-white">{{statusResponse.onlineOrders.futurePaymentsCount}}</h4>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isAdmin()" class="col mt-3">
      <div class="my-card card-bg p-2" style="cursor: pointer" routerLink="/bank-transfers">
        <div class="d-flex flex-row align-content-center">
          <mat-icon *ngIf="false" aria-hidden="false" class="align-self-center text-white mat-icon-50">credit_card
          </mat-icon>


          <div class="d-flex flex-column justify-content-center flex-fill text-center">
            <div class="d-flex flex-column">
              <h5 class="text-white text-center m-0">העברות בנקאיות</h5>
              <p
                class="m-0 text-sm-center small"
                style="direction: rtl">{{getCountText(statusResponse.bankTransfers.count, statusResponse.bankTransfers.futurePaymentsCount) }}</p>
            </div>
            <div class="d-flex flex-column mt-2">

              <div class="row text-center">
                <div class="col text-center" *ngIf="false">
                  <h6 class="text-white">פעולות</h6>
                  <h4 class="text-white">{{statusResponse.bankTransfers.count}}</h4>
                </div>


                <div class="col text-center">
                  <h6 class="text-white">מומנו</h6>
                  <h4 class="text-white">{{statusResponse.bankTransfers.futurePaymentsTotal}}₪</h4>
                </div>

                <div class="col text-center">
                  <h6 class="text-white">סה"כ</h6>
                  <h4 class="text-white">{{statusResponse.bankTransfers.total}}₪</h4>
                </div>

              </div>

              <div class="row mt-2">


                <div class="col" *ngIf="false">
                  <h6 class="text-white">פעולות עם מימון</h6>
                  <h4 class="text-white">{{statusResponse.bankTransfers.futurePaymentsCount}}</h4>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isAdmin()" class="col mt-3">
      <div class="my-card card-bg p-2" style="cursor: pointer" routerLink="/leverages">
        <div class="d-flex flex-row align-content-center">
          <mat-icon *ngIf="false" aria-hidden="false" class="align-self-center text-white mat-icon-50">credit_card
          </mat-icon>

          <div class="d-flex flex-column justify-content-center flex-fill text-center">
            <div class="d-flex flex-column">
              <h5 class="text-white text-center m-0">מינופים</h5>
              <p class="m-0 text-sm-center small"
                 style="direction: rtl">{{getCountText(statusResponse.leverage.count, null)}} </p>
            </div>

            <div class="d-flex flex-column mt-2">
              <div class="row">
                <div class="col">
                  <h6 class="text-white">סה"כ</h6>
                  <h4 class="text-white">{{statusResponse.leverage.total}}₪</h4>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

  <div *ngIf="statusResponse" class="mt-5">
    <hr>
    <div class="col-12 text-center">
      <p class="text-dark">נתונים גרפיים - קניות בחנות</p>
    </div>
  </div>
  <!--  Charts -->
  <div *ngIf="statusResponse" class="row p-4 mt-2">

    <div class="col my-card ml-1 mr-1">
      <div style="display: block">
        <canvas baseChart
                [datasets]="barChart1Data"
                [labels]="barChart1Labels"
                [options]="barChartOptions"
                [legend]="barChart1Legend"
                [chartType]="barChart1Type">
        </canvas>
      </div>
    </div>

    <div class="col my-card ml-1 mr-1">
      <div style="display: block">
        <canvas baseChart
                [datasets]="barChart2Data"
                [labels]="barChart2Labels"
                [options]="barChartOptions"
                [legend]="barChart2Legend"
                [chartType]="barChart2Type">
        </canvas>
      </div>
    </div>

  </div>
</div>
