<div class="bg-success d-block text-center p-2">
  <h4 class="text-white p-0 m-0">רשימת מוזמנים</h4>
</div>


<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <table class="table table-hover mt-2" style="direction: rtl">
    <thead class="text-right">
    <tr>
      <th>לקוח</th>
      <th>תאריך הצטרפות</th>
      <th>תאריך זיכוי</th>
      <th>בונוס שחולק</th>
      <th>סך תשלומים</th>
    </tr>
    </thead>


    <tbody class="text-right">
    <tr *ngFor="let result of inviteItem.invitees; let i = index">
      <td>{{getFullName(result.customer.name)}}</td>
      <td>{{formatDate(result.joined)}}</td>
      <td>{{formatDate(result.rewardDate)}}</td>
      <td>{{formatPrice(result.rewardGiven)}}</td>
      <td>{{formatPrice(result.totalPayments)}}</td>
    </tr>
    </tbody>
  </table>

</div>
