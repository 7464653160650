import {Component, Inject} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';
import {EditSupplierDialogComponent} from '../edit-supplier-dialog/edit-supplier-dialog.component';

@Component({
  selector: 'app-create-campaign-dialog',
  templateUrl: './create-campaign-dialog.component.html',
  styleUrls: ['./create-campaign-dialog.component.scss']
})
export class CreateCampaignDialogComponent extends BaseDialogComponent<CreateCampaignDialogComponent> {

  supplierId;
  campaignId; // for edit mode
  startDate = new Date();
  endDate;
  isOffline;

  isOfflineDiscount = false;


  offlineDiscount;
  onlineDiscount = [];
  categories = [];

  // online data item

  newProduct;

  percentages = [];
  selectedPercentage;


  defaultPercentage;
  futurePaymentsPercentage;
  defaultHabitCommission;

  campaign;

  isEdit = false;
  isFullPageImage = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CreateCampaignDialogComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.supplierId = data.supplierId;
    this.isOffline = data.isOffline;

    this.endDate = new Date();
    this.endDate.setDate(new Date().getDate() + (1));

    this.loadData();
    this.updatePercentagesList();


    this.selectedPercentage = this.percentages[20];


    if (data.campaign) {
      // edit
      this.isEdit = true;
      this.onlineDiscount = data.campaign.onlineDiscount;
      this.offlineDiscount = data.campaign.offlineDiscount;
      this.startDate = data.campaign.startDate;
      this.endDate = data.campaign.endDate;
      this.isFullPageImage = data.campaign.isFullPageImage;
      this.campaignId = data.campaign.id;
      this.supplierId = data.campaign.supplier.id;

      // offline
      this.selectedPercentage = data.campaign.onlineDiscount;

      console.log(data.campaign);
    }
  }

  updatePercentagesList() {
    this.percentages = [];
    let min = ((this.defaultHabitCommission || 0.01) * 100);
    for (let i = min; i <= 100; ++i) {
      this.percentages.push({
        title: i.toString() + '%',
        value: i / 100
      });
    }
  }

  async loadData() {
    try {
      const res = await this.httpService.getCategories(this.supplierId);
      console.log(res);
      this.categories = res['result'];
    } catch (e) {
      console.error(e);
    }
    this.loadPercentages(0);
  }

  getTitle() {
    return this.isOffline ? 'קמפיין קניות בחנות' : 'קמפיין קניות אונליין';
  }

  async createCampaignOffline() {

    // console.log(JSON.stringify(this.selectedPercentage));
    // return;


    if (!this.offlineDiscount) {
      this.dataService.okDialog('יש לבחור אחוז עמלה');
      return;
    }

    if (!this.startDate || !this.endDate) {
      this.dataService.okDialog('יש לבחור תאריכי התחלה וסיום קמפיין');
      return;
    }
    /*
        if ( this.startDate.getTime() < Date.now()) {
          this.dataService.okDialog('תאריך תחילת הקמפיין כבר עבר');
          return;
        }*/


    let campaign = {
      startDate: this.startDate,
      endDate: this.endDate,
      supplierId: this.supplierId,
      offlineDiscount: this.offlineDiscount,
      onlineDiscount: [],
      isFullPageImage: this.isFullPageImage
    };

    this.showLoader = true;

    let res;
    if (this.isEdit) {
      campaign['campaignId'] = this.campaignId;
      res = await this.httpService.editCampaign(campaign);

    } else {
      res = await this.httpService.crateCampaign(campaign);
    }
    console.log(res);

    this.showLoader = false;
    this.dialogRef.close(true);
  }

  async createCampaignOnline() {

    var list = [];
    list.push({
      discountPercent: 0.3,
      categoryId: undefined,
      productId: '5bd8a689b498aa000ebac58c'
    });

    const campaign = {
      startDate: this.startDate,
      endDate: this.endDate,
      supplierId: this.supplierId,
      onlineDiscount: list
    };

    this.showLoader = true;
    const res = await this.httpService.crateCampaign(campaign);
    console.log(res);

    this.showLoader = false;
    this.dialogRef.close(true);

  }

  addProduct(discountPercent) {

    const item = {
      discountPercent,
      categoryId: undefined,
      productId: this.newProduct.id,
      productName: this.newProduct.name
    };

    this.onlineDiscount.push(item);
  }

  addCategory(categoryId, discount) {
    console.log(categoryId, discount);
  }


  async onChange(val) {
    // console.log(val);
    // console.log(this.selectedPercentage.value);
    this.loadPercentages(val);

    this.offlineDiscount = val;
    // console.log(defaultPercentage);
    // console.log(futurePaymentsPercentage);
  }


  async loadPercentages(val) {
    const res = await this.httpService.getExpectedFuturePayments(val, this.supplierId);
    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      if (status == 'NO_VOUCHER_AVAILABLE') {
        const isYes = await this.dataService.yesNoDialog('לא הוגדרה שיטת תשלום עבור עסק זה, האם תרצה להגידר כעת?');
        if (isYes) {
          const tmp = await this.httpService.getSupplier(this.supplierId);
          const supplier = tmp['result']['list'][0];
          console.log(supplier);
          this.showSupplierDialog(supplier);
        }
        return;
      }
      this.dataService.okDialog(status);
      return;
    }

    this.defaultPercentage = res['result']['defaultPercentage']; // מימון ברירת מחדל
    this.futurePaymentsPercentage = res['result']['futurePaymentsPercentage']; // אחוז מימון צפוי
    this.defaultHabitCommission = res['result']['defaultHabitCommission']; // עמלת ברירת מחדל ללקוח

    this.updatePercentagesList();
    // console.log(res);
  }

  showSupplierDialog(supplier) {

    const dialogRef = this.dialog.open(EditSupplierDialogComponent, {
      width: '90vw',
      maxWidth: '600px',
      height: '80vh',
      panelClass: 'dialog-style',
      data: {
        supplier
      }
    });

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('after close', result);
      if (!result) {
        return;
      }

      if (supplier != null) {
        Object.assign(supplier, result);
      }

      //  this.loadData();
    });
  }

  getCurrentPercentage() {
    if (!this.defaultPercentage) {
      return '';
    }
    return 'עמלת הביט ברירת מחדל: ' + this.defaultHabitCommission * 100 + '%';
  }

  getExpectedFuturePayments() {
    if (!this.futurePaymentsPercentage) {
      return '';
    }

    return 'מימון צפוי ללקוח: ' + Math.round(this.futurePaymentsPercentage * 100) + '%';
  }

  getCurrentHabitFunding() {
    return 'מימון הביט: ' + (this.defaultPercentage * 100).toFixed(1) + '%';
  }


}
