<app-loader *ngIf="showLoader"></app-loader>

<div class="container">


  <div class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div class="row mt-3" style="direction: rtl">
    <div *ngIf="false" class="col p-0 m-0">
      <div class="d-flex flex-row" style="direction: rtl">
        <input class="form-control ml-2 w-25" type="text" placeholder="הזן שם לקוח" #searchField>
        <button (click)="search(searchField.value)" class="btn btn-primary">חפש</button>
      </div>
    </div>

    <app-search hint="הזן שם לקוח" suppliers=false (itemSelected)="search($event)"></app-search>

  </div>

  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">
 <span style="direction: rtl">
          <label class="ml-3">{{total.count}} עסקאות </label>
          <label class="ml-3">{{formatPrice(total.amount)}}</label>
    </span>
    <span style="cursor: pointer">
       <label style="color: #107C41; cursor: pointer">שמור כקובץ אקסל</label>
       <img src="assets/excel.png" width="35px" height="50px">
    </span>

  </div>

  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="loadData()">
    <table *ngIf="results" class="table table-hover mt-3" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th (click)="setSortValue('created')" scope="col">
          <span *ngIf="sortValue === 'created'">↑</span>
          <span *ngIf="sortValue === '-created'">↓</span>
          תאריך
        </th>
        <th (click)="setSortValue('amount')" scope="col">
          <span *ngIf="sortValue === 'amount'">↑</span>
          <span *ngIf="sortValue === '-amount'">↓</span>
          <p class="m-0">סכום ({{formatPrice(total.amount)}})</p>
        </th>
        <th (click)="setSortValue('futurePaymentsAmount')" scope="col">
          <span *ngIf="sortValue === 'futurePaymentsAmount'">↑</span>
          <span *ngIf="sortValue === '-futurePaymentsAmount'">↓</span>
          <p class="m-0">סכום מימון ({{formatPrice(total.futurePaymentsAmount)}})</p>
        </th>
        <th (click)="setSortValue('customerId')" scope="col">
          <span *ngIf="sortValue === 'customerId'">↑</span>
          <span *ngIf="sortValue === '-customerId'">↓</span>
          מעביר
        </th>
        <th (click)="setSortValue('status')" scope="col">
          <span *ngIf="sortValue === 'status'">↑</span>
          <span *ngIf="sortValue === '-status'">↓</span>
          סטטוס
        </th>

        <th (click)="setSortValue('transferDate')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'transferDate'">↑</span>
          <span *ngIf="sortValue === '-transferDate'">↓</span>
          תאריך העברה
        </th>

        <th (click)="setSortValue('approvedBy')" scope="col">
          <span *ngIf="sortValue === 'approvedBy'">↑</span>
          <span *ngIf="sortValue === '-approvedBy'">↓</span>
          אושר על ידי
        </th>
        <th (click)="setSortValue('reference')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'reference'">↑</span>
          <span *ngIf="sortValue === '-reference'">↓</span>
          אסמכתא
        </th>
        <th scope="col">פעולות</th>
      </tr>
      </thead>
      <tbody class="text-right">
      <tr *ngFor="let result of results; let i = index">
        <td>{{i}}</td>
        <td
          (click)="toggleFilter('created', result.created,'תאריך - ' + getDateStr(result.created))">{{result.created | date:'dd.MM.yyyy HH:mm'}}</td>
        <td (click)="toggleFilter('amount', result.amount,'סכום - '+result.amount)">{{result.amount}}</td>
        <td
          (click)="toggleFilter('futurePaymentsAmount', result.futurePaymentsAmount,'סכום מימון - ' + result.futurePaymentsAmount )">{{result.futurePaymentsAmount}}</td>
        <td
          (click)="toggleFilter('customerId', result.customer.id,'מעביר - '  + getFullName(result.customer.name))">
          {{getFullName(result.customer.name)}}
          <span *ngIf="!customerId && isAdmin()" (click)="showUserProfileDialog(result.customer.id)"
                style="cursor: pointer"
                class="badge badge-pill badge-primary">פרופיל</span>
        </td>
        <td
          (click)="toggleFilter('status', result.status , 'סטטוס - '  + result.status)">{{result.status}}</td>

        <td
          (click)="toggleFilter('transferDate', result.transferDate ,'תאריך העברה - '  + getDateStr(result.transferDate))">
          {{getDateStr(result.transferDate)}}
        </td>
        <td>{{result.approvedBy}}</td>
        <td>{{result.reference}}</td>

        <td>
          <div class="d-flex flex-row mt-2">
            <mat-icon (click)="showDialog(result)" style="cursor: pointer">edit</mat-icon>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
