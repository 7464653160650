import {Component, OnInit} from '@angular/core';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../service/data/data.service';
import {MatDialog} from '@angular/material';
import {Utils} from '../../../util/utils';

@Component({
  selector: 'app-white-list',
  templateUrl: './white-list.component.html',
  styleUrls: ['./white-list.component.scss']
})
export class WhiteListComponent extends BaseListComponent {

  // newPhone;
  filter = '';

  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dataService: DataService, dialog: MatDialog) {
    super(httpService, activatedRoute, dataService, dialog);
  }

  async loadData() {
    super.loadData();
    const res = await this.httpService.getWhiteList();
    console.log(res);
    this.results = this.results || [];
    this.results = this.results.concat(res['result']);    // console.log(res);
    this.results = this.results.filter(i => i != null);
    this.total = res['result'].total;
    this.afterLoadData(res['result'].length);
  }

  async addNumber() {
    if (!this.filter) {
      return;
    }

    const newPhone = Utils.formatPhone(this.filter);


    //newPhone = Utils.formatPhone(this.newPhone);
    const res = await this.httpService.addNumberToWhiteList(newPhone);
    console.log(res);
    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      return;
    }


    await this.dataService.okDialog('מספר הטלפון נוסף בהצלחה!');
    this.filter = '';
    this.resetData();
    this.loadData();

  }

  async deleteNumber(number) {
    const a = await this.dataService.yesNoDialog('האם ברצונך להסיר את המספר?');
    console.log(a);
    if (!a) {
      return;
    }

    const res = await this.httpService.removeNumberFromWhiteList(number);
    console.log(res);
    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.showLoader = false;
      return;
    }

    await this.dataService.okDialog('מספר הטלפון נמחק בהצלחה!');
    this.resetData();
    this.loadData();
  }

  getFilteredList() {
    if (!this.filter) {
      return this.results;
    }

    // fix filter
    /*var relevantFilter = this.filter;
    if (relevantFilter.startsWith('+9720')) {
      relevantFilter = relevantFilter.replace('+9720', '');
    } else if (relevantFilter.startsWith('+972')) {
      relevantFilter = relevantFilter.replace('+972', '');
    }

    if (relevantFilter.startsWith('0')) {
      relevantFilter = relevantFilter.substring(1);
    }
    */

    return this.results.filter(i => {
      return i.phone.startsWith(Utils.formatPhone(this.filter));
    });
  }

  getClassName = (): String => 'WhiteListComponent';

}
