<div class="d-flex justify-content-center align-items-center flex-column border border-light p-1 rounded-top rounded-bottom text-center">
  <mat-icon  (click)="onDelete()" class="text-muted align-self-start">delete_outline</mat-icon>

  <img height="80" width="80"
       [src]="src || '/assets/no-image.png'"
       class="img rounded">

  <div class="d-flex flex-row d-block justify-content-center">
    <button  (click)="openImageDialog()" class="btn btn-sm btn-primary btn-block mt-2">בחר</button>
  </div>
</div>
