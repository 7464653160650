import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material';
import {DataService} from '../../../service/data/data.service';
import {HttpService} from '../../../service/http/http.service';
import {ImageDialogComponent} from '../image-dialog/image-dialog.component';
import {EditCreateProductComponent} from '../../dialog/edit-create-product/edit-create-product.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input()
  supplierId;

  @Input()
  product;

  @Input()
  categoryId;

  @Output() onProductDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onProductEdit: EventEmitter<any> = new EventEmitter<any>();


  showLoader = false;
  otherPrices;
  showComparePrices = false;


  constructor(public dialog: MatDialog, private  dataService: DataService, private  httpService: HttpService) {

  }

  ngOnInit() {
  }

  async openImageDialog() {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: this.product.images.thumbUrl
      }
      // width: '0vw',
      // maxWidth: '600px',
      // height: '80vh',
      // panelClass: 'dialog-style',
    });

    const file = await dialogRef.afterClosed().toPromise();
    if (!file) {
      return;
    }

    console.log(file);
    // this.uploadFile(file, supplier);

    this.showLoader = true;
    const res = await this.httpService.uploadProductImage(this.product.id, file);
    console.log(res);
    this.showLoader = false;


    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }

    const p = res['result'];
    this.product.images.thumbUrl = p.images.thumbUrl;
  }



  async deleteProduct() {

    if (this.onProductDelete && this.categoryId) {
      this.onProductDelete.emit(this.product);
      return;
    }

    const a = await this.dataService.yesNoDialog('האם ברצונך למחוק את המוצר?');
    if (!a) {
      return;
    }

    const res = await this.httpService.deleteProduct(this.product.id, this.product.categoryId);
    console.log(res);

    if (this.onProductDelete) {
      this.onProductDelete.emit(this.product);
    }

  }

  async editCreateProduct() {
    if (this.categoryId) {
      this.onProductEdit.emit(this.product);
      return;
    }

    const dialogRef = this.dialog.open(EditCreateProductComponent, {
      panelClass: 'dialog-style-no-padding',
      width: '400px',
      maxWidth: '400px',
      // height: '80vh',
      data: {
        product: this.product,
        categoryId: this.product.categoryId,
        supplierId: this.supplierId
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    console.log('after close', result);
    if (!result) {
      return;
    }

    /*if (product == null) {
      this.category.products.push(result);
    } else {
      /!*if (this.onCategoryEdit) {
        this.onCategoryEdit.emit(result);
      }*!/
    }*/
  }

  async comparePrice(product) {
    this.showLoader = true;
    const res = await this.httpService.comparePrice(this.supplierId, product.id);
    this.showLoader = false;
    this.otherPrices = res['result']['items'] || [];
    this.showComparePrices = true;
    console.log(res);
  }

  formatPrice(p) {
    if (p === undefined) {
      return '';
    }
    if (p === 0) {
      return '0₪';
    }
    return p.toFixed(2) + '₪';
  }

}
