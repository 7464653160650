<div class="row p-3 text-center">

  <div class="col-12 text-center">
    <p>יש לבחור תמונה</p>
  </div>
  <div class="row">
    <div *ngFor="let item of items" class="col-md-4 my-card text-center">
      <img  (click)="select(item.link)" class="m-1" [src]="item.link" style="height: auto; width: 130px; cursor: pointer">
    </div>
  </div>

  <div class="col-12 text-center">
    <button (click)="close()" class="btn btn-primary btn-sm btn-block">חזור</button>
  </div>
</div>
