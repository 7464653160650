import {Component, Inject} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-edit-supplier-bank-transfer',
  templateUrl: './edit-supplier-bank-transfer.component.html',
  styleUrls: ['./edit-supplier-bank-transfer.component.scss']
})
export class EditSupplierBankTransferComponent extends BaseDialogComponent<EditSupplierBankTransferComponent> {

  transfer;
  originalTransfer;
  paidAmount;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditSupplierBankTransferComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.isNew = data.transfer == null;
    if (!this.isNew) {
      this.transfer = JSON.parse(JSON.stringify(data.transfer));
      this.originalTransfer = data.transfer;
    }

    // set transferDate
    if (this.transfer.transferDate) {
      this.transfer.transferDate = new Date(this.transfer.transferDate).toISOString().split('T')[0];
    } else {
      // set for today - we can just leave it empty
      this.transfer.transferDate = new Date().toISOString().split('T')[0];
    }
    console.log(this.transfer);

  }

  ngOnInit() {
  }

  getTitle() {
    return 'פרטי העברה עבור ' + this.transfer.supplier.name;
    // return 'פרטי העברה על סך ' + this.transfer.amount + ' עבור ' + this.transfer.supplier.name + '';
  }

  async save(closePayment = false) {

    // input check
    if (!closePayment && !this.transfer.reference) {
      this.showSnackBar('יש להזין מספר אסמכתא');
      return;
    }


    this.error = '';
    // const res = this.isNew ? await this.httpService.addChain(this.transfer) : await this.httpService.updateSupplierPaymentTransfer(this.transfer);

    let body = closePayment ? {
      supplierBankTransferId: this.transfer.id,
      close: true
    } : {
      reference: this.transfer.reference,
      supplierBankTransferId: this.transfer.id,
      transferDate: new Date(this.transfer.transferDate).getTime(),
    };

    body['paidAmount'] = this.paidAmount;


    const res = await this.httpService.updateSupplierPaymentTransfer(body);
    console.log(res);

    const status = res['status'] as string;
    if (status.toLowerCase() === 'ok') {
      this.dialogRef.close(res['result']);
      return;
    }

    // this.error = status;
    this.showSnackBar('שגיאה: ' + status);
    // this.originalSupplier = res['result'];

  }


  getBankDetails() {
    let bank = this.transfer.bankDetails.bank;
    return 'מספר בנק: ' + bank.bankNumber + ' סניף: ' + bank.branch + ' חשבון: ' + bank.accountNumber + ' שם מוטב: ' + bank.accountBeneficial;
  }
}
