<button *ngIf="false" class="dialogCloseButton" (click)="close()">X</button>
<app-loader *ngIf="showLoader"></app-loader>


<div *ngIf="isNew" class="bg-success d-block text-center p-2">
  <h4 class="text-white">הקמת מוצר</h4>
</div>

<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <div class="d-flex flex-column justify-content-center p-3" style="direction: rtl">


    <div class="row">


      <div class="col-12 text-center">
        <img (click)="openImageDialog()" height="60" width="60"
        [src]="product.images.thumbUrl || '/assets/logo.png'" class="img rounded">
      </div>
      <div class="col-12">

        <div class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">שם מוצר</label>
          <input class="form-control input-width-sm" [(ngModel)]="product.name">
          <button (click)="pickImage(product.name)" *ngIf="product.name && product.name.length >= 2" class="btn btn-sm btn-success  mr-2">
            תמונה לפי שם
          </button>
        </div>


        <div class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">ברקוד</label>
          <input class="form-control input-width-sm" [(ngModel)]="product.barcode">

          <button (click)="pickImage(product.barcode)" *ngIf="product.barcode && product.barcode.length >= 2" class="btn btn-sm btn-success mr-2">
            תמונה לפי ברקוד
          </button>
        </div>

        <div class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">יצרן</label>
          <input class="form-control input-width-sm" [(ngModel)]="product.manufacturer">
        </div>

        <div class="d-flex flex-row flex-wrap mt-3">
          <label class="ml-2 text-width-sm">מוצר פעיל</label>
          <mat-slide-toggle [(ngModel)]="product.isEnabled" color="primary"></mat-slide-toggle>
        </div>


        <div class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">סוג מארז</label>
          <select [(ngModel)]="product.unitOfMeasure" class="ml-2 form-control input-width-sm">
            <option class="form-control" *ngFor="let o of packOptions; let i = index;"
                    [value]="o.value">{{(o.name)}}</option>
          </select>
        </div>

        <div *ngIf="product.unitOfMeasure== 'kg'" class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">יחידות בקילו</label>
          <input class="form-control input-width-sm" [(ngModel)]="product.unitsPerKg">
        </div>

        <div class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">סוג כשרות</label>
          <input class="form-control input-width-sm" [(ngModel)]="product.kosherType">
        </div>

        <!--<div *ngIf="false" class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">שם רשת</label>
          <select [(ngModel)]="selectedChainValue" class="ml-2">
            <option *ngFor="let o of chainOptions" [value]="o.id">{{getChainOptionValue(o)}}</option>
          </select>

        </div>-->

        <!-- <div class="d-flex flex-row align-items-center mt-2">
           <label class="m-0 p-0 ml-3 text-width-sm">סניף ללא רשת</label>
           <mat-slide-toggle [(ngModel)]="notBelongToChain" color="primary"></mat-slide-toggle>
         </div>-->


      </div>

    </div>

    <!--<div *ngIf="error!==''" class="alert alert-danger" role="alert">
      {{error}}
    </div>-->

    <div class="row mt-3">
      <div class="col-12">
        <button (click)="save()" class="btn btn-success btn-block">שמירה</button>
      </div>
    </div>


  </div>
</div>
