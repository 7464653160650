import {Component, HostListener, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'habit-logger';

  userActivity;
  userInactive: Subject<any> = new Subject();

  constructor() {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      console.log('user has been inactive!');
    });

  }

  ngOnInit() {

  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1000 * 5);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

}
