import {OnInit} from '@angular/core';
import {HttpService} from '../../../service/http/http.service';


export abstract class BaseComponent implements OnInit {


  protected constructor(public httpService: HttpService) {

  }

  ngOnInit() {
  }


  idAdmin() {
    return localStorage.getItem('role') === 'admin';
  }

  isChainMaster() {
    return localStorage.getItem('chain') === undefined;
  }


  formatDate(d: any) {
    try {
      if (!d) {
        return '-';
      }
      const date = new Date(d);
      const day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
      const month = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)));

      const answer = day + '/' + month + '/' + date.getFullYear();
      return answer;
    } catch (e) {
      return '-';
    }
  }

}
