import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LogsComponent} from './components/logs/logs.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LoginComponent} from './components/login/login.component';
import {MainComponent} from './components/main/main.component';
import {AuthGuardService} from './service/authGuard/auth-guard.service';
import {OfflineOrdersComponent} from './components/lists/offline-orders/offline-orders.component';
import {OnlineOrdersComponent} from './components/lists/online-orders/online-orders.component';
import {UserToUserComponent} from './components/lists/user-to-user/user-to-user.component';
import {BankTransfersComponent} from './components/lists/bank-transfers/bank-transfers.component';
import {LeverageListComponent} from './components/lists/leverage-list/leverage-list.component';
import {CustomersComponent} from './components/lists/customers/customers.component';
import {SupplierPaymentsComponent} from './components/lists/supplier-payments/supplier-payments.component';
import {AdminGuardService} from './service/adminGuard/admin-guard.service';
import {SuppliersComponent} from './components/lists/suppliers/suppliers.component';
import {UsersLocationComponent} from './components/users-location/users-location.component';
import {ChainsComponent} from './components/lists/chains/chains.component';
import {WhiteListComponent} from './components/lists/white-list/white-list.component';
import {EditOnlineStoreComponent} from './components/edit-online-store/edit-online-store.component';
import {HabitProductsListComponent} from './components/lists/habit-products-list/habit-products-list.component';
import {FuturePaymentsComponent} from './components/lists/future-payments/future-payments.component';
import {CampaignsListComponent} from './components/lists/campaigns-list/campaigns-list.component';
import {CampaignsHolderComponent} from './components/lists/campaigns-holder/campaigns-holder.component';
import {InvitesComponent} from './components/lists/invites/invites.component';
import {HabitCategoriesComponent} from './components/lists/habit-categories/habit-categories.component';


const routes: Routes = [
  //  {path: '', component: DashboardComponent},
  {
    path: '', component: MainComponent, canActivate: [AuthGuardService], children: [
      {path: '', component: DashboardComponent},
      {path: 'logs', component: LogsComponent},
      {path: 'offline-orders', component: OfflineOrdersComponent},
      {path: 'online-orders', component: OnlineOrdersComponent},
      {path: 'supplier-payments', component: SupplierPaymentsComponent},
      {path: 'user-to-user', canActivate: [AdminGuardService], component: UserToUserComponent},
      {path: 'habit-products-list', canActivate: [AdminGuardService], component: HabitProductsListComponent},
      {path: 'bank-transfers', canActivate: [AdminGuardService], component: BankTransfersComponent},
      {path: 'habit-categories', canActivate: [AdminGuardService], component: HabitCategoriesComponent},
      {path: 'leverages', canActivate: [AdminGuardService], component: LeverageListComponent},
      {path: 'customers', canActivate: [AdminGuardService], component: CustomersComponent},
      {path: 'suppliers', component: SuppliersComponent},
      {path: 'users-locations', canActivate: [AdminGuardService], component: UsersLocationComponent},
      // {path: 'campaigns', component: CampaignsListComponent},
      {path: 'campaigns', component: CampaignsHolderComponent},
      {path: 'chains', canActivate: [AdminGuardService], component: ChainsComponent},
      {path: 'invites', canActivate: [AdminGuardService], component: InvitesComponent},
      {path: 'white-list', canActivate: [AdminGuardService], component: WhiteListComponent},
      {path: 'edit-online-store/:id', canActivate: [AdminGuardService], component: EditOnlineStoreComponent},
      {path: 'future-payments', canActivate: [AdminGuardService], component: FuturePaymentsComponent},
    ]
  },
  {path: 'login', component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
