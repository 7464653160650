import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-campaigns-holder',
  templateUrl: './campaigns-holder.component.html',
  styleUrls: ['./campaigns-holder.component.scss']
})
export class CampaignsHolderComponent implements OnInit {

  @Input()
  supplierId;

  supplierName = '';

  constructor(private  activatedRoute: ActivatedRoute) {

    // the two children will load there data after 500 millis
    activatedRoute.queryParams.subscribe(params => {
      const supplierId = params['supplierId'];
      const supplierName = params['supplierName'];
      if (!supplierId) {
        return;
      }

      this.supplierName = supplierName;
      this.supplierId = supplierId;
    });

  }

  ngOnInit() {
  }

}
