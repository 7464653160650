export const environment = {
  production: true,
  config: {
    apiKey: 'AIzaSyBdVmZNxHQvJ01dKof10SAexPuHKXGWgzk',
    authDomain: 'habit-f2130.firebaseapp.com',
    databaseURL: 'https://habit-f2130.firebaseio.com',
    projectId: 'habit-f2130',
    storageBucket: 'habit-f2130.appspot.com',
    messagingSenderId: '569267540228',
    appId: '1:569267540228:web:aab76f345ffcd4dd248051'
  }
};
