<div *ngIf="isNew" class="bg-success d-block text-center p-2">
  <h4 class="text-white p-0 m-0">הוספת מוצר</h4>
</div>

<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <div class="d-flex flex-column justify-content-center p-3" style="direction: rtl">

    <!-- details -->


    <mat-form-field style="width: 20em;" class="text-right">
      <input [disabled]="!isNew" [(ngModel)]="searchValue" type="text" matInput
             placeholder='הזן שם מוצר' class="text-right"
             (input)="onChange(value.value)" #value
             [matAutocomplete]="auto">
    </mat-form-field>

    <!-- product options -->

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of productOptions" [value]="option.name"
                  (onSelectionChange)="onItemSelected($event,option)">

        <div class="d-flex flex-row p-2 justify-content-between">
          <img style="height: 50px; width: auto" [src]="option.images.thumbUrl" class="img rounded ml-3">
          <p class="m-0 p-0">{{option.name}}</p>

          <div *ngIf="false" class="d-flex flex-column flex-fill justify-content-around">
            <!-- Created-->
            <div class="d-flex flex-row justify-content-around">
              <p *ngIf="false" class="m-0 p-0">שם המוצר</p>
              <p class="m-0 p-0">{{option.name}}</p>
            </div>


            <!--Amount-->
            <div *ngIf="false" class="d-flex flex-row justify-content-between">
              <p class="m-0 p-0">ברקוד</p>
              <p class="m-0 p-0">{{option.barcode}}</p>
            </div>

          </div>
        </div>

      </mat-option>
    </mat-autocomplete>


    <div class="row" *ngIf="selectedItem">

      <div class="col-12">

        <div class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">מחיר</label>
          <input type="number" class="form-control input-width-sm" [(ngModel)]="productDetails.price">
        </div>


        <div class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">עמלת הביט באחוזים</label>
          <input type="number" max="100" min="0" class="form-control input-width-sm"
                 [(ngModel)]="productDetails.profit">
        </div>

        <div class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">מימון הביט</label>
          <input type="number" class="form-control input-width-sm"
                 [(ngModel)]="productDetails.futurePaymentsAmount">
        </div>

        <div class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">כמות במלאי</label>
          <input type="number" class="form-control input-width-sm"
                 [(ngModel)]="productDetails.quantity">
        </div>


        <!--<div *ngIf="false" class="d-flex flex-row align-items-center mt-2">
          <label class="m-0 p-0 ml-3 text-width-sm">שם רשת</label>
          <select [(ngModel)]="selectedChainValue" class="ml-2">
            <option *ngFor="let o of chainOptions" [value]="o.id">{{getChainOptionValue(o)}}</option>
          </select>

        </div>-->

        <!-- <div class="d-flex flex-row align-items-center mt-2">
           <label class="m-0 p-0 ml-3 text-width-sm">סניף ללא רשת</label>
           <mat-slide-toggle [(ngModel)]="notBelongToChain" color="primary"></mat-slide-toggle>
         </div>-->


      </div>

    </div>

    <div *ngIf="selectedItem" class="col-12 mt-3">
      <button (click)="save()" class="btn btn-success btn-block">{{isNew ? 'הוסף' : 'שמור'}}</button>
    </div>

  </div>
</div>
