<div class="row">
  <div class="form form-inline pl-4 pt-2 pb-2">
    <button *ngIf="userFilter.value" (click)="userFilter.value = ''; loadAllLogs();" class="btn btn-sm btn-danger">
      Clear
    </button>
    <input #userFilter class="form-control" placeholder="logs for user...">
    <button (click)="searchForUser(userFilter.value)" class="btn btn-sm btn-primary">Search</button>
    <p>{{logs.length}} results</p>
  </div>
</div>
<table class="table table-bordered">
  <thead>
  <tr>
    <th scope="col">Time</th>
    <th scope="col">User</th>
    <th scope="col">Message</th>
    <th scope="col">Type</th>
    <th scope="col">Device</th>
    <th scope="col">Store Version</th>
    <th scope="col">Debug Version</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let l of logs ">
    <td>{{l.time | date : 'dd/MM/yyyy HH:mm:ss'}}</td>
    <th>{{l.user}}</th>
    <th style="max-width: 350px">{{l.log}}</th>
    <td>
      <div class="{{getClass(l.logType)}}"> {{getTitle(l.logType)}}</div>
    </td>
    <td>{{l.device}}</td>
    <td>{{l.storeVersion}}</td>
    <td>{{l.debugVersion}}</td>
  </tr>
  </tbody>
</table>
