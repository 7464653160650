import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DataService} from '../../service/data/data.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  startDate = new Date();
  endDate = new Date();

  maxDateEnd: Date = new Date();

  //  @Output() onDateChanged = new EventEmitter<{ startDate: number, endDate: number }>();

  constructor(private  dataService: DataService) {
    this.startDate = new Date();
    this.startDate.setDate(new Date().getDate() - (30 * 12));
    this.endDate = new Date();
    // this.maxDateEnd.setDate(new Date().getDate() + )

    this.maxDateEnd.setHours(23, 59, 59);
    // console.log(this.maxDateEnd.toString());
  }

  ngOnInit() {
  }


  onStartDateSelected(date: Date) {
    if (!date) {
      return;
    }

    this.startDate = date;
    this.startDate.setHours(1, 0, 0);
    // console.log(date.getTime());

    this.dataService.updateDates(this.startDate, this.endDate);

    // this.onDateChanged.emit();
    // localStorage.setItem('startDate', date.getTime().toString());
    // this.loadData();
  }

  onEndDateSelected(date) {
    if (!date) {
      return;
    }

    this.endDate = date;
    this.endDate.setHours(23, 59, 59);
    this.dataService.updateDates(this.startDate, this.endDate);

    // console.log(date.getTime());
    // localStorage.setItem('endDate', date.getTime().toString());
    // this.loadData();
  }

  onDateSelected() {
    //  this.loadData();
  }

}
