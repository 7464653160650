import {Component, OnInit} from '@angular/core';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog, MatRadioChange} from '@angular/material';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-future-payments',
  templateUrl: './future-payments.component.html',
  styleUrls: ['./future-payments.component.scss']
})
export class FuturePaymentsComponent extends BaseListComponent {

  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dialog: MatDialog, dataService: DataService) {
    super(httpService, activatedRoute, dataService, dialog);
  }

  isPaidOffFullyValue = 0;
  paymentFailureValue = 0;

  async loadData() {
    super.loadData();
    const res = await this.httpService.getFuturePayments(this.sortValue, this.filters);
    console.log(res);
    this.checkResults(res);
    this.results = this.results || [];
    this.results = this.results.concat(res['result'].list);
    this.total = res['result'].total;
    this.afterLoadData(res['result'].list.length);
  }


  getUserPayments(user) {
    if (!user) {
      return 0;
    }
    if (!user.payments) {
      user.payments = {
        total: 0,
        list: []
      };
      return 0;
    }

    return user.payments.total;
  }

  getUserReturns(user) {
    if (!user) {
      return 0;
    }
    if (!user.returns) {
      user.returns = {
        total: 0,
        list: []
      };
      return 0;
    }

    return user.returns.total || 0;
  }

  getClassName = (): String => 'FuturePaymentsComponent';

  onIsPaidOffFullyChanged(value) {
    console.log(value);
    if (value == 0) {
      delete this.filters['isPaidOffFully'];
      this.resetData();
      this.loadData();
      return;
    }

    this.addFilter('isPaidOffFully', value == 1, value == 1 ? 'עסקאות שנפרעו' : 'עסקאות שלא נפרעו');
    this.resetData();
    this.loadData();
  }

  onIsPaymentFailureChanged(value) {
    console.log(value);
    if (value == 0) {
      delete this.filters['overdue'];
      this.resetData();
      this.loadData();
      return;
    }

    this.addFilter('overdue', value == 1, value == 1 ? 'תשלומים שנכשלו' : 'תשלומים שלא נכשלו');
    this.resetData();
    this.loadData();
  }
}
