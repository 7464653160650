import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImageDialogComponent} from '../image-dialog/image-dialog.component';
import {MatDialog} from '@angular/material';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  @Input()
  src;

  @Output() onImageSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onImageDelete: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dialog: MatDialog, private  dataService: DataService) {
  }

  ngOnInit() {
  }


  async openImageDialog() {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: this.src
      }
    });

    const file = await dialogRef.afterClosed().toPromise();
    if (!file) {
      return;
    }

    this.onImageSelected.emit(file);
  }

  async onDelete() {
    const res = await this.dataService.yesNoDialog('האם למחוק את התמונה?');
    if (res === true) {
      this.onImageDelete.emit();
    }
  }
}
