<div>
  <div class="d-flex flex-row justify-content-center align-items-center locationSelectHolder">
    <label class="p-0 m-0 ml-3">תוצאות מ</label>
    <select [(ngModel)]="selectedValue" class="ml-2 locationSelect" (change)="loadData()">
      <option *ngFor="let o of options" [value]="o.value">{{o.title}}</option>
    </select>
  </div>


  <div class="row p-2">
    <agm-map style="height: 100vh;  width: 100%;" [latitude]=latitude [longitude]=longitude>

      <agm-marker
        *ngFor="let item of list"
        [latitude]="item.locationMark.lat"
        [longitude]="item.locationMark.lon"
        (markerClick)="onMarkerClicked(item,$event)">

        <agm-info-window class="p-0 m-0" [disableAutoPan]="false" #infoWindow>
          <div class="ml-4">
            <div class="d-flex flex-column justify-content-center align-items-center">
              {{item.customer.name.first}} {{item.customer.name.last}}
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              {{item.locationMark.created | date:'dd.MM.yyyy HH:mm' }}
            </div>
            <div class="mt-2">
              <img [src]="item.customer.imageUrl || '/assets/logo.png'" height="80" width="80" class="img rounded">
            </div>
          </div>


        </agm-info-window>

      </agm-marker>

    </agm-map>
  </div>
</div>


