<app-loader *ngIf="showLoader"></app-loader>

<div class="">

  <div class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>


  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">


    <span style="direction: rtl">
          <label class="ml-3">{{total.count}} קמפיינים </label>
          <label class="ml-3">{{formatPrice(total.amount)}}</label>
    </span>
    <span *ngIf="false" style="cursor: pointer">
       <label style="color: #107C41; cursor: pointer">שמור כקובץ אקסל</label>
       <img src="assets/excel.png" width="35px" height="50px">
    </span>

  </div>

  <div class="row p-3" style="direction: ltr">
    <div class="d-flex flex-row align-items-start justify-content-start">
      <button *ngIf="!isAdmin() || supplierId" (click)="createCampaign(null)" class="btn btn-sm btn-primary float-left">
        צור קמפיין חלוקה
      </button>
    </div>

  </div>


  <div>
    <table *ngIf="results" class="table table-hover mt-3" style="direction: rtl">
      <thead class="text-right">
      <tr>
<!--
        <th scope="col">תמונה</th>
-->
        <th>#</th>
        <th scope="col">ספק</th>
        <th *ngIf="false" scope="col">נוצר</th>
        <th scope="col">זמן תחילת קמפיין</th>
        <th scope="col">תקציב מקורי</th>
        <th scope="col">תקציב שנותר</th>
        <th scope="col">תדירות עסקאות בדקות</th>
        <th scope="col">מימוש</th>
        <th scope="col">זמן עסקה אחרונה</th>
        <th scope="col">האם פעיל</th>
        <th scope="col">פעולות</th>
      </tr>
      </thead>
      <tbody class="text-right">
      <tr *ngFor="let result of results; let i = index">
        <td>{{i}}</td>

        <!-- <td>

           <app-image-upload [src]="result.imageUrl" (onImageSelected)="uploadCampaignImage($event,result.supplier.id,result)" (onImageDelete)="deleteImage(result)"></app-image-upload>

           &lt;!&ndash;<img (click)="openImageDialogForCampaign(result.supplier.id,result)" height="80" width="80"
                [src]="result.imageUrl || '/assets/no-image.png'"
                class="img rounded mt-3">&ndash;&gt;
         </td>-->
        <td (click)="toggleFilter('supplierId', result.supplier.id,'ספק - '+result.supplier.name)">
          <div class="d-flex flex-column justify-content-center">
            <img style="height: auto; width: 50px" [src]="result.supplier.images?.thumbUrl" class="img rounded ml-3">
            <p>{{result.supplier.name}}</p>
          </div>
        </td>
        <td *ngIf="false">{{getDateStr(result.created)}}</td>
        <td style="direction: ltr">{{getDateStrWithTime(result.startDate,true)}}</td>
        <td>{{formatPrice(result.budget)}}</td>
        <td>{{formatPrice(result.remainingBudget)}}</td>
        <td>{{result.frequencyMinutes}}</td>
        <td> <button (click)="showReachoutDialog(result)" class="btn btn-sm btn-primary">מימושים ({{result.reachout?.realized.length}})</button>
          <div *ngIf="false" class="d-flex flex-column">
            <div><p>{{result.reachout?.realized.length}} מימשו</p></div>
            <div><p>{{result.reachout?.unrealized.length}} לא מימשו</p></div>
          </div>
        </td>

        <td style="direction: ltr">{{getDateStrWithTime(result.lastDistribution,true)}}</td>
        <td><span class="badge badge-pill"
                  [ngClass]="{'badge-primary': result.isActive, 'badge-danger':!result.isActive  }">{{result.isActive ? 'פעיל' : 'לא פעיל'}}</span>
        </td>

        <td>
          <div class="d-flex flex-row mt-2">
            <mat-icon style="cursor: pointer" (click)="createCampaign(result)">edit</mat-icon>
            <mat-icon class="text-danger mr-3" (click)="delete(result)" style="cursor: pointer">delete</mat-icon>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <button (click)="loadData()" *ngIf="hasPagination" class="btn btn-sm btn-primary btn-block">טען עוד</button>

</div>
