import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-edit-create-category',
  templateUrl: './edit-create-category.component.html',
  styleUrls: ['./edit-create-category.component.scss']
})
export class EditCreateCategoryComponent extends BaseDialogComponent<EditCreateCategoryComponent> {

  category;
  supplierId;
  parentCategoryId;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditCreateCategoryComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.category = data.category;
    this.supplierId = data.supplierId;
    this.parentCategoryId = data.parentCategoryId;
    this.isNew = !data.category;
    if (!data.category) {
      this.category = {
        name: ''
      };
    } else {
      this.category = JSON.parse(JSON.stringify(data.category));

    }

    console.log('parentId', this.parentCategoryId);
  }

  ngOnInit() {
  }

  async save() {
    let res;
    if (this.isNew) {
      res = await this.httpService.addCategory(this.supplierId, this.category.name, this.parentCategoryId);
    } else {
      res = await this.httpService.editCategory(this.category.id, this.category.name);
    }

    console.log(res);
    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }
    this.close(res['result']);
  }

}
