import {Component, OnInit} from '@angular/core';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../service/data/data.service';
import {MatDialog} from '@angular/material';
import {EditBankTransferComponent} from '../../dialog/edit-bank-transfer/edit-bank-transfer.component';
import {FindSupplierComponent} from '../../dialog/find-supplier/find-supplier.component';
import {__await} from 'tslib';

@Component({
  selector: 'app-habit-categories',
  templateUrl: './habit-categories.component.html',
  styleUrls: ['./habit-categories.component.scss']
})
export class HabitCategoriesComponent extends BaseListComponent {

  currentEditCategory;
  newCategoryName;


  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dataService: DataService, dialog: MatDialog) {
    super(httpService, activatedRoute, dataService, dialog);
  }


  async loadData() {
    super.loadData();

    const res = await this.httpService.getHabitCategories(this.sortValue, this.filters);
    res['result']['list'] = res['result'];
    console.log(res);
    this.checkResults(res);
    // this.results = this.results || [];
    this.results = [];
    this.results = this.results.concat(res['result'].list);
    this.total = res['result'].total;
    this.afterLoadData(res['result'].list.length);
  }


  async findSupplier(category) {
    console.log(category);

    const dialogRef = this.dialog.open(FindSupplierComponent, {
      data: {}
    });

    const supplierId = await dialogRef.afterClosed().toPromise();
    console.log('after close', supplierId);
    if (!supplierId) {
      return;
    }

    // add supplier to category
    category.suppliers.push({id: supplierId});
    this.updateCategory(category);

  }

  async updateCategory(category) {
    await this.httpService.editHabitCategory(category);
    this.currentEditCategory = null;
    this.loadData();
  }


  getClassName = (): String => 'HabitCategoriesComponent';


  async deleteSupplier(supplier, category) {
    const ans = await this.dataService.yesNoDialog('האם להסיר ספק זה?');
    if (!ans) {
      return;
    }

    category.suppliers.splice(category.suppliers.indexOf(supplier), 1);
    await this.updateCategory(category);
    this.loadData();
  }

  async createCategory() {
    if (!this.newCategoryName) {
      return;
    }

    await this.httpService.createHabitCategory(this.newCategoryName);
    this.newCategoryName = '';
    this.loadData();
  }

  async deleteCategory(category) {
    const ans = await this.dataService.yesNoDialog('האם למחוק את הקטגוריה?');
    if (!ans) {
      return;
    }

    await this.httpService.deleteHabitCategory(category.id);
    this.loadData();
  }
}
