<app-loader *ngIf="showLoader"></app-loader>

<div class="container">

  <div class="row mt-3 text-right" style="direction: rtl">
    <div class="col p-0 m-0" style="direction: rtl">
      <div *ngIf="false" class="d-flex flex-row" style="direction: rtl">
        <input (keydown.enter)="search(searchField.value)" class="form-control ml-2 w-25" type="text"
               placeholder="חפש לקוח לפי שם או טלפון" #searchField>
        <button (click)="search(searchField.value)" class="btn btn-primary">חפש</button>
      </div>

      <app-search hint="חפש לקוח לפי שם או טלפון" suppliers=false (itemSelected)="search($event)"></app-search>

    </div>
  </div>


  <div class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>


  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">
    <span style="direction: rtl">
          <label class="ml-3">{{total.count}} לקוחות</label>
          <label class="ml-3">{{formatPrice(total.total)}}</label>
    </span>

  </div>

  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="loadData()">
    <table *ngIf="results" class="table table-hover mt-2" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>#</th>
        <th scope="col">תמונה</th>
        <th (click)="setSortValue('name')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'name'">↑</span>
          <span *ngIf="sortValue === '-name'">↓</span>
          שם
        </th>
        <th scope="col">אימייל</th>
        <th (click)="setSortValue('phone')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'phone'">↑</span>
          <span *ngIf="sortValue === '-phone'">↓</span>
          טלפון
        </th>
        <th scope="col">כתובת</th>
        <th scope="col">יתרה</th>
        <th scope="col">חיובים</th>
        <th scope="col">זיכויים</th>
        <th scope="col">פעולות</th>
      </tr>
      </thead>


      <tbody class="text-right">
      <tr *ngFor="let result of results; let i = index">
        <td>{{i}}</td>
        <td><img height="60"  width="60" [src]="result.imageUrl || '/assets/logo.png'" class="img rounded"></td>
        <td
          (click)="toggleFilter('name', result.name.first,'שם - ' + result.name.first)">{{getFullName(result.name)}}
        </td>
        <td>{{(result.email)}}</td>
        <td>{{(result.phone)}}</td>
        <td>{{getAddressText(result.address)}}</td>
        <td
          [ngClass]="{'text-danger': result.balance <= 0, 'text-success':result.balance > 0 }">{{formatPrice(result.balance)}}
        </td>
        <td
          [ngClass]="{'text-danger': result.totalPayments > 0 }">{{formatPrice(getUserPayments(result))}}
        </td>
        <td
          [ngClass]="{'text-success': result.totalReturns > 0 }">{{formatPrice(getUserReturns(result))}}
        </td>
        <div class="d-flex flex-row mt-2">
          <mat-icon (click)="showUserProfileDialog(result.id)" style="cursor: pointer">edit</mat-icon>
        </div>
      </tr>
      </tbody>
    </table>

  </div>
</div>
