<div class="bg-success d-block text-center p-2">
  <h4 class="text-white p-0 m-0">זיכוי עסקה</h4>
</div>


<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <div class="d-flex flex-column justify-content-center p-3" style="direction: rtl">


    <!-- details -->

    <div class="row">

      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">פרטי זיכוי</li>
          <li class="list-group-item">
            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">זיכוי מלא</label>
              <mat-slide-toggle [disabled]="isOnline" [(ngModel)]="fullRefund" color="primary"></mat-slide-toggle>
            </div>

            <div *ngIf="!fullRefund" class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">סכום לזיכוי</label>
              <input type="number" [max]="item.amount" class="form-control input-width-sm" [(ngModel)]="refundAmount">
            </div>
          </li>

        </ul>
      </div>

    </div>


    <div class="row mt-3">
      <div class="col-12" [style.opacity]="isLoading?0.5:1">
        <button (click)="makeRefund()" class="btn btn-success btn-block">בצע זיכוי</button>
      </div>

      <div *ngIf="isLoading" class="d-flex flex-fill flex-column align-items-center justify-content-center">
        <mat-spinner diameter="40" class="text-center"></mat-spinner>
        <p class="p-0 m-0">אנא המתן...</p>
      </div>


    </div>

  </div>
</div>
