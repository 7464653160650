<app-loader *ngIf="showLoader"></app-loader>

<div class="container mt-3" style="">

  <div class="text-right" style="direction: rtl">
    <!--<app-search class="ml-3" hint="חפש מוצר" suppliers=false customers=false products="true" [supplierId]="supplierId"
                (itemSelected)="search($event)"></app-search>-->

    <div class="d-flex flex-row">
      <button (click)="createCategory()" class="btn btn-sm btn-primary ml-2 align-self-center ml-3">הוסף קטגוריה ראשית
      </button>


      <div class="btn-group">
        <button class="btn btn-primary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
          פעולות בקבצים
        </button>
        <div class="dropdown-menu text-right">
          <button (click)="downloadExcel()" class="dropdown-item btn btn-sm">הורדת אקסל מוצרים</button>
          <!--
                    <button (click)="downloadInventory()" class="dropdown-item btn btn-sm">הורדת אקסל מלאי</button>
          -->
          <button (click)="downloadPriceCompareExcel(false)" class="dropdown-item btn btn-sm">הורדת אקסל השוואת מחירים
          </button>
          <button (click)="downloadPriceCompareExcel(true)" class="dropdown-item btn btn-sm">הורדת אקסל השוואת מחירים
            שמחירם השתנה מאתמול
            <div *ngIf="supplier && supplier.automaticPriceUpdate" class="text-center">
              <div class="d-flex flex-row text-right">
                <p class="m-0 p-0 small">תאריך עדכון מחירים אחרון - </p>
                <p class="m-0 p-0 small">{{formatDate(supplier.automaticPriceUpdate)}}</p>
              </div>
            </div>
          </button>

          <div class="dropdown-item text-right">
            <label for="file" style="cursor: pointer" #label
                   class=" p-0 mb-0 pb-0 ml-1 btn btn-sm text-center">העלאת אקסל מחירים ומלאי</label>
            <input style="visibility: hidden; width: 0;" type="file" class="text-white btn" id="file"
                   (change)="handleFileInput($event.target.files[0])">
          </div>

        </div>
      </div>

    </div>

  </div>

  <div class="row mt-2 mb-3">
    <div class="col-3 ml-auto" style="direction: rtl">
      <input [(ngModel)]="searchBox" (input)="onSearchChanged($event.target.value)" class="form-control form-control-sm"
             placeholder="חפש מוצר">
    </div>
  </div>

  <div *ngIf="supplier" class="text-center">
    <app-supplier-view [supplier]="supplier"></app-supplier-view>
  </div>


  <div class="row justify-content-center mt-3">

    <mat-accordion *ngIf="allCategories && searchBox.length === 0; else productList" class="m-0">
      <app-category *ngFor="let category of allCategories" [category]="category" [isFirstLevel]="true"
                    [supplierId]="supplierId" (categoryDeleted)="onCategoryDeleted(category)"
                    (onCategoryEdit)="onCategoryEdit($event)"></app-category>
    </mat-accordion>

    <ng-template #productList>

      <!-- <div *ngFor="let product of filteredProducts">
         <p>{{product.name}}</p>
       </div>-->

      <ul class="list-group p-0 m-0" style="direction: rtl">
        <!--  LEAF  - show products -->

        <li class="list-group-item p-0 mt-2" *ngFor="let product of filteredProducts || []">
          <app-product [supplierId]="supplierId" [product]="product"
                       (onProductDelete)="afterProductDeleted(product)"></app-product>
          <!-- <div *ngIf="false" class="d-flex flex-row p-2">
             <img (click)="openImageDialog(product)" height="50" width="50" [src]="product.images.thumbUrl"
                  class="img rounded ml-3">
             <div class="d-flex flex-column flex-fill justify-content-between">
               &lt;!&ndash; Created&ndash;&gt;
               <div class="d-flex flex-row justify-content-between">
                 <p class="m-0 p-0">{{product.name}}</p>
               </div>


               &lt;!&ndash;Amount&ndash;&gt;
               <div class="d-flex flex-row justify-content-between">
                 <p class="m-0 p-0">{{product.barcode}}</p>
               </div>

               &lt;!&ndash;Actions&ndash;&gt;
               <div class="d-flex flex-row justify-content-end">
                 <button (click)="deleteProduct(product)" class="btn btn-sm btn-danger ml-2">מחק</button>
                 <button (click)="editCreateProduct(product)" class="btn btn-sm btn-primary ml-2">ערוך</button>
                 <button (click)="comparePrice(product)" class="btn btn-sm btn-primary">השוואת מחירים</button>
               </div>

               &lt;!&ndash;Compare Prices&ndash;&gt;
               <div *ngIf="productIdToCompare == product.id" class="d-flex flex-row justify-content-end mt-3">
                 <div *ngFor="let price of otherPrices">
                   <div class="d-flex flex-column align-items-center justify-content-center card ml-2 mr-2 p-3">
                     <img (click)="openImageDialog(product)" height="50" width="50"
                          [src]="price.supplier?.images?.thumbUrl  || '/assets/no-image.png'"
                          class="img rounded">
                     <p class="m-0 p-0 text-center small">{{price.supplier.name}}</p>
                     <p class="m-0 p-0 text-center">{{formatPrice(price.price)}}</p>
                     <p class="m-0 p-0 text-center small">{{price.distance}} מטר</p>
                   </div>
                 </div>
               </div>


             </div>
           </div>-->
        </li>

      </ul>

    </ng-template>
  </div>


</div>



