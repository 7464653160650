<app-loader *ngIf="showLoader"></app-loader>

<button class="dialogCloseButtonWideDialog" (click)="close()">X</button>

<div class="row text-center" style="direction: rtl">
  <div class="col text-center p-3">
    <h4 class="text-center">הרכב עסקאות</h4>
    <h6 *ngIf="result" class="text-center">{{getTitle()}}</h6>
    <h6 class="text-center">{{formatPrice(transfer.amount)}}</h6>
    <button *ngIf="orders" class="btn btn-primary btn-sm" (click)="downloadExcel()">הורד קובץ אקסל</button>
  </div>
</div>

<mat-tab-group mat-stretch-tabs >
  <mat-tab label="קניות בקופה">
    <app-offline-orders [isExternalData]="true" [enableCashback]="false" [results]="orders"></app-offline-orders>
  </mat-tab>
  <mat-tab label="קניות אונליין" *ngIf="onlineOrders && onlineOrders.length > 0">
    <app-online-orders [isExternalData]="true" [results]="onlineOrders"></app-online-orders>
  </mat-tab>

</mat-tab-group>
