<app-loader *ngIf="showLoader"></app-loader>

<div class="container">

  <div class="row mt-3 text-right" style="direction: rtl">
    <div class="col p-0 m-0" style="direction: rtl">
      <div *ngIf="false" class="d-flex flex-row" style="direction: rtl">
        <input (keydown.enter)="search(searchField.value)" class="form-control ml-2 w-25" type="text"
               placeholder="חפש מוצר לפי שם" #searchField>
        <button (click)="search(searchField.value)" class="btn btn-primary">חפש</button>
      </div>

      <app-search hint="חפש מוצר" suppliers=false customers=false products="true"  (itemSelected)="search($event)"></app-search>

    </div>
  </div>


  <div class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>


  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">
    <span style="direction: rtl">
      <label class="ml-3">{{total.count}} מוצרים</label>
      <label class="ml-3">{{formatPrice(total.total)}}</label>
      <button (click)="showDialog(null)" class="btn btn-success ml-3">מוצר חדש</button>

    </span>

  </div>


  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="loadData()">
    <table *ngIf="results" class="table table-hover mt-2" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>#</th>
        <th scope="col">תמונה</th>
        <th (click)="setSortValue('name')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'name'">↑</span>
          <span *ngIf="sortValue === '-name'">↓</span>
          שם
        </th>
        <th scope="col">ברקוד</th>
        <th scope="col">מוצר פעיל</th>
        <th scope="col">יחידת מידה</th>
        <th scope="col">פעולות</th>
      </tr>
      </thead>


      <tbody class="text-right">
      <tr *ngFor="let result of results; let i = index">
        <td>{{i}}</td>

        <td><img (click)="openImageDialog(result)" height="60" width="60"
                 [src]="result.images.thumbUrl || '/assets/logo.png'" class="img rounded"></td>
        <td class="align-middle"
            (click)="toggleFilter('name', result.name,'שם - ' + result.name)">{{result.name}}
        </td>
        <td class="align-middle">{{(result.barcode)}}</td>
        <td class="align-middle">
          <mat-icon color="primary">{{result.isEnabled ? 'done' : 'clear'}}</mat-icon>
        </td>
        <td class="align-middle">{{(result.unitOfMeasure)}}</td>

        <td class="align-middle">
          <div class="d-flex flex-row mt-2">
            <mat-icon (click)="showDialog(result)" style="cursor: pointer">edit</mat-icon>
          </div>
        </td>

      </tr>
      </tbody>
    </table>

  </div>
</div>
