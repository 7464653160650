import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/*@Injectable({
  providedIn: 'root'
})*/
export abstract class BaseService<T> {

  itemsCollection: AngularFirestoreCollection<T>;
  items$: Observable<T[]>;


  constructor(public afs: AngularFirestore, public path: string) {
    this.path = path;
    console.log('base service was created with path - ' + path + ' and type: ');
    this.itemsCollection = afs.collection<T>(this.path);
    this.items$ = this.itemsCollection.valueChanges();
  }

  /*getItems(limit) {
    return this.afs.collection<T>(this.path).valueChanges();
  }*/

  getItems(limit = 100) {
    return this.afs.collection<T>(this.path, ref =>
      ref.limit(limit)
    ).valueChanges();
  }

  getItem(id: string) {
    return this.afs.doc<T>(`${this.path}/${id}`).snapshotChanges()
      .pipe(
        map(item => {
          return Object.assign(this.getType(), item.payload.data());
          //  return item as T;
        })
      );
  }

  addItem(item: T) {
    // Persist a document id
    const id = this.afs.createId();
    //  const data = Object.assign({}, item);
    item['id'] = id;
    return this.itemsCollection.doc(id).set(Object.assign({}, item));
  }

  deleteItem(id) {
    return this.afs.collection(this.path).doc(id).delete();
  }

  editItem(item) {
    const o = JSON.parse(JSON.stringify(item));
    return this.itemsCollection.doc(item.id).set(o);
  }


  abstract getType(): T;

}

