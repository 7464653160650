import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSidenav} from '@angular/material';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {EditBankTransferComponent} from '../dialog/edit-bank-transfer/edit-bank-transfer.component';
import {CreateCampaignDialogComponent} from '../dialog/create-campaign-dialog/create-campaign-dialog.component';
import {environment} from '../../../environments/environment';
import {HttpService} from '../../service/http/http.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  isMobile;
  @ViewChild('drawer', {static: false}) drawer: MatSidenav;

  shouldShowDatePicker = true;
  role;
  name;
  currentUrl;

  hiddenSearchRoutes = ['/customers', '/suppliers', '/users-locations', '/campaigns'];


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  menuItems = [
    {
      title: 'דשבורד',
      shouldShow: () => true,
      routerLink: '/'

    },
    {
      title: 'קניות אונליין',
      shouldShow: () => (this.isAdmin() || this.shouldShowOnlineOrders()),
      routerLink: '/online-orders'
    },
    {
      title: 'קניות בחנות',
      shouldShow: () => (this.isAdmin() || this.shouldShowOfflineOrders()),
      routerLink: '/offline-orders'
    },
    {
      title: 'העברות בין משתמשים',
      shouldShow: () => this.isAdmin(),
      routerLink: '/user-to-user'
    },
    {
      title: 'העברות בנקאיות',
      shouldShow: () => this.isAdmin(),
      routerLink: '/bank-transfers'
    },
    {
      title: 'מינופים',
      shouldShow: () => this.isAdmin(),
      routerLink: '/leverages'
    },
    {
      title: 'משתמשים',
      shouldShow: () => this.isAdmin(),
      routerLink: '/customers'
    },
    {
      title: 'תשלומים לספקים',
      shouldShow: () => true,
      routerLink: '/supplier-payments'
    },
    {
      title: 'ספקים',
      // shouldShow: () => (this.isAdmin() || this.isChainMaster()),
      shouldShow: () => true,
      routerLink: '/suppliers'
    },
    {
      title: 'רשתות',
      shouldShow: () => this.isAdmin(),
      routerLink: '/chains'
    },
    {
      title: 'מיקומי לקוחות',
      isAdminOnly: true,
      shouldShow: () => this.isAdmin(),
      routerLink: '/users-locations'
    },
    {
      title: 'רשימה לבנה',
      shouldShow: () => this.isAdmin(),
      routerLink: '/white-list'
    },
    {
      title: 'מוצרים',
      shouldShow: () => this.isAdmin(),
      routerLink: '/habit-products-list'
    },
    {
      title: 'קטגוריות הביט',
      shouldShow: () => this.isAdmin(),
      routerLink: '/habit-categories'
    },
    {
      title: 'ניהול קמפיינים',
      shouldShow: () => true,
      // onClick: () => this.showCreateCampaignDialog(),
      routerLink: '/campaigns'
    },
    {
      title: 'תשלומים עתידיים',
      shouldShow: () => this.isAdmin(),
      routerLink: '/future-payments'
    },
    {
      title: 'שיתופי חברים',
      shouldShow: () => this.isAdmin(),
      routerLink: '/invites'
    },
    {
      title: 'לוגים',
      shouldShow: () => this.isAdmin(),
      routerLink: '/logs'
    },
  ];

  isTestServer = false;
  userImage;


  constructor(private breakpointObserver: BreakpointObserver, private router: Router, public dialog: MatDialog, private httpService: HttpService) {
    this.role = localStorage.getItem('role');
    this.name = localStorage.getItem('name');
    this.userImage = localStorage.getItem('image') || 'assets/logo.png';
    console.log('image', localStorage.getItem('image'));


    router.events.subscribe((val) => {
      if (val['url']) {
        this.currentUrl = val['url'];
        // console.log(this.currentUrl);
        for (let i = 0; i < this.hiddenSearchRoutes.length; ++i) {
          if (this.hiddenSearchRoutes[i].endsWith(this.currentUrl)) {
            this.shouldShowDatePicker = false;
            return;
          }
        }
        this.shouldShowDatePicker = true;
      }
    });

    console.log('role', localStorage.getItem('role'));


  }

  ngOnInit() {
    this.isTestServer = localStorage.getItem('isTestServer') === 'true';

    this.isHandset$.subscribe(handset => {
      this.isMobile = handset;
    });


    // this.showCreateCampaignDialog();
  }

  closeDrawer() {
    if (this.isMobile) {
      this.drawer.close();
    }
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }

  getClassFor(url) {
    return this.currentUrl.endsWith(url) ? 'menuItemSelected' : 'menuItem';
  }

  isAdmin() {
    return localStorage.getItem('role') === 'admin';
  }

  isChainMaster() {
    return localStorage.getItem('role') === 'chainMaster';
  }

  getRoleText() {
    if (this.isAdmin()) {
      return 'מנהל';
    }

    if (this.isChainMaster()) {
      return 'מנהל רשת';
    }

    return 'מנהל סניף';

  }

  shouldShowOfflineOrders() {
    return localStorage.getItem('showOfflineOrders') === 'true';
  }

  shouldShowOnlineOrders() {
    return localStorage.getItem('showOnlineOrders') === 'true';
  }


  async showCreateCampaignDialog() {

    let chain = localStorage.getItem('chain');
    console.log(chain);
    const dialogRef = this.dialog.open(CreateCampaignDialogComponent, {
      width: '90vw',
      maxWidth: '700px',
      panelClass: 'dialog-style',
      data: {
        supplierId: chain
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    console.log('after close', result);
    if (!result) {
      return;
    }
  }


  isLocal = () => !environment.production;

  setServer(isTest) {
    return;
    if (!this.isLocal) {
      return;
    }

    this.isTestServer = isTest;

    console.log(isTest);
    this.httpService.setServer(isTest);

  }
}
