<button class="dialogCloseButton" (click)="close()">X</button>


<div *ngIf="isNew" class="bg-success d-block text-center p-2">
  <h4 class="text-white">פעולה חדשה</h4>
</div>

<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <div class="d-flex flex-column justify-content-center p-3" style="direction: rtl">


    <!-- Chain details -->

    <div class="row">

      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">פרטי העברה</li>
          <li class="list-group-item">
            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">אסמכתא</label>
              <input class="form-control input-width-sm" [(ngModel)]="transfer.reference">
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">תאריך העברה</label>
              <input type="date" class="form-control input-width-sm"
                     [(ngModel)]="transfer.transferDate">
            </div>
          </li>

        </ul>
      </div>

    </div>


    <div class="row mt-3">
      <div class="col-12">
        <button (click)="save()" class="btn btn-success btn-block">שמירה</button>
      </div>
    </div>

  </div>
</div>
