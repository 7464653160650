import {Component, OnInit} from '@angular/core';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material';
import {DataService} from '../../../service/data/data.service';
import {getClassName} from 'codelyzer/util/utils';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent extends BaseListComponent {

  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dialog: MatDialog, dataService: DataService) {
    super(httpService, activatedRoute, dataService, dialog);
  }

  async loadData() {
    super.loadData();
    const res = await this.httpService.getTransactionsHistory(this.sortValue, this.filters, this.customerId);
    this.checkResults(res);
    console.log(this.getClassName());
    console.log(res);
    this.results = this.results || [];
    this.results = this.results.concat(res['result']);
    this.total = res['result'].total;
    this.afterLoadData(this.results.length);



   /* amount: 0.00003555555555555556
    balance: 0.21
    date: "2020-06-28T14:50:02.147Z"
    id: "5ef8ae1abef1810004e98d71"
    image: "http://res.cloudinary.com/dkeupcske/image/upload/c_fit,h_160,w_160/v1591866164/5ee1f30e36d8030015ea1ca8_1591866163815.png"
    order: {id: "5ef8ae12cc830d0015b5a4c3"}
    status: "completed"
    supplier: {id: "5ee1f30e36d8030015ea1ca8", name: "BBB", street: "יגאל אלון", houseNumber: "96", city: "תל אביב", …}
    transactionType: "distribution"*/
  }


  getTransactionTitle(result) {
    return result.transactionType;
  }

  getClassName = (): String => 'TransactionsComponent';

}
