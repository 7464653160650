<app-loader *ngIf="showLoader"></app-loader>
<button *ngIf="false" class="dialogCloseButton" (click)="close()">X</button>


<div class="bg-success d-block text-center p-2">
  <h4 class="text-white">יצירת קמפיין חלוקה</h4>
</div>

<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <div class="d-flex flex-column justify-content-center p-3" style="direction: rtl">


    <!-- details -->

    <div class="row">

      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li *ngIf="false" class="list-group-item active text-white text-center" style="direction: rtl">פרטי רשת</li>
          <li class="list-group-item">

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">תחילת קמפיין</label>
              <ejs-datetimepicker class="mt-2" [(ngModel)]="startDate"></ejs-datetimepicker>

            <!--  <mat-form-field>
                <input matInput (ngModelChange)="startDate = $event" type="datetime-local" [value]="startDate"
                       [(ngModel)]="startDate" placeholder="start date">
              </mat-form-field>-->
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">תקציב קמפיין</label>
              <input class="form-control input-width-sm" [(ngModel)]="budget">
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">תדירות רכישה בדקות</label>
              <input class="form-control input-width-sm" [(ngModel)]="frequencyMinutes">
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">סכום רכישה בכל פעימה</label>
              <input class="form-control input-width-sm" [(ngModel)]="avgDistributionSize">
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">סכום מקסימלי למשתמש</label>
              <input class="form-control input-width-sm" [(ngModel)]="maxDistributionPerUser">
            </div>

            <div class="d-flex flex-row">
              <mat-slide-toggle class="ml-3" [(ngModel)]="isActive" color="primary"></mat-slide-toggle>
              <p class="p-0 m-0 ml-2"> {{isActive ? '   קמפיין פעיל   ' : 'קמפיין לא פעיל' }}</p>
            </div>
          </li>

          <li *ngIf="numOfUsersInArea" class="text-center">
            {{getNumOfUsersText()}}
          </li>

        </ul>
      </div>

    </div>


    <div class="row mt-3">
      <div class="col-12">
        <button (click)="save()" class="btn btn-success btn-block">שמירה</button>
      </div>
    </div>

  </div>

</div>
