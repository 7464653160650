<app-loader *ngIf="showLoader"></app-loader>

<div class="container">

  <div  class="row mt-3" style="direction: rtl">
    <div  class="col p-0 m-0">
      <div class="d-flex flex-row" style="direction: rtl">
        <input (keydown.enter)="search(searchField.value)" class="form-control ml-2 w-25" type="text"
               placeholder="הזן שם רשת" #searchField>
        <button (click)="localSearch(searchField.value)" class="btn btn-primary">חפש</button>
      </div>
    </div>

    <app-search *ngIf="false" hint="חפש" customers=false (itemSelected)="search($event)"></app-search>

  </div>


  <div class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">
    <span style="direction: rtl">
          <label class="ml-3">{{total.count}} שיתופים</label>
          <label class="ml-3">{{formatPrice(total.total)}}</label>
    </span>

  </div>


  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="loadData()">

    <table *ngIf="results" class="table table-hover mt-2" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>#</th>
        <th (click)="setSortValue('created')" scope="col">
          <span *ngIf="sortValue === 'created'">↑</span>
          <span *ngIf="sortValue === '-created'">↓</span>
          תאריך
        </th>
        <th (click)="setSortValue('customerId')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'customerId'">↑</span>
          <span *ngIf="sortValue === '-customerId'">↓</span>
          לקוח
        </th>
        <th scope="col">בונוס שחולק</th>
        <th scope="col">בונוס שחולק</th>
        <th scope="col">פעולות</th>
      </tr>
      </thead>


      <tbody class="text-right">
      <tr *ngFor="let result of results; let i = index">
        <td>{{i}}</td>
        <td (click)="toggleFilter('created', result.created,'תאריך - ' + getDateStr(result.created))"
            scope="row">{{result.created | date:'dd.MM.yyyy HH:mm'}}</td>

        <th (click)="toggleFilter('customerId', result.customer.id,'לקוח - '  + getFullName(result.customer.name))">
          {{getFullName(result.customer.name)}}
          <span (click)="showUserProfileDialog(result.customer.id)"
                style="cursor: pointer"
                class="badge badge-pill badge-primary">פרופיל</span>
        </th>

        <th scope="col">{{formatPrice(result.totalRewardGiven)}}</th>
        <th>
          <button *ngIf="result.invitees.length>0" (click)="showInvitieesDialog(result)" class="btn btn-sm btn-primary">צפייה במוזמנים
            ({{result.invitees.length}})
          </button>
        </th>
        <td>
          <!--<div class="d-flex flex-row mt-2">
            <mat-icon style="cursor: pointer">edit</mat-icon>
          </div>-->
        </td>

      </tr>
      </tbody>
    </table>
  </div>

</div>
