import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base-component/base.component';
import {HttpService} from '../../service/http/http.service';
import {DataService} from '../../service/data/data.service';

const DAY = 1000 * 60 * 60 * 24;

@Component({
  selector: 'app-users-location',
  templateUrl: './users-location.component.html',
  styleUrls: ['./users-location.component.scss']
})
export class UsersLocationComponent extends BaseComponent {

  latitude = -28.68352;
  longitude = -147.20785;

  list;


  options = [
    {
      title: 'היום האחרון',
      value: DAY
    },
    {
      title: 'היומיים האחרונים',
      value: DAY * 2
    },
    {
      title: '3 ימים האחרונים',
      value: DAY * 3
    },
    {
      title: '4 ימים האחרונים',
      value: DAY * 4
    },
    {
      title: 'השבוע האחרון',
      value: DAY * 7
    },
  ];

  selectedValue = this.options[0].value;

  constructor(httpService: HttpService, private dataService: DataService) {
    super(httpService);
  }

  async ngOnInit() {
    this.loadData();
  }

  async loadData() {
    const from = Date.now() - this.selectedValue;
    console.log('startTime', new Date(from));
    const res = await this.httpService.getCustomersLocations(from);
    this.list = res['result']['list'];
    this.latitude = this.list[0].locationMark.lat;
    this.longitude = this.list[0].locationMark.lon;
    console.log(this.list);
  }

  onMarkerClicked(locationItem, event) {

  }
}
