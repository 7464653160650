import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {isNullOrUndefined} from 'util';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log('can activate called');
    const role = localStorage.getItem('role');
    // console.log('token is: ' + token);

    if (role !== 'admin') {
      console.error('not an admin, page is blocked');
      this.router.navigateByUrl('/');
      return false;
    }

    // console.log('auth ok');
    return true;
  }
}
