<app-loader *ngIf="showLoader"></app-loader>

<div class="container">

  <div class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div *ngIf="!isExternalData" class="row mt-3" style="direction: rtl">
    <div *ngIf="false" class="col p-0 m-0">
      <div class="d-flex flex-row">
        <input (keydown.enter)="search(searchField.value)" class="form-control ml-2 w-25" type="text"
               placeholder="חיפוש חופשי" #searchField>
        <button (click)="search(searchField.value)" class="btn btn-primary">חפש</button>
      </div>
    </div>

    <app-search hint="חיפוש חופשי" (itemSelected)="search($event)"></app-search>

  </div>

  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">
    <span style="direction: rtl">
          <label class="ml-3">{{total.count}} עסקאות </label>
          <label class="ml-3">{{formatPrice(total.amount)}}</label>
    </span>

    <span (click)="createExcel()" style="cursor: pointer">
       <label style="color: #107C41; cursor: pointer">שמור כקובץ אקסל</label>
       <img src="assets/excel.png" width="35px" height="50px">
    </span>

  </div>

  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="loadData()">
    <table *ngIf="results" class="table table-hover mt-3" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>#</th>
        <th (click)="setSortValue('created')" scope="col">
          <span *ngIf="sortValue === 'created'">↑</span>
          <span *ngIf="sortValue === '-created'">↓</span>
          תאריך
        </th>
        <th (click)="setSortValue('amount')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'amount'">↑</span>
          <span *ngIf="sortValue === '-amount'">↓</span>
          <p class="m-0">סכום <span *ngIf="!isExternalData">({{formatPrice(total.amount)}})</span></p>
        </th>
        <th class="text-right">תשלום לספק</th>
        <th (click)="setSortValue('futurePaymentsAmount')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'futurePaymentsAmount'">↑</span>
          <span *ngIf="sortValue === '-futurePaymentsAmount'">↓</span>
          <p class="m-0">סכום מימון <span *ngIf="!isExternalData">({{formatPrice(total.futurePaymentsAmount)}})</span>
          </p>
        </th>
        <th (click)="setSortValue('status')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'status'">↑</span>
          <span *ngIf="sortValue === '-status'">↓</span>
          <p class="m-0">סטטוס</p>
        </th>
        <th (click)="setSortValue('customerId')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'customerId'">↑</span>
          <span *ngIf="sortValue === '-customerId'">↓</span>
          לקוח
        </th>
        <th (click)="setSortValue('supplierId')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'supplierId'">↑</span>
          <span *ngIf="sortValue === '-supplierId'">↓</span>
          ספק
        </th>
        <th>קופה</th>
        <th>מסמכים</th>
        <th>פעולות</th>
      </tr>
      </thead>
      <tbody class="text-right">
      <tr *ngFor="let result of results; let i = index">
        <td>{{i}}</td>
        <td
          (click)="toggleFilter('created', result.created,'תאריך - ' + getDateStr(result.created))">{{result.created | date:'dd.MM.yyyy HH:mm'}}</td>
        <td>
          <div class="d-flex flex-column justify-content-center">
            <p
              (click)="toggleFilter('amount', result.amount,'סכום - '+result.amount)">{{formatPrice(result.amount)}}</p>
            <p *ngIf="result.originalAmount !== result.amount"
               [ngClass]="{ 'line-through' : true }">{{formatPrice(result.originalAmount)}}</p>
          </div>
        </td>
        <td>{{formatPrice(result.supplierPayment)}}</td>
        <td
          (click)="toggleFilter('futurePaymentsAmount', result.futurePaymentsAmount,'סכום מימון - ' + result.futurePaymentsAmount)">{{formatPrice(result.futurePaymentsAmount)}}</td>
        <td
          (click)="toggleFilter('status', result.status,'סטטוס - ' + result.status)">{{formatOrderStatus(result.status, isExternalData)}}</td>
        <td
          (click)="toggleFilter('customerId', result.customer.id,'לקוח - '  + getFullName(result.customer.name))">

          {{result.customer.name.first}} {{result.customer.name.last}}
          <span *ngIf="!customerId && isAdmin()" (click)="showUserProfileDialog(result.customer.id)"
                style="cursor: pointer"
                class="badge badge-pill badge-primary">פרופיל</span>
        </td>
        <td
          (click)="toggleFilter('supplierId', result.supplier.id,'ספק - '  + result.supplier.name)">{{result.supplier.name}}</td>
        <td>{{result.register || '-'}}</td>
        <td>
          <div class="d-flex flex-row">
            <div *ngIf="isAdmin() && hasInvoice(result)" (click)="openInvoice(result.invoice)"
                 class="d-flex flex-column align-items-center justify-content-center" style="cursor: pointer">
              <mat-icon aria-hidden="false" class="align-self-center text-muted mat-icon-30">
                insert_drive_file
              </mat-icon>
              <h6 class="text-muted small">חשבונית</h6>
            </div>

            <div *ngIf="hasRefund(result)" (click)="openRefund(result.refunds)"
                 class="d-flex flex-column align-items-center justify-content-center text-success ml-2 mr-2"
                 style="cursor: pointer">
              <mat-icon aria-hidden="false" class="align-self-center mat-icon-30 text-success">
                insert_drive_file
              </mat-icon>
              <h6 class="text-muted small">זיכוי</h6>
            </div>

          </div>
        </td>
        <td>
          <div class="d-flex flex-row">
            <div *ngIf="result.amount > 0 && enableCashback" (click)="showRefundDialog(result)"
                 class="d-flex flex-column align-items-center justify-content-center mr-2 ml-2" style="cursor: pointer">
              <mat-icon aria-hidden="false" class="align-self-center text-success mat-icon-30 ">
                attach_money
              </mat-icon>
              <h6 class="text-muted small">זיכוי</h6>
            </div>

          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
