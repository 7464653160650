export class LogItem {
  public id: string;
  public log: string;
  public device: string;
  public user: string;
  public logType: number;
  public storeVersion: string;
  public debugVersion: string;
  public time: number;
}
