import {Component, OnInit} from '@angular/core';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material';
import {DataService} from '../../../service/data/data.service';
import {EditSupplierDialogComponent} from '../../dialog/edit-supplier-dialog/edit-supplier-dialog.component';
import {EditHabitProductDialogComponent} from '../../dialog/edit-habit-product-dialog/edit-habit-product-dialog.component';
import {ImageDialogComponent} from '../../views/image-dialog/image-dialog.component';

@Component({
  selector: 'app-habit-products-list',
  templateUrl: './habit-products-list.component.html',
  styleUrls: ['./habit-products-list.component.scss']
})
export class HabitProductsListComponent extends BaseListComponent implements OnInit {

  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dialog: MatDialog, dataService: DataService) {
    super(httpService, activatedRoute, dataService, dialog);
  }


  ngOnInit() {
    super.ngOnInit();

    // no date filter on customers list
    delete this.filters['startDate'];
    delete this.filters['endDate'];
  }


  async loadData() {
    super.loadData();
    const res = await this.httpService.getHabitProducts(this.sortValue, this.filters);
    console.log(res);
    this.checkResults(res);
    this.results = this.results || [];
    this.results = this.results.concat(res['result'].list);
    this.total = res['result'].total;
    this.afterLoadData(res['result'].list.length);
  }

  getClassName = (): String => 'HabitProductsListComponent';


  async showDialog(product) {
    const dialogRef = this.dialog.open(EditHabitProductDialogComponent, {
      // maxWidth: '600px',
      panelClass: 'dialog-style-no-padding',
      data: {
        product: product
      }
    });

    let result = await dialogRef.afterClosed().toPromise();
    console.log('after close', result);
    if (!result) {
      return;
    }

    if (product != null) {
      Object.assign(product, result);
    } else {
      // new product was added
      this.loadData();
    }

  }


  async openImageDialog(product) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: product.images.thumbUrl
      }
    });

    const file = await dialogRef.afterClosed().toPromise();
    if (!file) {
      return;
    }

    console.log(file);
    // this.uploadFile(file, supplier);

    this.showLoader = true;
    const res = await this.httpService.uploadProductImage(product.id, file);
    console.log(res);
    this.showLoader = false;


    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }

    const p = res['result'];
    product.images.thumbUrl = p.images.thumbUrl;
  }

}
