import {Component, OnInit} from '@angular/core';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material';
import {DataService} from '../../../service/data/data.service';
import {EditChainDialogComponent} from '../../dialog/edit-chain-dialog/edit-chain-dialog.component';

@Component({
  selector: 'app-chains',
  templateUrl: './chains.component.html',
  styleUrls: ['./chains.component.scss']
})
export class ChainsComponent extends BaseListComponent {

  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dialog: MatDialog, dataService: DataService) {
    super(httpService, activatedRoute, dataService, dialog);
  }

  async loadData() {
    super.loadData();
    const res = await this.httpService.getChains(this.sortValue, this.filters);
    this.checkResults(res);
    this.results = this.results || [];
    this.results = this.results.concat(res['result'].list);
    this.total = res['result'].total;
    // this.openBalance = res['result'].openBalance;
    // this.afterLoadData(res['result'].list.length);
    this.afterLoadData(this.results.length);

  }




  async showChainDialog(chain) {
    console.log(chain);

    const dialogRef = this.dialog.open(EditChainDialogComponent, {
      width: '90vw',
      maxWidth: '600px',
      height: '80vh',
      panelClass: 'dialog-style',
      data: {
        chain
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    console.log('after close', result);
    if (!result) {
      return;
    }

    if (chain != null) {
      Object.assign(chain, result);
    }

    this.loadData();

  }

  async uploadFile(event, supplier) {
    const file = event.target.files[0];
    // console.log(event.target.files[0]);
    const res = await this.httpService.updateSupplierImage(supplier.id, file);
    console.log(res);
    const status: string = res['status'];
    if (status.toLowerCase() != 'ok') {
      return;
    }
    supplier.image = res['result']['images']['thumbUrl'];

  }

  getClassName = (): String => 'ChainsComponent';


}
