import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {Utils} from '../../../util/utils';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-edit-chain-dialog',
  templateUrl: './edit-chain-dialog.component.html',
  styleUrls: ['./edit-chain-dialog.component.scss']
})
export class EditChainDialogComponent extends BaseDialogComponent<EditChainDialogComponent> {

  chain;
  originalChain;
  newPhone;


  selectedSdkClientValue;
  selectedSdkClientOptions = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditChainDialogComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.isNew = data.chain == null;
    if (!this.isNew) {
      this.chain = JSON.parse(JSON.stringify(data.chain));
      this.originalChain = data.chain;
    }

    this.initChain();
    console.log(this.chain);
  }

  async initChain() {

    const res = await this.httpService.getSdkClients();
    this.selectedSdkClientOptions = res['result']['list'];
    this.selectedSdkClientValue = this.selectedSdkClientOptions[0];

    console.log(res);

    if (!this.isNew) {
      const t = await this.httpService.getChain(this.originalChain.id);
      this.chain = t['result']['list'][0];
      // console.log(tt);
    }

    if (this.chain == null) {
      this.chain = {
        name: '',
        hebrewName: '',
      };
    }

    // this.chain.linkedCustomerNumbers = this.chain.linkedCustomerNumbers || [];
    this.chain.linkedCustomers = this.chain.linkedCustomers || [];
    this.chain.sdkClients = this.chain.sdkClients || [];

  }

  async addLinkedClient() {

    this.chain.linkedCustomers = this.chain.linkedCustomers || [];
    const linkedCustomerNumbers = this.chain.linkedCustomers.map(i => i.phone);
    if (this.newPhone) {
      console.log('has phone');
      linkedCustomerNumbers.push(Utils.formatPhone(this.newPhone));
    }
    this.chain.linkedCustomerNumbers = linkedCustomerNumbers;

    const res = await this.httpService.updateChain(this.chain);
    const status: string = res['status'];
    if (status.toLowerCase() === 'ok') {
      this.chain = res['result'];
      this.newPhone = null;
    }


    console.log(res);

    // customerPhones
  }

  async removeLinkedClient(index, item) {
    const a = await this.dataService.yesNoDialog('האם ברצונך להסיר את מנהל הרשת?');
    console.log(a);
    if (!a) {
      return;
    }
    this.chain.linkedCustomers.splice(index, 1);
    this.newPhone = undefined;
    await this.addLinkedClient();
  }

  addSdkClient() {
    const toAdd = this.selectedSdkClientOptions[this.selectedSdkClientValue];
    if (this.chain.sdkClients.indexOf(toAdd) > -1) {
      this.showOkDialog('חיבור זה כבר קיים', true);
      return;
    }

    this.chain.sdkClients.push(this.selectedSdkClientOptions[this.selectedSdkClientValue]);
  }


  async save() {

    // input check
    if (!this.chain.name) {
      this.showSnackBar('יש להזין שם רשת');
      return;
    }


    this.error = '';

    // set only sdkClient ids
    this.chain.sdkClients = this.chain.sdkClients.map(s => s.id);


    const res = this.isNew ? await this.httpService.addChain(this.chain) :
      await this.httpService.updateChain(this.chain);
    console.log(res);

    const status = res['status'] as string;
    if (status.toLowerCase() === 'ok') {
      this.dialogRef.close(res['result']);
      return;
    }

    // this.error = status;
    this.showSnackBar('שגיאה: ' + status);
    // this.originalSupplier = res['result'];
  }


}
