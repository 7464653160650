import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../service/http/http.service';
import {MatDatepicker} from '@angular/material';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {BaseComponent} from '../base/base-component/base.component';
import {DataService} from '../../service/data/data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent {

  statusResponse;
  startDate = new Date();
  endDate = new Date();

  showLoader = false;

  /*  graphs  */
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {xAxes: [{}], yAxes: [{}]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public barChart1Labels: Label[] = [];
  public barChart1Type: ChartType = 'bar';
  public barChart1Legend = true;
  public barChart1Data: ChartDataSets[] = [
    {data: [], label: 'סכום רכישות כולל', backgroundColor: '#F44972'},
    {data: [], label: 'סכום מימון כולל', backgroundColor: '#495DF5'},
  ];


  public barChart2Labels: Label[] = [];
  public barChart2Type: ChartType = 'bar';
  public barChart2Legend = true;
  public barChart2Data: ChartDataSets[] = [
    {data: [], label: 'מספר קניות בחנות', backgroundColor: '#495DF5'}
  ];


  constructor(httpService: HttpService, private dataService: DataService) {
    super(httpService);
  }

  async ngOnInit() {
    super.ngOnInit();

    this.endDate = new Date();
    this.startDate.setDate(new Date().getDate() - (30 * 12));


    this.loadChartData();


    this.dataService.currentDates.subscribe(newDates => {

      const defaultStartDate = new Date();
      defaultStartDate.setDate(new Date().getDate() - 30);

      this.startDate = newDates.startDate || defaultStartDate;
      this.endDate = newDates.endDate || new Date();

      this.loadData();
    });

    this.loadData();
  }

  async loadData() {
    try {
      this.showLoader = true;
      const res = await this.httpService.getStatus(this.startDate.getTime(), this.endDate.getTime());
      this.statusResponse = res['result'];
      // console.log(this.statusResponse);
    } catch (e) {
      console.error(e);
    }
    this.showLoader = false;

  }

  async loadChartData() {
    const res = await this.httpService.getOfflineOrdersDaily();
    console.log('chart data', res);

    let graphData = res['result'];
    graphData = graphData.slice(0, 15).reverse();

    this.barChart1Labels = graphData.map(i => i.date);
    this.barChart1Data[0].data = graphData.map(i => i.amountTotal);
    this.barChart1Data[1].data = graphData.map(i => i.futurePaymentsTotal);


    this.barChart2Labels = graphData.map(i => i.date);
    this.barChart2Data[0].data = graphData.map(i => i.count);
    // console.log(this.graphData);
  }

  getCountText(regular, withFunding) {
    let answer = regular === 1 ? 'פעולה אחת' : (regular + ' ' + 'פעולות');
    if (regular === 0 || withFunding === null) {
      return answer;
    }
    if (withFunding === regular) {
      if (withFunding === 1) {
        answer += ' עם מימון';
        return answer;
      }
      answer += ', כולם עם מימון';
      return answer;
    }
    answer += ', ' + withFunding + ' עם מימון';
    return answer;
  }

  isAdmin() {
    return localStorage.getItem('role') === 'admin';
  }

  shouldShowOfflineOrders() {
    return localStorage.getItem('showOfflineOrders') === 'true';
  }

  shouldShowOnlineOrders() {
    return localStorage.getItem('showOnlineOrders') === 'true';
  }
}

