import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {isNullOrUndefined} from 'util';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  URL_TEST = 'https://habit-test-server.herokuapp.com/v2/api/admin/';
  URL_LIVE = 'https://habitserver.herokuapp.com/v2/api/admin/';

  baseUrl = this.URL_LIVE;


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      token: localStorage.getItem('token'),
      // 'Access-Control-Allow-Origin': 'http://localhost:4200'
    })
  };

  httpOptionsNoContentType = {
    headers: new HttpHeaders({
      token: localStorage.getItem('token'), // 4
      // 'Access-Control-Allow-Origin': 'http://localhost:4200'
    })
  };

  loginHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  fileOptions = {
    responseType: 'blob' as 'json',
    headers: new HttpHeaders({
      token: localStorage.getItem('token'),
    })
  };

  constructor(private http: HttpClient) {
    const isTestServer = localStorage.getItem('isTestServer') === 'true';
    this.baseUrl = isTestServer ? this.URL_TEST : this.URL_LIVE;
  }

  setServer(isTest) {
    localStorage.setItem('isTestServer', isTest ? 'true' : 'false');
    this.baseUrl = isTest ? this.URL_TEST : this.URL_LIVE;
  }

  // update all options headers (token etc...)
  updateOptions() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'), // 4
        // 'Access-Control-Allow-Origin': 'http://localhost:4200'
      })
    };

    this.httpOptionsNoContentType = {
      headers: new HttpHeaders({
        token: localStorage.getItem('token'), // 4
        // 'Access-Control-Allow-Origin': 'http://localhost:4200'
      })
    };

    this.loginHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.fileOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        token: localStorage.getItem('token'),
      })
    };
  }

  // login
  login(email, password) {
    return this.http.post(this.baseUrl + 'signin', {
      email,
      password
    }, this.loginHttpOptions).toPromise();
  }

  // login
  loginWithPhone(phone) {
    return this.http.post(this.baseUrl + 'phone/signin', {
      phone,
    }, this.loginHttpOptions).toPromise();
  }

  phoneConfirmation(phone, code) {
    return this.http.post(this.baseUrl + 'phone/confirmation', {
      phone,
      code
    }, this.loginHttpOptions).toPromise();
  }

  updateCustomer(customer) {
    return this.http.post(this.baseUrl + 'customer/update', customer, this.httpOptions).toPromise();
  }

  getCustomerStatus(customerId) {
    return this.http.get(this.baseUrl + 'dashboard?customerId=' + customerId, this.httpOptions).toPromise();
  }

  getStatus(startDate, endDate) {
    // return this.http.get(`${this.baseUrl}dashboard?startDate=${startDate}&endDate=${endDate}`, this.httpOptions).toPromise();
    return this.http.get(this.baseUrl + 'dashboard?startDate=' + startDate + '&endDate=' + endDate, this.httpOptions).toPromise();
    // return this.http.get(this.baseUrl + 'dashboard', this.httpOptions).toPromise();
  }

  /*******  Lists  **********/

  getOfflineOrders(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'offlineOrders?page=' + f.page, {
      sort: sortValue,
      filters: f
    }, this.httpOptions).toPromise();
  }

  getLeverages(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'leverages?page=' + f.page, {
      sort: sortValue,
      filters: f
    }, this.httpOptions).toPromise();
  }

  getCustomers(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'customers?page=' + f.page, {
      sort: sortValue,
      filters: f
    }, this.httpOptions).toPromise();
  }

  getSuppliers(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'suppliers?page=' + f.page, {
      sort: sortValue,
      filters: f
    }, this.httpOptions).toPromise();
  }

  getSupplier(id) {
    // const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'suppliers', {
      filters: {id}
    }, this.httpOptions).toPromise();
  }

  async getOneSupplier(id) {
    try {
      const response = await this.http.post(this.baseUrl + 'suppliers', {
        filters: {id}
      }, this.httpOptions).toPromise();

      const supplier = response['result']['list'][0];
      return supplier;
    } catch (e) {
      return null;
    }

  }

  getChains(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'chains?page=' + f.page, {
      sort: sortValue,
      filters: f
    }, this.httpOptions).toPromise();
  }

  getChain(id) {
    console.log('get chain', id);
    // const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'chains', {
      filters: {id},
    }, this.httpOptions).toPromise();
  }

  getCampaigns(sortValue, filters) {

    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'campaigns?page=' + f.page, {
      sort: sortValue,
      filters: f
    }, this.httpOptions).toPromise();

    // const params = isNullOrUndefined(supplierId) ? '' : ('?supplierId=' + supplierId);
    // return this.http.get(this.baseUrl + 'campaigns' + params, this.httpOptions).toPromise();
  }

  /*
  getCampaigns(supplierId) {
    const params = isNullOrUndefined(supplierId) ? '' : ('?supplierId=' + supplierId);
    return this.http.get(this.baseUrl + 'campaigns' + params, this.httpOptions).toPromise();
  }*/


  editCampaign(campaign) {
    // campaign['campaignId'] = campaign.id;
    return this.http.post(this.baseUrl + 'campaign/edit', campaign, this.httpOptions).toPromise();
  }

  deleteCampaign(campaignId, supplierId) {
    const params = {
      campaignId,
    };

    if (supplierId) {
      params['supplierId'] = supplierId;
    }

    return this.http.post(this.baseUrl + 'campaign/remove', params, this.httpOptions).toPromise();
  }

  crateCampaign(body) {
    return this.http.post(this.baseUrl + 'campaign/create', body, this.httpOptions).toPromise();
  }

  getDistributionCampaigns(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'distribution/campaigns?page=' + f.page, {
      sort: sortValue,
      filters: f
    }, this.httpOptions).toPromise();


    // const params = isNullOrUndefined(supplierId) ? '' : ('?supplierId=' + supplierId);
    // return this.http.get(this.baseUrl + 'distribution/campaigns' + params, this.httpOptions).toPromise();
  }

  /*getDistributionCampaigns(supplierId) {
    const params = isNullOrUndefined(supplierId) ? '' : ('?supplierId=' + supplierId);
    return this.http.get(this.baseUrl + 'distribution/campaigns' + params, this.httpOptions).toPromise();
  }*/

  crateDistributionCampaign(campaign) {
    return this.http.post(this.baseUrl + 'distribution/campaign/create', campaign, this.httpOptions).toPromise();
  }

  editDistributionCampaign(campaign) {
    return this.http.post(this.baseUrl + 'distribution/campaign/edit', campaign, this.httpOptions).toPromise();
  }

  getExpectedFuturePayments(discount, supplierId) {
    let params = '?discount=' + discount;
    params += isNullOrUndefined(supplierId) ? '' : '&supplierId=' + supplierId;
    return this.http.get(this.baseUrl + 'campaigns/expectedFuturePayments' + params, this.httpOptions).toPromise();

  }

  getNumOfUsersInArea(supplierId) {
    return this.http.get(this.baseUrl + 'usersInArea?supplierId=' + supplierId, this.httpOptions).toPromise();
  }


  getOnlineOrders(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'onlineOrders?page=' + f.page, {
      sort: sortValue,
      filters: f
    }, this.httpOptions).toPromise();
  }

  getInvites(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'invitations?page=' + f.page, {
      sort: sortValue,
      filters: f
    }, this.httpOptions).toPromise();
  }

  getUserToUserTransfer(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'userToUserTransfers?page=' + f.page, {
      sort: sortValue,
      filters: f
    }, this.httpOptions).toPromise();
  }

  getBankTransfer(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'bankTransfers?page=' + f.page, {
      sort: sortValue,
      filters: f,
    }, this.httpOptions).toPromise();
  }

  getWhiteList() {
    return this.http.get(this.baseUrl + 'whitelist', this.httpOptions).toPromise();
  }

  addNumberToWhiteList(number) {
    return this.http.post(this.baseUrl + 'whitelist/add', {
      phoneNumber: number
    }, this.httpOptions).toPromise();
  }

  removeNumberFromWhiteList(number) {
    return this.http.post(this.baseUrl + 'whitelist/remove', {
      phoneNumber: number
    }, this.httpOptions).toPromise();
  }

  getSupplierPayments(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'supplier/banktransfers?page=' + f.page, {
      sort: sortValue,
      filters: f,
    }, this.httpOptions).toPromise();
  }

  getFuturePayments(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'futurePayments', {
      sort: sortValue,
      filters: f,
    }, this.httpOptions).toPromise();
  }

  getTransactionsHistory(sortValue, filters, customerId) {
    const f = this.clearFilter(filters);
    f.customerId = customerId;

    let params = 'page=' + f.page + '&userType=customer&customerId=' + customerId;
    if (f.transactionType) {
      params += '&transactionType=' + f.transactionType;
    }
    return this.http.get(this.baseUrl + 'transactions?' + params, this.httpOptions).toPromise();
  }


  /*********  End of lists  **********/

  getCustomersLocations(startDate) {
    return this.http.get(this.baseUrl + 'customers/location?startDate=' + startDate, this.httpOptions).toPromise();
  }

  getFuturePaymentPerMonth(year, month, customerId, isLeverage = false) {
    return this.http.get(this.baseUrl + `getFuturePayments/month?year=${year}&month=${month}&customerId=${customerId}&isLeverage=${isLeverage}`, this.httpOptions).toPromise();
  }

  getOfflineOrdersDaily() {
    return this.http.get(this.baseUrl + 'offlineOrders/daily', this.httpOptions).toPromise();
  }


  updateSupplier(supplier) {
    return this.http.post(this.baseUrl + 'supplier/update', supplier, this.httpOptions).toPromise();
  }

  addSupplier(supplier) {
    return this.http.post(this.baseUrl + 'supplier/add', supplier, this.httpOptions).toPromise();
  }

  addChain(chain) {
    return this.http.post(this.baseUrl + 'chain/create', chain, this.httpOptions).toPromise();
  }

  makeRefund(offlineOrderId, onlineOrderId, amount) {
    const body = {
      offlineOrderId,
      onlineOrderId,
      amount
    };
    return this.http.post(this.baseUrl + 'order/refund', body, this.httpOptions).toPromise();
  }

  updateChain(chain) {
    return this.http.post(this.baseUrl + 'chain/update', chain, this.httpOptions).toPromise();
  }

  updateSupplierPaymentTransfer(transfer) {
    return this.http.post(this.baseUrl + 'supplier/banktransfers/update', transfer, this.httpOptions).toPromise();
  }

  updateSupplierPaymentTransferDetails(transferId) {
    return this.http.get(this.baseUrl + 'supplier/banktransfers/details?id=' + transferId, this.httpOptions).toPromise();
  }

  updateBankTransfer(transfer) {
    return this.http.post(this.baseUrl + 'banktransfers/update', transfer, this.httpOptions).toPromise();
  }

  updateSupplierImage(supplierId, image) {

    console.log(supplierId);
    const formData = new FormData();
    formData.append('image', image);
    formData.append('supplierId', supplierId);
    formData.append('isPrintableVersion', 'false');

    if (this.baseUrl === this.URL_LIVE) {
      return this.http.post('https://habitserver.herokuapp.com/api/admin/supplier/image', formData, this.httpOptionsNoContentType).toPromise();
    } else {
      return this.http.post('https://habit-test-server.herokuapp.com/api/admin/supplier/image', formData, this.httpOptionsNoContentType).toPromise();
    }
  }

  getOneCustomer(customerId) {
    // const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'customers', {
      filters: {customerId: customerId}
    }, this.httpOptions).toPromise();
  }

  getSdkClients() {
    // const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'sdkclients', {
      // filters: {id: customerId}
    }, this.httpOptions).toPromise();
  }

  autoComplete(search, customers = true, suppliers = true, chains = false): Promise<any> {
    // const f = this.clearFilter(filters);
    return this.http.get(this.baseUrl + 'autocomplete?search=' + search + '&customers=' + customers + '&suppliers=' + suppliers + '&chains=' + chains, this.httpOptions).toPromise();
  }

  autoCompleteObserver(search, customers = true, suppliers = true, chains = false) {
    // const f = this.clearFilter(filters);
    return this.http.get(this.baseUrl + 'autocomplete?search=' + search + '&customers=' + customers + '&suppliers=' + suppliers + '&chains=' + chains, this.httpOptions);
  }


  /*Habit allCategories*/

  getHabitCategories(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.get(this.baseUrl + 'supplierCategories?page=' + f.page, this.httpOptions).toPromise();
  }

  editHabitCategory(category) {

    const idList = category.suppliers.map(s => s.id);
    const body = {
      supplierCategoryId: category.id,
      name: category.name,
      supplierIds: idList
    };

    return this.http.post(this.baseUrl + 'supplierCategory/edit', body, this.httpOptions).toPromise();
  }

  createHabitCategory(name) {
    const body = {
      name: name,
      supplierIds: []
    };

    return this.http.post(this.baseUrl + 'supplierCategory/create', body, this.httpOptions).toPromise();
  }

  deleteHabitCategory(id) {
    return this.http.delete(this.baseUrl + 'supplierCategory/remove?supplierCategoryId=' + id, this.httpOptions).toPromise();
  }


  /* online store */
  getCategories(supplierId) {
    return this.http.get(this.baseUrl + 'categories/products?supplierId=' + supplierId, this.httpOptions).toPromise();
  }

  addCategory(supplierId, name, parentCategoryId) {
    const body = {
      supplierId: supplierId,
      name: name,
      parentCategoryId: parentCategoryId
    };
    return this.http.post(this.baseUrl + 'categories/add', body, this.httpOptions).toPromise();
  }

  editCategory(categoryId, name) {
    const body = {
      categoryId: categoryId,
      name: name,
    };
    return this.http.post(this.baseUrl + 'categories/edit', body, this.httpOptions).toPromise();
  }

  deleteCategory(categoryId) {
    return this.http.delete(this.baseUrl + 'categories/remove?categoryId=' + categoryId, this.httpOptions).toPromise();
  }

  async productsAutocomplete(name) {
    let optionsRes = await this.http.get(this.baseUrl + 'products/autocomplete?name=' + name, this.httpOptions).toPromise();
    let status: string = optionsRes['status'];
    if (status.toLowerCase() !== 'ok') {
      return [];
    }
    return optionsRes['result'];
  }

  productsAutocompleteObserver(name) {
    return this.http.get(this.baseUrl + 'products/autocomplete?name=' + name, this.httpOptions);
  }

  /*async supplierProductsAutocomplete(supplierId, value) {
    let param = true ? 'name' : 'barcode';
    let optionsRes = await this.http.get(this.baseUrl + `customer/store/products?supplierId=${supplierId}&${param}=${value}`, this.httpOptions).toPromise();
    console.log(optionsRes);
    let status: string = optionsRes['status'];
    if (status.toLowerCase() !== 'ok') {
      return [];
    }
    return optionsRes['result'];
  }
*/
  addProduct(categoryId, productId) {
    const body = {
      categoryId: categoryId,
      productId: productId,
      // parentCategoryId: parentCategoryId
    };
    return this.http.post(this.baseUrl + 'categories/products/add', body, this.httpOptions).toPromise();
  }

  updateSupplierProduct(product) {
    return this.http.post(this.baseUrl + 'product/supplier/update', product, this.httpOptions).toPromise();
  }

  getSupplierProductDetails(supplierId, productId) {
    return this.http.get(this.baseUrl + `product/supplier?productIds=${productId}&supplierId=${supplierId}`, this.httpOptions).toPromise();
  }

  deleteProduct(productId, categoryId) {
    return this.http.delete(this.baseUrl + 'categories/products/remove?categoryId=' + categoryId + '&productId=' + productId, this.httpOptions).toPromise();
  }

  uploadProductImage(productId, image) {

    const formData = new FormData();
    formData.append('image', image);
    formData.append('productId', productId);
    return this.http.post(this.baseUrl + 'product/image', formData, this.httpOptionsNoContentType).toPromise();
  }

  uploadCategoryImage(categoryId, image) {

    const formData = new FormData();
    formData.append('image', image);
    formData.append('categoryId', categoryId);
    return this.http.post(this.baseUrl + 'categories/image', formData, this.httpOptionsNoContentType).toPromise();
  }

  uploadCampaignImage(supplierId, campaignId, image) {


    const formData = new FormData();
    formData.append('image', image);
    formData.append('supplierId', supplierId);
    if (campaignId) {
      formData.append('campaignId', campaignId);
    }
    return this.http.post(this.baseUrl + 'campaign/image', formData, this.httpOptionsNoContentType).toPromise();
  }

  deleteCampaignImage(supplierId, campaignId) {
    return this.http.post(this.baseUrl + 'campaign/image/remove', {
      supplierId: supplierId,
      campaignId: campaignId
    }, this.httpOptions).toPromise();
  }

  // habit products

  getHabitProducts(sortValue, filters) {
    const f = this.clearFilter(filters);
    return this.http.post(this.baseUrl + 'products?page=' + f.page, {
      sort: sortValue,
      filters: f
    }, this.httpOptions).toPromise();
  }

  async getSupplierPricesFile(supplierId) {
    // const f = this.clearFilter(filters);
    const res: any = await this.http.get(this.baseUrl + 'product/supplier/xls?supplierId=' + supplierId, this.fileOptions).toPromise();

    /* const res: any = await this.http.post(this.baseUrl + 'product/supplier/xls', {
       //  sort: sortValue,
       //  filters: f
     }, this.fileOptions).toPromise();*/


    console.log(res);
    // const blob = new Blob([res], {type: 'application/pdf'});
    const downloadURL = window.URL.createObjectURL(res);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = 'productPrices' + '.xls';
    link.click();

  }


  updateHabitProduct(product) {
    return this.http.post(this.baseUrl + 'product/update', product, this.httpOptions).toPromise();
  }


  addHabitProduct(product) {
    return this.http.post(this.baseUrl + 'product/create', product, this.httpOptions).toPromise();
  }

  async getOfflineOrdersExcel(sortValue, filters, fileName) {

    const f = this.clearFilter(filters);
    const res: any = await this.http.post(this.baseUrl + 'offlineOrders/xls', {
      sort: sortValue,
      filters: f
    }, this.fileOptions).toPromise();


    console.log(res);
    const downloadURL = window.URL.createObjectURL(res);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName + '.xlsx';
    link.click();

  }

  async getSupplierPaymentsExcel(sortValue, filters, fileName) {
    const f = this.clearFilter(filters);
    const res: any = await this.http.post(this.baseUrl + 'supplier/banktransfers/xls', {
      sort: sortValue,
      filters: f
    }, this.fileOptions).toPromise();


    console.log(res);
    // const blob = new Blob([res], {type: 'application/pdf'});
    const downloadURL = window.URL.createObjectURL(res);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName + '.xlsx';
    link.click();

  }

  async getSuppliersExcel(sortValue, filters, fileName) {
    const f = this.clearFilter(filters);
    const res: any = await this.http.post(this.baseUrl + 'suppliers/xls', {
      sort: sortValue,
      filters: f
    }, this.fileOptions).toPromise();


    console.log(res);
    // const blob = new Blob([res], {type: 'application/pdf'});
    const downloadURL = window.URL.createObjectURL(res);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName + '.xlsx';
    link.click();

  }

  async uploadSupplierPaymentsFile(file) {
    const formData: FormData = new FormData();
    formData.append('supplierBankTransfers', file, file.name);
    const res = await this.http.post(this.baseUrl + 'supplier/banktransfers/xls/upload', formData, this.httpOptionsNoContentType).toPromise();
    console.log(res);
    return 'OK';
  }

  async uploadSuppliersFile(file) {
    const formData: FormData = new FormData();
    formData.append('suppliers', file, file.name);
    return this.http.post(this.baseUrl + 'suppliers/xls/upload', formData, this.httpOptionsNoContentType).toPromise();

  }

  async uploadSupplierProductsFile(file, supplierId) {
    const formData: FormData = new FormData();
    formData.append('supplierPrices', file, file.name);
    formData.append('supplierId', supplierId);
    return this.http.post(this.baseUrl + 'product/supplier/xls/upload', formData, this.httpOptionsNoContentType).toPromise();

  }

  async getGoogleImageResults(query) {

    const url = `https://www.googleapis.com/customsearch/v1?key=AIzaSyC5s_oE55PGOGJauo8E0fqR8-ov-866Wzo&searchType=image&q=${query}&callback=hndlr&cx=d29190f159b271a28`;
    let res = await this.http.get(url, {
      responseType: 'text'
    }).toPromise();

    try {
      let data = res.replace('// API callback', '').replace('hndlr(', '');
      data = data.substring(0, data.length - 3);
      let json = JSON.parse(data);
      console.log(json.items);
      return json.items;
    } catch (e) {
      console.error(e);
      return [];
    }

  }


  clearFilter(filters) {
    const f = JSON.parse(JSON.stringify(filters));
    // console.log(JSON.stringify(filters));
    Object.keys(f).forEach(k => {
      if (f[k].present !== undefined) {
        f[k] = f[k].value;
      }
    });

    // console.log(f);
    return f;
  }


  async comparePrice(supplierId, productId) {
    return this.http.get(this.baseUrl + 'product/price/comparison?supplierId=' + supplierId + '&productId=' + productId, this.httpOptions).toPromise();
  }

  async getComparePriceExcel(supplierId, changesOnly = false) {
    const res: any = await this.http.get(this.baseUrl + 'product/price/comparison/xls?supplierId=' + supplierId + '&changesOnly=' + changesOnly, this.fileOptions).toPromise();
    console.log(res);
    const downloadURL = window.URL.createObjectURL(res);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = 'price compare' + '.xlsx';
    link.click();

  }

  async downloadInventoryExcel(supplierId) {
    const res: any = await this.http.get(this.baseUrl + 'product/count/xls?supplierId=' + supplierId, this.fileOptions).toPromise();
    console.log(res);
    const downloadURL = window.URL.createObjectURL(res);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = 'inventory' + '.xlsx';
    link.click();

  }

}
