<app-loader *ngIf="showLoader"></app-loader>

<div class="container">

  <div class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div class="row mt-3" style="direction: rtl">
    <div *ngIf="false" class="col p-0 m-0">
      <div class="d-flex flex-row" style="direction: rtl">
        <input (keydown.enter)="search(searchField.value)" class="form-control ml-2 w-25" type="text"
               placeholder="הזן שם ספק" #searchField>
        <button (click)="search(searchField.value)" class="btn btn-primary">חפש</button>
      </div>
    </div>

    <app-search  *ngIf="!isSupplier()" hint="הזן שם ספק" customers=false (itemSelected)="search($event)"></app-search>

  </div>


  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">
    <label class="" style="direction: rtl">{{total.count}} עסקאות </label>

    <div class="d-flex flex-row justify-content-center align-items-center">

      <div *ngIf="isAdmin()"
           class="form-group mb-0 p-0 bg-success rounded-pill text-center align-self-center"
           style="cursor: pointer">

        <label for="file" style="color: white; cursor: pointer" #label class="pr-4">העלה קובץ אקסל</label>
        <input style="visibility: hidden; width: 0;" type="file" class="text-white btn" id="file"
               (change)="handleFileInput($event.target.files[0])">
      </div>

      <span style="cursor: pointer" class="mr-2" (click)="createExcel()">
       <label style="color: #107C41; cursor: pointer">שמור כקובץ אקסל</label>
       <img src="assets/excel.png" width="35px" height="50px">
    </span>

    </div>


  </div>

  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="loadData()">

    <table *ngIf="results" class="table table-hover mt-3" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>#</th>
        <th (click)="setSortValue('created')" scope="col">
          <span *ngIf="sortValue === 'created'">↑</span>
          <span *ngIf="sortValue === '-created'">↓</span>
          תאריך
        </th>
        <th (click)="setSortValue('amount')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'amount'">↑</span>
          <span *ngIf="sortValue === '-amount'">↓</span>
          <p class="m-0">סכום ({{formatPrice(total.total)}})</p>
        </th>
        <th>סכום לפני עמלה</th>

        <th (click)="setSortValue('supplierId')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'supplierId'">↑</span>
          <span *ngIf="sortValue === '-supplierId'">↓</span>
          ספק
        </th>
        <th (click)="setSortValue('status')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'status'">↑</span>
          <span *ngIf="sortValue === '-status'">↓</span>
          סטטוס
        </th>
        <th (click)="setSortValue('transferDate')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'transferDate'">↑</span>
          <span *ngIf="sortValue === '-transferDate'">↓</span>
          תאריך העברה
        </th>
        <th (click)="setSortValue('approvedBy')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'approvedBy'">↑</span>
          <span *ngIf="sortValue === '-approvedBy'">↓</span>
          אושר על ידי
        </th>

        <th (click)="setSortValue('reference')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'reference'">↑</span>
          <span *ngIf="sortValue === '-reference'">↓</span>
          אסמכתא
        </th>
        <th scope="col">פעולות</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let result of results; let i = index" class="text-right">
        <td>{{i}}</td>
        <td (click)="toggleFilter('created', result.created,'תאריך - ' + getDateStr(result.created))"
            scope="row">{{result.created | date:'dd.MM.yyyy HH:mm'}}</td>
        <td (click)="toggleFilter('amount', result.amount,'סכום - '+result.amount)">{{formatPrice(result.amount)}}</td>
        <td>{{formatPrice(result.amountBeforeCommission)}}</td>
        <td [ngComponentOutlet]=""
            (click)="toggleFilter('supplierId', result.supplier.id,'ספק - '  + result.supplier.name)">
          {{result.supplier.name}}
        </td>

        <td>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <p (click)="toggleFilter('status', result.status, 'סטטוס - '  + formatStatus(result.status))"
               class="p-0 m-0 mb-2"> {{formatStatus(result.status)}}</p>
          </div>
        </td>

        <td
          (click)="toggleFilter('transferDate', result.transferDate ,'תאריך העברה - '  + getDateStr(result.transferDate))">
          {{getDateStr(result.transferDate)}}
        </td>
        <td>{{result.approvedBy}}</td>
        <td>{{result.reference}}</td>


        <td>
          <div  class="d-flex flex-row mt-2 align-items-center">

            <div  (click)="openClose(result,false)" *ngIf="result.status === 'open'  && isAdmin()"
                 class="badge badge-pill badge-primary" style="cursor: pointer">
              סגור
              תשלום
            </div>
            <div (click)="openClose(result ,true)" *ngIf="result.status === 'pending' && isAdmin()"
                 class="badge badge-pill badge-success" style="cursor: pointer">פתח
              תשלום
            </div>

            <mat-icon (click)="showPaymentIngredients(result)" class="text-primary ml-2 mr-2" style="cursor: pointer">
              visibility
            </mat-icon>

            <mat-icon *ngIf="result.status !== 'open' && isAdmin()" (click)="showDialog(result)" style="cursor: pointer">edit
            </mat-icon>
          </div>
        </td>

      </tr>
      </tbody>
    </table>
  </div>

</div>
