import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {MatDialog} from '@angular/material';
import {EditChainDialogComponent} from '../../components/dialog/edit-chain-dialog/edit-chain-dialog.component';
import {YesNoDialogComponent} from '../../components/dialog/yes-no-dialog/yes-no-dialog.component';
import {OkDialogComponent} from '../../components/dialog/ok-dialog/ok-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  startDate = new Date();
  endDate = new Date();

  private dateSource;
  currentDates;

  constructor(private dialog: MatDialog) {

    this.startDate = new Date();
    this.startDate.setDate(new Date().getDate() - (30 * 12));
    this.endDate = new Date();
    this.endDate.setHours(23, 59, 59);


    const initialValue = {startDate: this.startDate, endDate: this.endDate};
    this.dateSource = new BehaviorSubject<any>(initialValue);
    this.currentDates = this.dateSource.asObservable();
  }

  updateDates(startDate: Date, endDate: Date) {
    console.log('start date', startDate.toString());
    console.log('end date', endDate.toString());
    this.dateSource.next({startDate, endDate});
  }

  async yesNoDialog(message) {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      data: {
        message
      }
    });

    // return await dialogRef.afterClosed().toPromise();
    const result = await dialogRef.afterClosed().toPromise();
    return result;
    // console.log('after close', result);

  }

  async okDialog(message) {
    const dialogRef = this.dialog.open(OkDialogComponent, {
      data: {
        message
      }
    });

    // return await dialogRef.afterClosed().toPromise();
    const result = await dialogRef.afterClosed().toPromise();
    return result;

  }
}
