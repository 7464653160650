<app-loader *ngIf="showLoader"></app-loader>

<div class="container h-100">
  <div class="row p-5  h-100 d-flex justify-content-center align-items-center">

    <div class="col-4 p-5 bg-light my-card p-3">
      <div class="row text-center d-flex flex-column justify-content-center">
        <h3 class="text-dark text-center">Habit Login</h3>
      </div>

      <div *ngIf="false" class="row mt-4">
        <input #email class="form-control" placeholder="Email">
      </div>

      <div *ngIf="false" class="row mt-4">
        <input type="password" #password class="form-control" placeholder="Password">
      </div>


      <div class="row mt-4" *ngIf="!codeSent">
        <input (keydown.enter)="loginWithPhone(phone.value)" #phone class="form-control m-0 d-block text-center"
               placeholder="מספר טלפון">

        <button (click)="loginWithPhone(phone.value); " class="btn btn-primary btn-block mt-2">שלח קוד
        </button>

        <button *ngIf="isLocal()" (click)="phone.value = '0524667179'" class="btn btn-primary btn-block mt-2">FILL
        </button>

        <div *ngIf="isTestServer" class="col-12 text-center mt-2">
          <h5 class="text-danger d-block text-center">Test Server</h5>
        </div>

      </div>

      <div class="row mt-4 text-center" *ngIf="codeSent">
        <input (keydown.enter)="phoneConfirmation(code.value)" #code class="form-control m-0 text-center"
               placeholder="אנא הזן את הקוד שקיבלת">

        <button (click)="phoneConfirmation(code.value)" class="btn btn-primary btn-block mt-2">התחבר
        </button>

        <div class="col-12">
          <label class="text-center text-primary mt-3" *ngIf="codeSent" (click)="codeSent=false">הזן טלפון אחר</label>
        </div>
      </div>


    </div>
  </div>

  <div (click)="serverClicked()" id="serverDiv"></div>
</div>
