<div class="text-center d-flex flex-column align-items-center justify-content-center">


  <input type="file" (change)="fileChangeEvent($event)"/>

  <image-cropper
    style="max-height: 300px; max-width: 300px;"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="false"
    [aspectRatio]="4 / 3"
    format="png"
    [imageURL]="imageUrl"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"></image-cropper>

  <img [src]="croppedImage" style="max-width: 200px; max-height: 200px"/>

  <div *ngIf="croppedImage" class="mt-3">
    <button (click)="save()" class="btn btn-success">שמור</button>
  </div>
  <!--

    <div class="file-upload">
      <span class="text">upload</span>
      <input id="custom-input" type="file" (change)="fileChangeListener($event)">
    </div>
    <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
    <br>
    <span class="result rounded" *ngIf="data.image">
      <img [src]="data.image" [width]="cropperSettings.croppedWidth" [height]="cropperSettings.croppedHeight">
  </span>

    <div *ngIf="data.image" class="mt-3">
      <button (click)="uploadFile()" class="btn btn-success">שמור</button>
    </div>

  -->


</div>
