<button class="dialogCloseButton" (click)="close()">X</button>


<div *ngIf="isNew" class="bg-success d-block text-center p-2">
  <h4 class="text-white">הקמת רשת</h4>
</div>

<div *ngIf="chain" class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <div class="d-flex flex-column justify-content-center p-3" style="direction: rtl">


    <!-- details -->

    <div class="row">

      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">פרטי רשת</li>
          <li class="list-group-item">
            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">שם רשת</label>
              <input [disabled]="!isNew" class="form-control input-width-sm" [(ngModel)]="chain.name">
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">שם עברי</label>
              <input class="form-control input-width-sm" [(ngModel)]="chain.hebrewName">
            </div>
          </li>

        </ul>
      </div>

    </div>

    <!--  Connected Users -->
    <div class="row mt-3">

      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">מנהלי רשת מורשים</li>
          <li class="list-group-item" *ngFor="let item of chain.linkedCustomers  || []; let i = index">
            <div class="d-flex flex-row justify-content-between">
              <p class="m-0 p-0">{{item.name.first}} {{item.name.last}}</p>
              <mat-icon (click)="removeLinkedClient(i,item)" class="text-danger">delete_outline</mat-icon>
            </div>
          </li>

          <li class="list-group-item">
            <div class="d-flex flex-row align-items-center">
              <input (keydown.enter)="addLinkedClient()" [(ngModel)]="newPhone" type="text"
                     class="form-control ml-2 w-50" placeholder="טלפון משתמש חדש">
              <button (click)="addLinkedClient()" class="btn btn-sm btn-success">הוסף
              </button>
            </div>
          </li>

        </ul>
      </div>
    </div>


    <!--  Sdk Clients -->
    <div class="row mt-3" *ngIf="isNew ||  (chain && chain.sdkClients && chain.sdkClients.length>0)">

      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">חיבורי קופה</li>
          <li class="list-group-item" *ngFor="let item of chain.sdkClients  || []; let i = index">
            <div class="d-flex flex-row justify-content-between">
              <p class="m-0 p-0">{{item.name}}</p>
              <mat-icon *ngIf="isNew" (click)="chain.sdkClients.splice(i,1)" class="text-danger">delete_outline
              </mat-icon>
            </div>
          </li>

          <li class="list-group-item" *ngIf="isNew">
            <div class="d-flex flex-row align-items-center">
              <select [(ngModel)]="selectedSdkClientValue" class="ml-2">
                <option *ngFor="let o of selectedSdkClientOptions; let i = index;" [value]="i">{{(o.name)}}</option>
              </select>
              <button (click)="addSdkClient()" class="btn btn-sm btn-success">הוסף
              </button>
            </div>
          </li>

        </ul>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <button (click)="save()" class="btn btn-success btn-block">שמירה</button>
      </div>
    </div>

  </div>

</div>
