<button class="dialogCloseButton" (click)="close()">X</button>


<div *ngIf="isNewSupplier" class="bg-success d-block text-center p-2">
  <h4 class="text-white">הקמת ספק</h4>
</div>

<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <div class="d-flex flex-column justify-content-center p-3" style="direction: rtl">


    <!-- Business details -->

    <div class="row">

      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">פרטי עסק</li>
          <li class="list-group-item">
            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">שם עסק</label>
              <input class="form-control input-width-sm" [(ngModel)]="supplier.name">
            </div>

            <div *ngIf="chainOptions" class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">שם רשת</label>
              <select [(ngModel)]="selectedChainValue" class="ml-2">
                <option *ngFor="let o of chainOptions" [value]="o.id">{{getChainOptionValue(o)}}</option>
              </select>

            </div>

            <!-- <div class="d-flex flex-row align-items-center mt-2">
               <label class="m-0 p-0 ml-3 text-width-sm">סניף ללא רשת</label>
               <mat-slide-toggle [(ngModel)]="notBelongToChain" color="primary"></mat-slide-toggle>
             </div>-->

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">מספר עוסק</label>
              <input class="form-control input-width-sm" [(ngModel)]="supplier.taxId">
            </div>

            <div *ngIf="selectedChainValue || selectedChainValue !== ''"
                 class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">סניף</label>
              <input type="number" class="form-control input-width-sm" [(ngModel)]="supplier.branch">
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">רחוב</label>
              <input class="form-control input-width-sm" [(ngModel)]="supplier.street">
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">מספר</label>
              <input class="form-control input-width-sm" [(ngModel)]="supplier.houseNumber">
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">מיקוד</label>
              <input class="form-control input-width-sm" [(ngModel)]="supplier.zipcode">
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">עיר</label>
              <input class="form-control input-width-sm" [(ngModel)]="supplier.city">
            </div>
          </li>

          <li class="list-group-item">
            <div class="d-flex flex-row align-items-center">
              <label class="m-0 ml-2 p-0 text-width-m">% חלוקת רווחים</label>
              <input type="number" class="form-control w-25" [(ngModel)]="supplier.orderDistributionPercentage">
            </div>
          </li>

          <li class="list-group-item">
            <div class="d-flex flex-row align-items-center">
              <label class="m-0 ml-2 p-0">מרחק חלוקת רווחים (מטר) </label>
              <input type="number" class="form-control w-25" [(ngModel)]="supplier.activityRadius">

              <mat-slider *ngIf="false" min="1000" max="100000" color="primary" thumbLabel [displayWith]="getLabel"
                          [(ngModel)]="supplier.activityRadius"></mat-slider>
            </div>
          </li>

        </ul>
      </div>

    </div>

    <!--  Connected Users -->
    <div class="row mt-3">

      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">משתמשים מורשים</li>
          <li class="list-group-item" *ngFor="let item of supplier.linkedCustomers  || []; let i = index">
            <div class="d-flex flex-row justify-content-between">
              <p class="m-0 p-0">{{item.name.first}} {{item.name.last}}</p>
              <mat-icon (click)="supplier.linkedCustomers. splice(i,1); addLinkedClient()" class="text-danger">
                delete_outline
              </mat-icon>
            </div>
          </li>

          <li class="list-group-item">
            <div class="d-flex flex-row align-items-center">
              <input [(ngModel)]="newConnectedClientPhone" type="text" class="form-control ml-2 w-50"
                     placeholder="טלפון משתמש חדש">
              <button (click)="addLinkedClient()" class="btn btn-sm btn-success">הוסף
              </button>
            </div>
          </li>

        </ul>
      </div>
    </div>

    <!--  Categories  -->
    <div *ngIf="false" class="row mt-3">
      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">קטגוריות</li>
          <li class="list-group-item" *ngFor="let item of supplier.categories  || []; let i = index">
            <div class="d-flex flex-row justify-content-between">
              <p class="m-0 p-0">{{item}}</p>
              <mat-icon (click)="supplier.categories.splice(i,1)" class="text-danger">delete_outline</mat-icon>
            </div>
          </li>

          <li class="list-group-item">
            <div class="d-flex flex-row align-items-center">
              <input #catName type="text" class="form-control ml-2 w-50" placeholder="שם קטגוריה חדשה">
              <button (click)="addCategory(catName)" class="btn btn-sm btn-success">הוסף
              </button>
            </div>
          </li>

        </ul>
      </div>

    </div>


    <!--  Store Payment -->
    <div class="row mt-3">

      <div class="col-12">


        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">
            <div class="d-flex justify-content-between">
              <p class="p-0 m-0"> {{getOnOffText('תשלום בקופה', supplier.payAtRegisterEnabled)}}</p>
              <mat-slide-toggle [(ngModel)]="supplier.payAtRegisterEnabled" color="primary"></mat-slide-toggle>
            </div>
          </li>


          <li class="list-group-item" *ngFor="let item of supplier.vouchers  || []; let i = index">
            <div class="d-flex flex-row justify-content-between">
              <p class="m-0 p-0">{{item.voucherType}} - {{item.profit}}% </p>
              <mat-icon (click)="supplier.vouchers.splice(i,1)" class="text-danger">delete_outline</mat-icon>
            </div>
          </li>


          <!--Add new voucher-->
          <li class="list-group-item">
            <div class="d-flex flex-row align-items-center">
              <select [(ngModel)]="selectedLevel" class="ml-2">
                <option *ngFor="let o of voucherOptions" [value]="o.value">{{o.title}}</option>
              </select>
              <input #percent type="number" min=0 max=100 class="form-control ml-2 w-50" placeholder="אחוז">
              <button (click)="addVoucher(percent)" class="btn btn-sm btn-success">הוסף</button>
            </div>
          </li>


          <li class="list-group-item">
            <div class="d-flex flex-row flex-wrap">
              <label class="ml-2">השוואת מחירים באפליקציה</label>
              <mat-slide-toggle [(ngModel)]="supplier.habitGoPricesEnabled" color="primary"></mat-slide-toggle>
            </div>
          </li>

        </ul>

      </div>

    </div>


    <!-- Online -->
    <div class="row mt-3">

      <div class="col-12">


        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">
            <div class="d-flex justify-content-between">
              <p class="p-0 m-0">{{getOnOffText('קנייה אונליין', supplier.buyOnline)}}</p>
              <mat-slide-toggle [(ngModel)]="supplier.buyOnline" color="primary"></mat-slide-toggle>
            </div>
          </li>


          <li class="list-group-item">
            <div class="d-flex flex-row flex-wrap">
              <label class="ml-2 text-width-sm">איסוף עצמי</label>
              <mat-slide-toggle [(ngModel)]="supplier.selfPickupEnabled" color="primary"></mat-slide-toggle>
            </div>
          </li>

          <li class="list-group-item">
            <div class="d-flex flex-row flex-wrap">
              <label class="ml-2 text-width-sm">הביט Go</label>
              <mat-slide-toggle [(ngModel)]="supplier.habitGoEnabled" color="primary"></mat-slide-toggle>
            </div>
          </li>

          <li class="list-group-item">
            <div class="d-flex flex-row flex-wrap">
              <label class="ml-2 text-width-sm">משלוחים</label>
              <mat-slide-toggle [(ngModel)]="supplier.deliveryEnabled" color="primary"></mat-slide-toggle>
            </div>
          </li>

          <li class="list-group-item">
            <div class="d-flex flex-row align-items-center">
              <label class="m-0 ml-2 p-0 text-width-m">עלות משלוח</label>
              <input type="number" class="form-control w-25" [(ngModel)]="supplier.deliveryPrice">
            </div>
          </li>

        </ul>

      </div>

    </div>

    <!-- Bank Details  -->
    <div class="row mt-3">

      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">פרטי חשבון בנק</li>
          <li class="list-group-item">

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">שם המוטב</label>
              <input class="form-control input-width-sm" [(ngModel)]="supplier.bankAccountDetails.accountBeneficial">
            </div>


            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">בנק</label>
              <!--
                            <input class="form-control input-width-sm" [(ngModel)]="supplier.bankAccountDetails.branch">
              -->
              <select [(ngModel)]="selectedBankValue" class="ml-2">
                <option *ngFor="let o of bankOptions" [value]="o.value">{{o.title}}</option>
              </select>
            </div>


            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">סניף</label>
              <input type="number" class="form-control input-width-sm" [(ngModel)]="supplier.bankAccountDetails.branch">
            </div>

            <div class="d-flex flex-row align-items-center mt-2">
              <label class="m-0 p-0 ml-3 text-width-sm">מספר חשבון</label>
              <input type="number" class="form-control input-width-sm"
                     [(ngModel)]="supplier.bankAccountDetails.accountNumber">
            </div>

          </li>

        </ul>
      </div>

    </div>


    <!-- Campaigns   -->
    <div class="row mt-3">

      <div class="col-12">
        <ul class="list-group m-0 p-0">
          <li class="list-group-item active text-white text-center" style="direction: rtl">קמפיינים</li>
          <li class="list-group-item">

            <div class="d-flex flex-column align-items-center mt-2">
              <p class="m-0 p-0">תמונת קמפיין דיפולטיבית</p>
              <img (click)="openImageDialogForCampaign()" height="150" width="150" [src]="supplier.defaultCampaignImage || '/assets/no-image.png'"
                   class="img rounded mt-3">
            </div>

          </li>

        </ul>
      </div>

    </div>


    <!--<div *ngIf="error!==''" class="alert alert-danger" role="alert">
      {{error}}
    </div>-->


    <div class="row mt-3">
      <div class="col-12">
        <button (click)="save()" class="btn btn-success btn-block">שמירה</button>
      </div>
    </div>


  </div>
</div>
