import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-supplier-transfer-details',
  templateUrl: './supplier-transfer-details.component.html',
  styleUrls: ['./supplier-transfer-details.component.scss']
})
export class SupplierTransferDetailsComponent extends BaseDialogComponent<SupplierTransferDetailsComponent> {

  transfer;
  result;
  orders;
  onlineOrders;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SupplierTransferDetailsComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.transfer = data.transfer;
    console.log(data);

    this.loadData();

  }

  async loadData() {
    const res = await this.httpService.updateSupplierPaymentTransferDetails(this.transfer.id);
    console.log(res);
    this.result = res['result'];
    this.orders = this.result.orders;
    this.onlineOrders = this.result.onlineOrders;
  }

  ngOnInit() {
  }

  getTitle() {
    return (this.onlineOrders.length + this.orders.length) + ' ' + 'עסקאות';
  }

  formatPrice(p) {
    if (p === undefined) {
      return '';
    }
    if (p === 0) {
      return '0₪';
    }
    return p.toFixed(2) + '₪';
  }


  async downloadExcel() {
    console.log('test');
    this.showLoader = true;
    await this.httpService.getOfflineOrdersExcel({}, {'ids': this.orders.map(o => o.id)}, 'supplier payments');
    this.showLoader = false;
    // console.log(res);
  }
}
