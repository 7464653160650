import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-campaign-reachout-list-dialog',
  templateUrl: './campaign-reachout-list-dialog.component.html',
  styleUrls: ['./campaign-reachout-list-dialog.component.scss']
})
export class CampaignReachoutListDialogComponent extends BaseDialogComponent<CampaignReachoutListDialogComponent> {

  distributionCampaignItem;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CampaignReachoutListDialogComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.distributionCampaignItem = data.distributionCampaignItem;
    console.log(this.distributionCampaignItem);

  }

  async ngOnInit() {

  }

  getNotUserTitle() {
    return 'משתמשים שלא מימשו - ' + this.distributionCampaignItem.reachout?.unrealized.length;
  }
}
