import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpService} from '../../../service/http/http.service';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {DataService} from '../../../service/data/data.service';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-leverage-list',
  templateUrl: './leverage-list.component.html',
  styleUrls: ['./leverage-list.component.scss']
})
export class LeverageListComponent extends BaseListComponent {

  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dataService: DataService, dialog: MatDialog) {
    super(httpService, activatedRoute, dataService, dialog);
  }


  async loadData() {
    super.loadData();
    const res = await this.httpService.getLeverages(this.sortValue, this.filters);
    this.checkResults(res);
    // console.log(res);
    this.results = this.results || [];
    this.results = res['result'].list;
    this.total = res['result'].total;
    this.afterLoadData(res['result'].list.length);
  }

  getLeverageTypeText(result) {
    if (!!result.userToUserTransferId) {
      return 'מינוף העברה';
    }
    return 'מינוף עסקה';
  }

  getRelatedTransactionUrl(result) {
    if (!!result.userToUserTransferId) {
      return '/user-to-user';
    }
    return '/offline-orders';
  }

  getRelatedTransactionParams(result) {
    if (!!result.userToUserTransferId) {
      return {filterKey: 'orderId', filterValue: result.userToUserTransferId, filterTitle: 'מזהה העברה - ' + result.userToUserTransferId};
    }
    return {filterKey: 'orderId', filterValue: result.orderId, filterTitle: 'מזהה עסקה - ' + result.orderId};
  }


  getClassName = (): String => 'LeverageListComponent';


}
