import {Component, OnInit} from '@angular/core';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material';
import {DataService} from '../../../service/data/data.service';
import {UserProfileDialogComponent} from '../../dialog/user-profile-dialog/user-profile-dialog.component';
import {InviteesListDialogComponent} from '../../dialog/invitees-list-dialog/invitees-list-dialog.component';

@Component({
  selector: 'app-invites',
  templateUrl: './invites.component.html',
  styleUrls: ['./invites.component.scss']
})
export class InvitesComponent extends BaseListComponent {

  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dialog: MatDialog, dataService: DataService) {
    super(httpService, activatedRoute, dataService, dialog);
  }

  async loadData() {
    super.loadData();
    const res = await this.httpService.getInvites(this.sortValue, this.filters);
    // console.log(res);
    this.checkResults(res);
    this.results = this.results || [];
    this.results = this.results.concat(res['result'].list);
    this.total = res['result'].total;
    // this.openBalance = res['result'].openBalance;
    // this.afterLoadData(res['result'].list.length);
    this.afterLoadData(this.results.length);
  }


  getClassName = (): String => 'InvitesComponent';

  async showInvitieesDialog(result) {
    const dialogRef = this.dialog.open(InviteesListDialogComponent, {
      // width: '90vw',
      // height: '80vh',
      panelClass: 'dialog-style',
      data: {
        inviteItem: result
      }
    });

    var res = await dialogRef.afterClosed().toPromise();

  }

  localSearch(value) {
    this.toggleFilter('customerName', value, 'שם - ' + value);
  }
}
