import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {BaseService} from '../base/base.service';
import {LogItem} from '../../model/LogItem';

@Injectable({
  providedIn: 'root'
})
export class LogService extends BaseService<LogItem> {

  constructor(afs: AngularFirestore) {
    super(afs, 'logs');
  }


  getItems(limit = 100) {
    return this.afs.collection<LogItem>(this.path, ref =>
      ref
        .orderBy('time', 'desc')
        .limit(limit)
    ).valueChanges();
  }

  getLogsForUser(user) {
    return this.afs.collection<LogItem>(this.path, ref =>
      ref
        .where('user', '==', user)
        .orderBy('time', 'desc')
        .limit(100)
    ).valueChanges();

  }

  getType(): LogItem {
    return new LogItem();
  }
}
