<div class="row mt-4 pl-5 pr-5" style="direction: rtl">

  <div class="form-inline text-center">

    <div class="d-flex flex-column justify-content-center  align-items-center ml-5">
      <div class="d-flex flex-row">
        <label class="p-0 m-0 text-dark">מתאריך</label>
        <label class="pl-1 pr-1 m-0 " (click)="pickerFrom.open()"
               style="color: #495DF5">{{startDate | date:'dd.MM.yyyy'}}</label>
        <input [max]="endDate" style="width: 0" class="p-0" [(ngModel)]="startDate" [matDatepicker]="pickerFrom">
        <mat-datepicker-toggle [for]="pickerFrom">
          <mat-icon matDatepickerToggleIcon style="color: #495DF5">keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker (closed)="onStartDateSelected(pickerFrom._selected)"
                        (change)="onStartDateSelected(pickerFrom._selected)"
                        (select)="onStartDateSelected(pickerFrom._selected)"
                        #pickerFrom></mat-datepicker>
      </div>
    </div>

    <div class="d-flex flex-column justify-content-center  align-items-center">
      <div class="d-flex flex-row">
        <label class="p-0 m-0 text-dark">עד תאריך</label>
        <label class="pl-1 pr-1 m-0" style="color: #495DF5"
               (click)="pickerTo.open()">{{endDate | date:'dd.MM.yyyy'}}</label>
        <input style="width: 0" [min]="startDate" [max]="maxDateEnd" class="p-0" [(ngModel)]="endDate" [matDatepicker]="pickerTo">
        <mat-datepicker-toggle [for]="pickerTo">
          <mat-icon matDatepickerToggleIcon style="color: #495DF5">keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker (closed)="onEndDateSelected(pickerTo._selected)"
                        (change)="onEndDateSelected(pickerTo._selected)"
                        (select)="onEndDateSelected(pickerTo._selected)"
                        #pickerTo></mat-datepicker>
      </div>
    </div>

    <button *ngIf="false" (click)="onDateSelected()" class="btn my-btn mr-2">בחר</button>

  </div>

</div>
