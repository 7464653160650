import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../service/data/data.service';
import {MatDialog} from '@angular/material';
import {CreateCampaignDialogComponent} from '../../dialog/create-campaign-dialog/create-campaign-dialog.component';
import {ImageDialogComponent} from '../../views/image-dialog/image-dialog.component';
import {EditSupplierDialogComponent} from '../../dialog/edit-supplier-dialog/edit-supplier-dialog.component';

@Component({
  selector: 'app-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['./campaigns-list.component.scss']
})
export class CampaignsListComponent extends BaseListComponent implements AfterViewInit {


  // supplierId = '5dc7d3366649ae0041240293';

  @Input()
  supplierId = undefined;

  @Input()
  supplierName;

  canLoad = false;

  math = Math;

  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dataService: DataService, dialog: MatDialog) {
    super(httpService, activatedRoute, dataService, dialog);

    /*activatedRoute.queryParams.subscribe(params => {
      const supplierId = params['supplierId'];
      if (!supplierId) {
        return;
      }

      console.log(supplierId);
      this.supplierId = supplierId;
      this.loadData();
    });*/

    // wait until parent gets the supplierId parameter
    setTimeout(() => {
      console.log('timeout');
      this.loadData();
    }, 200);

  }


  async loadData() {
    if (this.supplierId) {
      this.toggleFilter('supplierId', this.supplierId, 'ספק - ' + this.supplierName);
    }


    super.loadData();
    const res = await this.httpService.getCampaigns(this.sortValue, this.filters);
    console.log(res);
    this.checkResults(res);
    this.results = this.results || [];

    // check if has duplicates
    if (this.results && this.results.length > 0 && res['result'].list.length > 0) {
      if (this.results[this.results.length - 1].id == res['result'].list[res['result'].list.length - 1].id) {
        console.log('duplicate call was found - ignoring');
        return;
      }
    }

    this.results = this.results.concat(res['result'].list);
    this.total = res['result'].total;
    // this.openBalance = res['result'].openBalance;
    // this.afterLoadData(res['result'].list.length);
    this.afterLoadData(this.results.length);
  }

  /*async loadData() {

    // const supplierId = localStorage.getItem('supplierId');
    // console.log('supplierId', supplierId);

    // super.loadData();
    const res = await this.httpService.getCampaigns(this.supplierId);
    console.log(res);
    this.checkResults(res);
    this.results = this.results || [];
    this.results = [];
    this.results = this.results.concat(res['result']);    // console.log(res);
    this.results = this.results.filter(i => i != null);
    this.total = res['result'].total;
    // this.afterLoadData(res['result'].list.length);
    this.showLoader = false;

  }*/

  getClassName(): String {
    return 'CampaignsListComponent';
  }


  async showCreateCampaignDialog(isOffline, campaign) {
    if (!campaign &&  !this.total.canCreateNewCampaign) {
      this.dataService.okDialog('לא ניתן ליצור קמפיין נוסף עבור ספק זה');
      return;
    }
    let chain = localStorage.getItem('chain');
    console.log(chain);
    const dialogRef = this.dialog.open(CreateCampaignDialogComponent, {
      width: '90vw',
      maxWidth: '700px',
      panelClass: 'dialog-style-no-height',
      data: {
        supplierId: this.supplierId,
        isOffline,
        campaign
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (!result) {
      return;
    }

    this.resetData();
    this.loadData();
  }

  async delete(result) {
    const res = await this.dataService.yesNoDialog('האם להסיר את הקמפיין?');
    if (!res) {
      return;
    }

    await this.httpService.deleteCampaign(result.id, result.supplierId);
    this.loadData();
  }

  getTimeToFinish(result) {
    const now = new Date().getTime();
    const endDate = Date.parse(result.endDate);
    const timeInMiliseconds = endDate - now;

    let h, m, s;
    h = Math.floor(timeInMiliseconds / 1000 / 60 / 60);
    m = Math.floor((timeInMiliseconds / 1000 / 60 / 60 - h) * 60);
    s = Math.floor(((timeInMiliseconds / 1000 / 60 / 60 - h) * 60 - m) * 60);

    return `${s}:${m}:${h}`;
  }

  getBarValue(result) {
    if (!this.canLoad) {
      return 0;
    }
    const now = new Date().getTime();
    const endDate = Date.parse(result.endDate);
    const startDate = Date.parse(result.startDate);

    console.log(now, endDate, startDate);
    return ((endDate - now) / (endDate - startDate)) * 100;
  }

  ngAfterViewInit(): void {

    this.canLoad = true;
  }

  async openImageDialogForCampaign(supplierId, campaign) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: campaign.imageUrl
      }
    });

    const file = await dialogRef.afterClosed().toPromise();
    if (!file) {
      return;
    }

    this.showLoader = true;
    const res = await this.httpService.uploadCampaignImage(supplierId, campaign.id, file);
    console.log(res);
    this.showLoader = false;


    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }

    const p = res['result'];
  }

  async uploadCampaignImage(file, supplierId, campaign) {
    if (!file) {
      return;
    }

    this.showLoader = true;
    const res = await this.httpService.uploadCampaignImage(supplierId, campaign.id, file);
    console.log(res);
    this.showLoader = false;


    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }

    this.resetData();
    this.loadData();
  }

  async showSupplierDialog(supplierId) {

    const tmp = await this.httpService.getSupplier(supplierId);
    const supplier = tmp['result']['list'][0];

    const dialogRef = this.dialog.open(EditSupplierDialogComponent, {
      width: '90vw',
      maxWidth: '600px',
      height: '80vh',
      panelClass: 'dialog-style',
      data: {
        supplier
      }
    });

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('after close', result);
      if (!result) {
        return;
      }

      if (supplier != null) {
        Object.assign(supplier, result);
      }

      this.loadData();
    });
  }

  async deleteImage(campaign) {
    await this.httpService.deleteCampaignImage(campaign.supplier.id, campaign.id);
    this.resetData();
    this.loadData();
  }
}
