import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatAutocompleteTrigger, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-edit-create-product',
  templateUrl: './edit-create-product.component.html',
  styleUrls: ['./edit-create-product.component.scss']
})
export class EditCreateProductComponent extends BaseDialogComponent<EditCreateProductComponent> {

  product;
  categoryId;

  productDetails = {
    price: '',
    profit: '',
    quantity: 10,
    futurePaymentsAmount: ''
  };

  productOptions;
  selectedOptionId; // this is the selected product id


  // autocomplete
  @ViewChild(MatAutocompleteTrigger, null) autocompleteTrigger: MatAutocompleteTrigger;


  options = [];
  selectedItem;
  searchValue: any;
  supplierId;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditCreateProductComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.product = data.product;
    this.categoryId = data.categoryId;
    this.supplierId = data.supplierId;
    this.isNew = !data.product;
    if (!data.product) {
      this.product = {
        name: ''
      };
    } else {
      this.product = JSON.parse(JSON.stringify(data.product));
      console.log('edit product', this.product);

      this.selectedItem = this.product;
      this.searchValue = this.selectedItem.name;
      this.loadProductDetails();

    }

    console.log('parentId', this.categoryId);
  }

  async loadProductDetails() {
    let res = await this.httpService.getSupplierProductDetails(this.supplierId, this.product.id);
    console.log(res);

    this.productDetails = res['result'];
  }

  ngOnInit() {
  }

  async onChange(value) {
    this.productOptions = await this.httpService.productsAutocomplete(value);

  }

  onEnterClicked(text) {
    this.autocompleteTrigger.closePanel();

    /*this.itemSelected.emit({
      isFreeText: true,
      text
    });*/

    this.searchValue = '';
  }

  onItemSelected(event, item: any) {
    this.selectedItem = item;

    const filterType = item.isSupplier ? 'supplierId' : 'customerId';
    const title = 'שם ' + (item.isSupplier ? 'ספק' : 'לקוח') + ' - ' + item.name;

    item.filterType = filterType;
    item.title = title;

    //  this.itemSelected.emit(item);
  }

  async search() {
    if (this.isNew) {
      this.productOptions = await this.httpService.productsAutocomplete(this.product.name);
      console.log(this.productOptions);
      if (this.productOptions.length === 0) {
        this.dataService.okDialog('לא נמצאו מוצרים תואמים לשם המוצר שהוזן, אנא נסה שם אחר');
        return;
      }
    }
  }


  async save() {

    let res;
    if (this.isNew) {

      res = await this.httpService.addProduct(this.categoryId, this.selectedItem.id);
      console.log(res);
      const status: string = res['status'];
      if (status.toLowerCase() !== 'ok') {
        this.dataService.okDialog(status);
        return;
      }

      // now we need to update the product details
      let data = {
        productId: this.selectedItem.id,
        supplierId: this.supplierId,
        price: this.productDetails.price,
        profit: this.productDetails.profit,
        quantity: this.productDetails.quantity,
        futurePaymentsAmount: this.productDetails.futurePaymentsAmount
      };

      res = await this.httpService.updateSupplierProduct(data);
      console.log(res);
      this.close(res['result']);

    } else {
      // res = await this.httpService.editCategory(this.category.id, this.category.name);

      let data = {
        productId: this.selectedItem.id,
        supplierId: this.supplierId,
        price: this.productDetails.price,
        profit: this.productDetails.profit,
        quantity: this.productDetails.quantity,
        futurePaymentsAmount: this.productDetails.futurePaymentsAmount
      };

      res = await this.httpService.updateSupplierProduct(data);
      console.log(res);
      this.close(res['result']);

    }


  }


}
