<div class="bg-success d-block text-center p-2">
  <h4 class="text-white p-0 m-0">רשימת מימושים</h4>
</div>


<mat-tab-group class="p-3" mat-stretch-tabs selectedIndex="0" dynamicHeight="false" >
  <mat-tab label="משתמשים שמימשו">
    <table class="table table-hover mt-2" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>לקוח</th>
      </tr>
      </thead>


      <tbody class="text-right">
      <tr *ngFor="let result of distributionCampaignItem.reachout?.realized">
        <td>{{getFullName(result.name)}}</td>
      </tr>
      </tbody>
    </table>
  </mat-tab>

  <mat-tab label="{{getNotUserTitle()}}">
    <table class="table table-hover mt-2" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>לקוח</th>
      </tr>
      </thead>


      <tbody class="text-right">
      <tr *ngFor="let result of distributionCampaignItem.reachout?.unrealized">
        <td>{{getFullName(result.name)}}</td>
      </tr>
      </tbody>
    </table>
  </mat-tab>


</mat-tab-group>


<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


</div>
