import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-refund-dialog',
  templateUrl: './refund-dialog.component.html',
  styleUrls: ['./refund-dialog.component.scss']
})
export class RefundDialogComponent extends BaseDialogComponent<RefundDialogComponent> {

  item;
  fullRefund = true;
  isLoading = false;
  isOnline = false;
  refundAmount;

  offlineOrderId;
  onlineOrderId;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RefundDialogComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.item = data.item;
    this.refundAmount = this.item.amount;
    this.isOnline = data.isOnline || false;

    this.offlineOrderId = data.offlineOrderId;
    this.onlineOrderId = data.onlineOrderId;
    console.log(data);

  }

  async makeRefund() {
    if (this.isLoading) {
      return;
    }

    if (!this.fullRefund && this.refundAmount > this.item.amount) {
      this.showSnackBar('לא ניתן לבצע זיכוי מעל לסכום העיסקה');
      close();
      return;
    }

    const finalRefundAmount = this.fullRefund ? this.item.amount : this.refundAmount;
    this.isLoading = true;
    const res = await this.httpService.makeRefund(this.offlineOrderId, this.onlineOrderId, finalRefundAmount);
    this.isLoading = false;

    const status: string = res['status'];
    if (status.toLowerCase() === 'ok') {
      this.item.amount = this.item.amount - finalRefundAmount;
      this.dialogRef.close({});
    } else {
      this.showSnackBar('אירעה שגיאה');
    }

    console.log(res);
  }
}
