import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';

@Component({
  selector: 'app-ok-dialog',
  templateUrl: './ok-dialog.component.html',
  styleUrls: ['./ok-dialog.component.scss']
})
export class OkDialogComponent implements OnInit {

  message;
  isError;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<OkDialogComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar) {

    this.message = data.message;
    this.isError = data.isError || false;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
