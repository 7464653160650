import {Component, Input, OnInit} from '@angular/core';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../service/data/data.service';
import {MatDialog} from '@angular/material';
import {CreateDistributionCampaignDialogComponent} from '../../dialog/create-distribution-campaign-dialog/create-distribution-campaign-dialog.component';
import {CampaignReachoutListDialogComponent} from '../../dialog/campaign-reachout-list-dialog/campaign-reachout-list-dialog.component';
import {ImageDialogComponent} from '../../views/image-dialog/image-dialog.component';

@Component({
  selector: 'app-distribution-campaign-list',
  templateUrl: './distribution-campaign-list.component.html',
  styleUrls: ['./distribution-campaign-list.component.scss']
})
export class DistributionCampaignListComponent extends BaseListComponent {

  @Input()
  supplierId;

  @Input()
  supplierName;

  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dataService: DataService, dialog: MatDialog) {
    super(httpService, activatedRoute, dataService, dialog);

    // wait until parent gets the supplierId parameter
    setTimeout(() => {
      console.log('timeout');
      this.loadData();
    }, 500);

  }

  ngOnInit() {
  }


  async loadData() {

    if (this.supplierId) {
      this.toggleFilter('supplierId', this.supplierId, 'ספק - ' + this.supplierName);
    }


    super.loadData();
    const res = await this.httpService.getDistributionCampaigns(this.sortValue, this.filters);
    // console.log(res);
    this.checkResults(res);
    this.results = this.results || [];

    // check if has duplicates
    if (this.results && this.results.length > 0 && res['result'].list.length > 0) {
      if (this.results[this.results.length - 1].id == res['result'].list[res['result'].list.length - 1].id) {
        console.log('duplicate call was found - ignoring');
        return;
      }
    }

    this.results = this.results.concat(res['result'].list);
    this.total = res['result'].total;
    // this.openBalance = res['result'].openBalance;
    // this.afterLoadData(res['result'].list.length);
    this.afterLoadData(this.results.length);

  }

  /* async loadData() {

     console.log('DistributionCampaignListComponent');
     // const supplierId = localStorage.getItem('supplierId');
     // console.log('supplierId', supplierId);

     // super.loadData();
     const res = await this.httpService.getDistributionCampaigns(this.supplierId);
     console.log(res);
     this.checkResults(res);
     this.results = [];
     this.results = [];
     this.results = this.results.concat(res['result']);    // console.log(res);
     this.results = this.results.filter(i => i != null);
     this.total = res['result'].total;
     // this.afterLoadData(res['result'].list.length);
     this.showLoader = false;

   }*/


  getClassName(): String {
    return 'DistributionCampaignListComponent';
  }

  async createCampaign(campaign) {
    if (!campaign && !this.total.canCreateNewCampaign) {
      this.dataService.okDialog('לא ניתן ליצור קמפיין נוסף עבור ספק זה');
      return;
    }
    const dialogRef = this.dialog.open(CreateDistributionCampaignDialogComponent, {
      width: '90vw',
      maxWidth: '700px',
      panelClass: 'dialog-style-no-height',
      data: {
        supplierId: this.supplierId,
        campaign: campaign
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (!result) {
      return;
    }

    this.resetData();
    this.loadData();
  }

  async delete(result) {
    const res = await this.dataService.yesNoDialog('האם למחוק קמפיין זה?');
    if (res === true) {
      const deleteRes = await this.httpService.deleteCampaign(result.id, result.supplierId);
      const status: string = deleteRes['status'];
      if (status.toLowerCase() !== 'ok') {
        this.dataService.okDialog(status);
        return;
      }
      this.loadData();
    }

  }

  async showReachoutDialog(result) {
    const dialogRef = this.dialog.open(CampaignReachoutListDialogComponent, {
      width: '90vw',
      maxWidth: '700px',
      panelClass: 'dialog-style-no-height',
      data: {
        distributionCampaignItem: result,
      }
    });

    //const result = await dialogRef.afterClosed().toPromise();
  }

  /*async openImageDialogForCampaign(supplierId, campaign) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: campaign.imageUrl
      }
    });

    const file = await dialogRef.afterClosed().toPromise();
    if (!file) {
      return;
    }

    this.showLoader = true;
    const res = await this.httpService.uploadCampaignImage(supplierId, campaign.id, file);
    console.log(res);
    this.showLoader = false;


    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }

    const p = res['result'];
  }
*/
  async uploadCampaignImage(file, supplierId, campaign) {
    if (!file) {
      return;
    }

    this.showLoader = true;
    const res = await this.httpService.uploadCampaignImage(supplierId, campaign.id, file);
    console.log(res);
    this.showLoader = false;


    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }

    this.resetData();
    this.loadData();
  }

  async deleteImage(campaign) {
    await this.httpService.deleteCampaignImage(campaign.supplier.id, campaign.id);
    this.resetData();
    this.loadData();
  }
}
