<app-loader *ngIf="showLoader"></app-loader>

<div class="container">

  <div class="row mt-3" style="direction: rtl">
    <div *ngIf="false" class="col p-0 m-0">
    <div class="d-flex flex-row" style="direction: rtl">
        <input (keydown.enter)="search(searchField.value)" class="form-control ml-2 w-25" type="text"
               placeholder="הזן שם ספק" #searchField>
        <button (click)="search(searchField.value)" class="btn btn-primary">חפש</button>
      </div>
    </div>

    <app-search hint="הזן שם ספק" customers=false (itemSelected)="search($event)"></app-search>

  </div>

  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">

    <div class="d-flex flex-row justify-content-center align-items-center">

      <div *ngIf="isAdmin()"
           class="form-group mb-0 p-0 bg-success rounded-pill text-center align-self-center"
           style="cursor: pointer">

        <label for="file" style="color: white; cursor: pointer" #label class="pr-4">העלה קובץ אקסל</label>
        <input style="visibility: hidden; width: 0;" type="file" class="text-white btn" id="file"
               (change)="handleFileInput($event.target.files[0])">
        <button *ngIf="false" (click)="uploadExcelFile()" class="btn btn-success rounded-pill text-white">שלח</button>
      </div>

      <span *ngIf="isAdmin()" style="cursor: pointer" class="mr-2" (click)="createExcel()">
       <label style="color: #107C41; cursor: pointer">שמור כקובץ אקסל</label>
       <img src="assets/excel.png" width="35px" height="50px">
    </span>

    </div>


  </div>


  <div class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">
    <span style="direction: rtl">
          <label class="ml-3">{{total.count}} ספקים</label>
          <label class="ml-3">{{formatPrice(total.total)}}</label>
      <button (click)="showSupplierDialog(null)" class="btn btn-success">ספק חדש</button>
    </span>

  </div>


  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="loadData()">

    <table *ngIf="results" class="table table-hover mt-2" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>#</th>
        <th scope="col">תמונה</th>
        <th scope="col">ברקוד</th>
        <th (click)="setSortValue('name')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'name'">↑</span>
          <span *ngIf="sortValue === '-name'">↓</span>
          שם
        </th>
        <th scope="col">מספר עוסק</th>
        <th scope="col">רשת</th>
        <th scope="col">כתובת</th>
        <th *ngIf="isAdmin()" scope="col">פעולות</th>
      </tr>
      </thead>


      <tbody class="text-right">
      <tr *ngFor="let result of results; let i = index">
        <td>{{i}}</td>
        <td>
          <!-- <input hidden type="file" #uploader (change)="uploadFile($event,result)">
           <img (click)="uploader.click()" height="60" width="60" [src]="result.image || '/assets/logo.png'"
                class="img rounded">-->

          <img (click)="openImageDialog(result)" height="60" width="60" [src]="result.image || '/assets/logo.png'"
               class="img rounded">
        </td>
        <td>
          <img height="60" width="60" [src]="result.barcode || '/assets/logo.png'" class="img">
        </td>
        <td
          (click)="toggleFilter('name', result.name,'שם - ' + result.name)">{{result.name}}
        </td>
        <td>{{result.taxId}}</td>
        <td
          (click)="toggleFilter('chainId', result.chain.id,'רשת - ' + getChainName(result))">{{getChainName(result)}}</td>
        <td>{{result.street}} {{result.houseNumber}}, {{result.city}}</td>
        <td *ngIf="isAdmin()">
          <div class="d-flex flex-row mt-2">
            <div (click)="showSupplierDialog(result)" style="cursor: pointer" class="d-flex flex-column align-items-center">
              <mat-icon class="ml-3 mr-3"  >edit</mat-icon>
              <p class="p-0 m-0 small">עריכה</p>
            </div>
            <a [routerLink]="['/edit-online-store', result.id]">
              <div class="d-flex flex-column align-items-center">
                <mat-icon style="cursor: pointer">storefront</mat-icon>
                <p class="p-0 m-0 small">אונליין</p>
              </div>
            </a>

            <button [routerLink]="['/campaigns']"
                    [queryParams]="{supplierId: result.id, supplierName:result.name}"
                    class="btn btn-sm btn-primary mr-2">ניהול קמפיינים
            </button>

          </div>
        </td>

      </tr>
      </tbody>
    </table>
  </div>

</div>
