import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EditCreateCategoryComponent} from '../../dialog/edit-create-category/edit-create-category.component';
import {MatDialog} from '@angular/material';
import {DataService} from '../../../service/data/data.service';
import {HttpService} from '../../../service/http/http.service';
import {EditCreateProductComponent} from '../../dialog/edit-create-product/edit-create-product.component';
import {ImageDialogComponent} from '../image-dialog/image-dialog.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  @Input()
  category;

  @Input()
  isFirstLevel = false;

  @Input()
  supplierId;

  @Output() categoryDeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCategoryEdit: EventEmitter<any> = new EventEmitter<any>();


  isLeaf;
  showLoader = false;


  constructor(public dialog: MatDialog, private  dataService: DataService, private  httpService: HttpService) {

  }

  ngOnInit() {
    this.category.products = this.category.products || [];
    this.category.children = this.category.children || [];
    this.isLeaf = !this.hasSubCategories;
  }


  isEmpty() {
    return ((this.category.products || []).length === 0 && (this.category.children || []).length === 0);
  }

  numOfProducts() {
    return (this.category.products || []).length;
  }

  numOfSubCategories() {
    return (this.category.children || []).length;
  }

  hasProducts = () => this.numOfProducts() > 0;
  hasSubCategories = () => this.numOfSubCategories() > 0;

  getDesc() {
    if (this.hasProducts()) {
      return ' (' + this.numOfProducts() + ' מוצרים' + ')';
    }

    if (this.isEmpty()) {
      return '(' + 'ריק' + ')';
    }

    return ' (' + this.numOfSubCategories() + ' תתי קטגוריות' + ')';
  }

  async editCreateCategory(category, parentCategoryId) {
    const dialogRef = this.dialog.open(EditCreateCategoryComponent, {
      panelClass: 'dialog-style-no-padding',
      width: '400px',
      maxWidth: '400px',
      // height: '80vh',
      data: {
        category: category,
        supplierId: this.supplierId,
        parentCategoryId: parentCategoryId
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    console.log('after close', result);
    if (!result) {
      return;
    }

    if (category == null) {
      this.category.children.push(result);
    } else {
      if (this.onCategoryEdit) {
        this.onCategoryEdit.emit(result);
      }
    }
  }

  async deleteCategory(category) {
    const a = await this.dataService.yesNoDialog('האם ברצונך למחוק את הקטגוריה?');
    if (!a) {
      return;
    }
    const res = await this.httpService.deleteCategory(category.id);
    console.log(res);
    if (this.categoryDeleted) {
      this.categoryDeleted.emit(category);
    }

  }

  onCategoryDeleted(category) {
    console.log('on category deleted', category);
    this.category.children.splice(this.category.children.indexOf(category), 1);
  }

  onCategoryEdited(category) {
    console.log('onCategoryEdit', category);
    let index = this.category.children.findIndex(c => c.id === category.id);
    console.log(index);
    this.category.children[index].name = category.name;
  }

  async editCreateProduct(product) {
    console.log(product);
    const dialogRef = this.dialog.open(EditCreateProductComponent, {
      panelClass: 'dialog-style-no-padding',
      width: '400px',
      maxWidth: '400px',
      // height: '80vh',
      data: {
        product: product,
        categoryId: this.category.id,
        supplierId: this.supplierId
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    console.log('after close', result);
    if (!result) {
      return;
    }

    if (product == null) {
      this.category.products.push(result);
    } else {
      /*if (this.onCategoryEdit) {
        this.onCategoryEdit.emit(result);
      }*/
    }
  }

  async deleteProduct(product) {
    const a = await this.dataService.yesNoDialog('האם ברצונך למחוק את המוצר?');
    if (!a) {
      return;
    }
    const res = await this.httpService.deleteProduct(product.id, this.category.id);
    console.log(res);

    let index = this.category.products.findIndex(p => p.id === product.id);
    console.log(index);
    this.category.products.splice(index, 1);
    /* if (this.categoryDeleted) {
       this.categoryDeleted.emit(category);
     }*/
  }

  async openImageDialog(product) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: product.images.thumbUrl
      }
      // width: '0vw',
      // maxWidth: '600px',
      // height: '80vh',
      // panelClass: 'dialog-style',
    });

    const file = await dialogRef.afterClosed().toPromise();
    if (!file) {
      return;
    }

    console.log(file);
    // this.uploadFile(file, supplier);

    this.showLoader = true;
    const res = await this.httpService.uploadProductImage(product.id, file);
    console.log(res);
    this.showLoader = false;


    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }

    const p = res['result'];
    product.images.thumbUrl = p.images.thumbUrl;
  }

  async openImageDialogForCategory(category) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: category.imageUrl
      }
    });

    const file = await dialogRef.afterClosed().toPromise();
    if (!file) {
      return;
    }

    this.showLoader = true;
    const res = await this.httpService.uploadCategoryImage(category.id, file);
    console.log(res);
    this.showLoader = false;


    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }

    const p = res['result'];
    // category.imageUrl = p.images.thumbUrl;
  }

  updateProduct(product: any) {

  }

  formatPrice(p) {
    if (p === undefined) {
      return '';
    }
    if (p === 0) {
      return '0₪';
    }
    return p.toFixed(2) + '₪';
  }
}
