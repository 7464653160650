export class Utils {
  static formatPhone(number): string {
    if (number.startsWith('+972')) {
      return number;
    }

    const preText = '+972';

    if (number.startsWith('0')) {
      return preText + number.substring(1);
    }

    return preText + number;
  }


  static hasError(res) {
    const status = res['status'] as string;
    if (status.toLowerCase() !== 'ok') {
      return status;
    }
    return false;
  }
}

