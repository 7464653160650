import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-supplier-view',
  templateUrl: './supplier-view.component.html',
  styleUrls: ['./supplier-view.component.scss']
})
export class SupplierViewComponent implements OnInit {

  @Input()
  supplier;

  constructor() { }

  ngOnInit() {
  }

}
