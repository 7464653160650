<app-loader *ngIf="showLoader"></app-loader>

<div class="container">

  <div class="row mt-3 text-right" style="direction: rtl">
    <div class="col p-0 m-0" style="direction: rtl">
      <div *ngIf="false" class="d-flex flex-row" style="direction: rtl">
        <input (keydown.enter)="search(searchField.value)" class="form-control ml-2 w-25" type="text"
               placeholder="חפש לקוח לפי שם או טלפון" #searchField>
        <button (click)="search(searchField.value)" class="btn btn-primary">חפש</button>
      </div>

      <app-search hint="חפש לקוח לפי שם או טלפון" suppliers=false (itemSelected)="search($event)"></app-search>

    </div>
  </div>


  <div class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>


  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">
    <div class="d-flex flex-column">
      <label class="ml-3">{{total.count}} עסקאות</label>
      <label class="ml-3">{{formatPrice(total.amount)}}</label>
    </div>


    <div class="d-flex flex-column">
      <mat-radio-group [(ngModel)]="isPaidOffFullyValue" aria-label="הצג את" class="ml-3">
        <mat-radio-button (change)="onIsPaidOffFullyChanged($event.value)"
                          color="primary"
                          value="0">כל העסקאות
        </mat-radio-button>
        <mat-radio-button (change)="onIsPaidOffFullyChanged($event.value)"
                          color="primary" class="mr-2 ml-2"
                          value="1">העסקאות שנפרעו
        </mat-radio-button>
        <mat-radio-button (change)="onIsPaidOffFullyChanged($event.value)"
                          color="primary" value="2">העסקאות שלא נפרעו
        </mat-radio-button>
      </mat-radio-group>

      <mat-radio-group [(ngModel)]="paymentFailureValue" aria-label="הצג את" class="ml-3">
        <mat-radio-button (change)="onIsPaymentFailureChanged($event.value)"
                          color="primary"
                          value="0">הצג את כל התשלומים
        </mat-radio-button>
        <mat-radio-button (change)="onIsPaymentFailureChanged($event.value)"
                          color="primary" class="mr-2 ml-2"
                          value="1">הצג תשלומים שנכשלו
        </mat-radio-button>
        <mat-radio-button (change)="onIsPaymentFailureChanged($event.value)"
                          color="primary" value="2">הצג תשלומים שלא נכשלו
        </mat-radio-button>
      </mat-radio-group>
    </div>


  </div>

  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="loadData()">
    <table *ngIf="results" class="table table-hover mt-2" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>#</th>
        <th scope="col">תמונה</th>
        <th (click)="setSortValue('name')" class="text-right">
          <span *ngIf="sortValue === 'name'">↑</span>
          <span *ngIf="sortValue === '-name'">↓</span>
          שם המבצע
        </th>

        <th (click)="setSortValue('date')" class="text-right">
          <span *ngIf="sortValue === 'date'">↑</span>
          <span *ngIf="sortValue === '-date'">↓</span>
          תאריך ביצוע עסקה
        </th>

        <th scope="col">תאריך פירעון</th>
        <th (click)="setSortValue('amount')" class="text-right">
          <span *ngIf="sortValue === 'amount'">↑</span>
          <span *ngIf="sortValue === '-amount'">↓</span>
          סכום
        </th>
        <th scope="col">מונף על ידי</th>
        <th (click)="setSortValue('isPaidOffFully')" class="text-right">
          <span *ngIf="sortValue === 'isPaidOffFully'">↑</span>
          <span *ngIf="sortValue === '-isPaidOffFully'">↓</span>
          סטטוס פירעון
        </th>
        <th (click)="setSortValue('overdue')">
          <div class="d-flex flex-row justify-content-center">
            <span *ngIf="sortValue === 'overdue'">↑</span>
            <span *ngIf="sortValue === '-overdue'">↓</span>
            <p class="p-0 m-0">כשלונות בחיוב ({{total.overDue || 0}})</p>
          </div>
        </th>
        <th scope="col">פעולות</th>
      </tr>
      </thead>


      <tbody class="text-right">
      <tr *ngFor="let result of results; let i = index">
        <td>{{i}}</td>

        <td><img height="60" width="60" [src]="result.customer.imageUrl || '/assets/logo.png'" class="img rounded"></td>
        <td
          (click)="toggleFilter('name', result.customer.name.first,'שם - ' + getFullName(result.customer.name))">{{getFullName(result.customer.name)}}
        </td>
        <td>{{getDateStr(result.created)}}</td>
        <td>{{getDateStr(result.date) }}</td>
        <td>{{formatPrice(result.amount)}}</td>
        <td>{{getFullName(result.leveragedByCustomer)}}</td>
        <td>{{result.isPaidOffFully ? 'הושלם' : 'לא הושלם'}}</td>
        <td>{{result.chargeFailedDates.length == 0 ? '' : result.chargeFailedDates.length}}</td>
        <!--<td
          [ngClass]="{'text-danger': result.balance <= 0, 'text-success':result.balance > 0 }">{{formatPrice(result.balance)}}
        </td>
        <td
          [ngClass]="{'text-danger': result.totalPayments > 0 }">{{formatPrice(getUserPayments(result))}}
        </td>
        <td
          [ngClass]="{'text-success': result.totalReturns > 0 }">{{formatPrice(getUserReturns(result))}}
        </td>-->
        <div class="d-flex flex-row mt-2">
          <mat-icon *ngIf="false" (click)="showUserProfileDialog(result.id)" style="cursor: pointer">edit</mat-icon>
        </div>
      </tr>
      </tbody>
    </table>

  </div>
</div>
