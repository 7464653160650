<mat-sidenav-container class="sidenav-container" style="background-color: white">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true" position="end"
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="!(isHandset$ | async)">
    <div style="height: 190px" class="f-center p-2 bg-light">

      <div class="text-center">
        <img
          [src]=userImage
          class="img-fluid mx-auto d-block rounded-circle mb-2 mt-2" style="width: 80px; height: 80px;
              background: no-repeat 100% 100%; object-fit: cover">
        <p class="p-0 m-0">{{name}}</p>
        <p class="p-0 m-0">{{getRoleText()}}</p>
        <p style="cursor: pointer" (click)="logout()" class="p-0 m-0 small text-primary">(התנתק)</p>


      </div>

    </div>

    <div class="m2-5">

      <div (click)="setServer(false)" style="position: fixed; top: 0; left: 0;  padding: 20px; "
           class="bg-danger text-white" *ngIf="isTestServer">
        TEST MODE
      </div>

      <div (click)="setServer(true)" style="position: fixed; top: 0; left: 0;  padding: 20px; "
           class="bg-success text-white"
           *ngIf="!isTestServer && isLocal()">
        LIVE MODE
      </div>


      <div *ngFor="let menuItem of menuItems">
        <div *ngIf="menuItem.shouldShow()" [ngClass]="getClassFor(menuItem.routerLink)"
             (click)="menuItem.onClick?menuItem.onClick():{}"

             [routerLink]="menuItem.onClick == null ? [menuItem.routerLink] : [] ">{{menuItem.title}}
        </div>

      </div>

      <div *ngIf="false" (click)="logout()" [ngClass]="'menuItem'" routerLink="/">התנתק</div>

    </div>
    <mat-nav-list *ngIf="false">


      <mat-list-item (click)="closeDrawer();" class="rtl" [ngClass]="getClassFor('/supplier-payments')"
                     routerLink="/supplier-payments">
        <a *ngIf="role==='admin'" class="rtl">פירוט
          תשלומים</a>

      </mat-list-item>

      <mat-list-item (click)="closeDrawer();" class="rtl" [ngClass]="getClassFor('/suppliers')" routerLink="/suppliers">
        <a class="rtl">רשימת ספקים</a>
      </mat-list-item>

      <mat-list-item (click)="closeDrawer();" class="rtl" [ngClass]="getClassFor('/users-locations')"
                     routerLink="/users-locations">
        <a class="rtl">מיקומי לקוחות</a>
      </mat-list-item>

      <mat-list-item class="rtl" (click)="logout();">
        <a class="rtl">התנתק</a>
      </mat-list-item>


    </mat-nav-list>


  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="dark" *ngIf="isHandset$ | async">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon class="text-white" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="text-white">Habit</span>
      <span class="example-spacer"></span>
      <h3 *ngIf="false" class="text-white" style="cursor: pointer;">התנתק</h3>
      <mat-icon *ngIf="false" class="example-icon text-white">favorite</mat-icon>
      <mat-icon *ngIf="false" class="example-icon text-white">delete</mat-icon>
    </mat-toolbar>
    <!-- Add Content Here -->

    <app-date-picker *ngIf="shouldShowDatePicker"></app-date-picker>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
