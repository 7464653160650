import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-create-distribution-campaign-dialog',
  templateUrl: './create-distribution-campaign-dialog.component.html',
  styleUrls: ['./create-distribution-campaign-dialog.component.scss']
})
export class CreateDistributionCampaignDialogComponent extends BaseDialogComponent<CreateDistributionCampaignDialogComponent> {

  supplierId;
  startDate: Date;
  avgDistributionSize;
  maxDistributionPerUser;
  isActive = true;
  frequencyMinutes;
  budget;

  campaign;
  campaignId;
  isUpdate = false;
  numOfUsersInArea;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CreateDistributionCampaignDialogComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.supplierId = data.supplierId;
    this.startDate = new Date();

    if (data.campaign) {
      // update
      this.campaignId = data.campaign.id;
      this.isUpdate = true;
      // this.startDate = data.campaign.startDate;
      this.avgDistributionSize = data.campaign.avgDistributionSize;
      this.isActive = data.campaign.isActive;
      this.frequencyMinutes = data.campaign.frequencyMinutes;
      this.budget = data.campaign.budget;
      this.maxDistributionPerUser = data.campaign.maxDistributionPerUser;
      this.startDate = new Date(Date.parse(data.campaign.startDate));
    }

    this.loadData();


  }

  ngOnInit() {
  }

  async loadData() {
    const res = await this.httpService.getCategories(this.supplierId);
    const res2 = await this.httpService.getNumOfUsersInArea(this.supplierId);
    console.log(res2);
    this.numOfUsersInArea = res2['result']['numOfUsers'];
  }

  async save() {

    /*if ( this.startDate.getTime() < Date.now()) {
      this.dataService.okDialog('תאריך תחילת הקמפיין כבר עבר');
      return;
    }*/

    if (!this.budget || this.budget.trim() == '') {
      this.dataService.okDialog('יש להגדיר תקציב לקמפיין');
      return;
    }
    const campaign = {
      supplierId: this.supplierId,
      startDate: new Date(this.startDate).getTime(),
      avgDistributionSize: this.avgDistributionSize,
      isActive: this.isActive,
      frequencyMinutes: this.frequencyMinutes,
      maxDistributionPerUser: this.maxDistributionPerUser,
      budget: this.budget
    };


    this.showLoader = true;
    let res;
    if (this.isUpdate) {
      campaign['distributionCampaignId'] = this.campaignId;
      res = await this.httpService.editDistributionCampaign(campaign);
    } else {
      res = await this.httpService.crateDistributionCampaign(campaign);
    }

    this.showLoader = false;

    const status: string = res['status'];
    console.log(status);
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }
    console.log(res);

    this.dialogRef.close(true);

  }

  getNumOfUsersText() {
    return 'קמפיין זה ישפיע על כ-' + this.numOfUsersInArea + ' משתמשים';
  }
}
