<button class="dialogCloseButtonWideDialog" (click)="close()">X</button>


<div *ngIf="user && details" class="d-flex flex-column justify-content-center" style="direction: rtl">

  <mat-tab-group>
    <mat-tab label="הגדרות משתמש">
      <div *ngIf="false ;else userInfo"></div>
    </mat-tab>
    <mat-tab label="קניות בקופה">
      <app-offline-orders [customerId]="userId"></app-offline-orders>
    </mat-tab>
    <mat-tab label="קניות אונליין">
      <app-online-orders [customerId]="userId"></app-online-orders>
    </mat-tab>
    <mat-tab label="העברות בין משתמשים">
      <app-user-to-user [customerId]="userId"></app-user-to-user>
    </mat-tab>
    <mat-tab label="העברות בנקאיות">
      <app-bank-transfers [customerId]="userId"></app-bank-transfers>
    </mat-tab>
    <mat-tab label="מינופים">
      <app-leverage-list [customerId]="userId"></app-leverage-list>
    </mat-tab>
    <mat-tab label="פעולות בחשבון">
      <app-transactions [customerId]="userId"></app-transactions>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #userInfo>
  <div class="d-flex flex-column justify-content-center p-3" style="direction: rtl">


    <h4 class="align-self-center text-center">{{user.name.first}} {{user.name.last}}</h4>
    <h6 class="align-self-center text-center">{{user.balance.toFixed(2)}} ₪</h6>


    <div class="row mt-3">
      <hr class="bg-white">
      <div class="col text-right">
        <h5>פרטים אישיים</h5>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col">
        <input class="form-control" placeholder="שם פרטי" [(ngModel)]="user.name.first">
      </div>

      <div class="col">
        <input class="form-control" placeholder="שם משפחה" [(ngModel)]="user.name.last">
      </div>
    </div>


    <div class="row mt-3">
      <div class="col">
        <input class="form-control" placeholder="אימייל" [(ngModel)]="user.email">
      </div>

      <div class="col">
        <input class="form-control" placeholder="טלפון" [(ngModel)]="user.phone">
      </div>
    </div>

    <div class="row mt-3" style="direction: rtl">
      <div class="col">
        <div class="d-flex flex-column align-items-start">
          <label>בונוס בהוספת חבר</label>
          <input class="form-control" placeholder="בונוס בהוספת חבר" [(ngModel)]="user.invite.bonusAmount">
        </div>
      </div>

      <div class="col">
        <div class="d-flex flex-column align-items-start">
          <label>סכום שימוש כתנאי לקבלת בונוס</label>
          <input class="form-control" placeholder="סכום שימוש כתנאי לקבלת בונוס" [(ngModel)]="user.invite.bonusThreshold">
        </div>
      </div>
    </div>


    <div class="row mt-3">
      <hr class="bg-white">
      <div class="col text-right">
        <h5>הרשאות</h5>
      </div>
    </div>


    <div class="d-flex flex-row align-items-center justify-content-start mt-1">
      <div class="ml-3">
        <input class="form-check-input" type="checkbox" value="" id="c1"
               [(ngModel)]="user.permissions.payInStore">
        <label class="form-check-label mr-3" for="c1">
          תשלום בחנות
        </label>
      </div>

      <div class="ml-3">
        <input class="form-check-input" type="checkbox" value="" id="c2" [(ngModel)]="user.permissions.payOnline">
        <label class="form-check-label mr-3" for="c2">
          קניות אונליין
        </label>
      </div>

      <div class="ml-3">
        <input class="form-check-input" type="checkbox" value="" id="c3" [(ngModel)]="user.permissions.transfers">
        <label class="form-check-label mr-3" for="c3">
          העברות
        </label>
      </div>

      <div class="ml-3">
        <input class="form-check-input" type="checkbox" value="" id="c4" [(ngModel)]="user.permissions.leverages">
        <label class="form-check-label mr-3" for="c4">
          מינופים
        </label>
      </div>

      <div class="ml-3">
        <input class="form-check-input" type="checkbox" value="" id="c5" [(ngModel)]="user.permissions.futurePayments">
        <label class="form-check-label mr-3" for="c5">
          מימון המונים
        </label>
      </div>
      <div class="ml-3">
        <input class="form-check-input" type="checkbox" value="" id="c5" [(ngModel)]="user.permissions.habitGo">
        <label class="form-check-label mr-3" for="c5">
          הביט GO
        </label>
      </div>

    </div>

    <div class="d-flex flex-row justify-content-center mt-4">
      <button (click)="save()" class="btn btn-primary  mr-2 ml-2 w-25">שמור</button>
      <button *ngIf="false" (click)="close()" class="btn btn-danger mr-2 ml-2 w-25">חזור</button>
    </div>

    <div class="row mt-3">

      <div class="col">
        <h5 class="text-center text-danger" style="direction: rtl">חיובים עתידיים
          - {{formatPrice(user.payments.total)}} </h5>
        <mat-accordion>
          <mat-expansion-panel *ngFor="let item of user.payments.list || []" (opened)="onOpened(item, false)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{item.date | date:'dd.MM.yyyy' }}
              </mat-panel-title>
              <mat-panel-description>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <p class="text-danger p-0 m-0">{{formatPrice(item.amount)}}</p>
                  <div *ngIf="item.chargeFailedDates.length>0" class="badge badge-pill badge-danger">החיוב נכשל</div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <h5 class="text-center">פירוט חיובים חודשי</h5>

            <ul *ngIf="detailedListFuturePayments.length > 0; else loader" class="list-group p-0 m-0"
                style="direction: rtl">
              <li class="list-group-item p-0 mt-2" *ngFor="let detailedItem of detailedListFuturePayments || []">
                <!-- chargeFailed -->
                <div class="d-block bg-danger rounded-top text-center text-white mb-2 p-2 border-bottom-0"
                     *ngIf="detailedItem.chargeFailedDates.length>0">
                  החיוב
                  נכשל
                </div>
                <div class="d-flex flex-row p-2">
                  <img height="50" width="50" [src]="detailedItem.data.imageUrl " class="img rounded ml-3">
                  <div class="d-flex flex-column flex-fill justify-content-between">
                    <!-- Created-->
                    <div class="d-flex flex-row justify-content-between">
                      <p class="m-0 p-0">תאריך רכישה</p>
                      <p class="m-0 p-0">{{formatDate(detailedItem.created)}}</p>
                    </div>


                    <!--Amount-->
                    <div class="d-flex flex-row justify-content-between">
                      <p class="m-0 p-0">סכום</p>
                      <p class="m-0 p-0">{{formatPrice(detailedItem.amount)}}</p>
                    </div>

                    <!--Store-->
                    <div class="d-flex flex-row justify-content-between">
                      <p class="m-0 p-0">בית עסק</p>
                      <p class="m-0 p-0">{{detailedItem.data.imageDescription}}</p>
                    </div>


                  </div>
                </div>
              </li>
            </ul>

            <ng-template #loader>
              <div class="d-flex justify-content-center align-items-center p-4 text-center">
                <mat-spinner diameter="50" class="text-center"></mat-spinner>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>


      <div class="col">
        <h5 class="text-center text-success" style="direction: rtl">זיכויים עתידיים
          - {{formatPrice(user.returns.total)}} </h5>
        <mat-accordion>
          <mat-expansion-panel *ngFor="let item of user.returns.list || []" (opened)="onOpened(item, true)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{item.date | date:'dd.MM.yyyy' }}
              </mat-panel-title>
              <mat-panel-description>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <p class="text-danger p-0 m-0">{{formatPrice(item.amount)}}</p>

                </div>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <h5 class="text-center">פירוט זיכויים חודשי</h5>

            <ul *ngIf="detailedListIncomingPayments.length > 0; else loader" class="list-group p-0 m-0"
                style="direction: rtl">
              <li class="list-group-item" *ngFor="let detailedItem of detailedListIncomingPayments || []">

                <div class="d-flex flex-row">
                  <img height="50" width="50" [src]="detailedItem.data.imageUrl " class="img rounded ml-3">
                  <div class="d-flex flex-column flex-fill justify-content-between">
                    <!-- Created-->
                    <div class="d-flex flex-row justify-content-between">
                      <p class="m-0 p-0">תאריך רכישה</p>
                      <p class="m-0 p-0">{{formatDate(detailedItem.created)}}</p>
                    </div>


                    <!--Amount-->
                    <div class="d-flex flex-row justify-content-between">
                      <p class="m-0 p-0">סכום</p>
                      <p class="m-0 p-0">{{formatPrice(detailedItem.amount)}}</p>
                    </div>

                    <!--Store-->
                    <div class="d-flex flex-row justify-content-between">
                      <p class="m-0 p-0">בית עסק</p>
                      <p class="m-0 p-0">{{detailedItem.data.imageDescription}}</p>
                    </div>

                  </div>
                </div>
              </li>
            </ul>

            <ng-template #loader>
              <div class="d-flex justify-content-center align-items-center p-4 text-center">
                <mat-spinner diameter="50" class="text-center"></mat-spinner>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!--
      <div class="col">
        <ul class="list-group">
          <li class="list-group-item active text-white bg-success text-center" style="direction: rtl">זיכויים עתידיים
            - {{user.returns.total | number: '1.2-2'}}</li>
          <li class="list-group-item" *ngFor="let item of user.returns.list || []">
            <div class="d-flex flex-row justify-content-between">
              <p class="m-0 p-0">₪{{item.amount | number: '1.2-2'}}</p>
              <p class="m-0 p-0">{{item.date | date:'dd.MM.yyyy' }}</p>
            </div>

        </ul>
      </div>-->

    </div>


  </div>
</ng-template>

