<div class="d-flex flex-row p-2">
  <img (click)="openImageDialog()" height="50" width="50" [src]="product.images.thumbUrl"
       class="img rounded ml-3">
  <div class="d-flex flex-column flex-fill justify-content-between">
    <!-- Created-->
    <div class="d-flex flex-row justify-content-between">
      <p class="m-0 p-0">{{product.name}}</p>
    </div>


    <!--Amount-->
    <div class="d-flex flex-row justify-content-between">
      <p class="m-0 p-0">{{product.barcode}}</p>
    </div>

    <!--Actions-->
    <div class="d-flex flex-row justify-content-end">
      <button (click)="deleteProduct()" class="btn btn-sm btn-danger ml-2">מחק</button>
      <button (click)="editCreateProduct()" class="btn btn-sm btn-primary ml-2">ערוך</button>
      <button (click)="comparePrice(product)" class="btn btn-sm btn-primary">השוואת מחירים</button>
    </div>

    <!--Compare Prices-->
    <div *ngIf="showComparePrices" class="d-flex flex-row justify-content-end mt-3">
      <div *ngFor="let price of otherPrices">
        <div class="d-flex flex-column align-items-center justify-content-center card ml-2 mr-2 p-3">
          <img height="50" width="50" [src]="price.supplier?.images?.thumbUrl  || '/assets/no-image.png'"
               class="img rounded">
          <p class="m-0 p-0 text-center small">{{price.supplier.name}}</p>
          <p class="m-0 p-0 text-center">{{formatPrice(price.price)}}</p>
          <p class="m-0 p-0 text-center small">{{price.distance}} מטר</p>
        </div>
      </div>
    </div>


  </div>
</div>
