import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpService} from '../../../service/http/http.service';
import {MatAutocompleteTrigger} from '@angular/material';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  selectedPos = -1;
  lastSearchTime;


  @Input()
  customers = true;

  @Input()
  suppliers = true;

  @Input()
  chains = false;

  @Input()
  products = false;

  lastEmitTime = 0;


  @Input()
  hint = '';

  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatAutocompleteTrigger, null) autocompleteTrigger: MatAutocompleteTrigger;


  options = [];
  selectedItem;
  searchValue: any;

  listener;

  constructor(private httpService: HttpService) {
  }

  ngOnInit() {
  }

  async onChange(value) {
    this.lastSearchTime = new Date().getTime();
    if (this.listener) {
      this.listener.unsubscribe();
    }
    // console.log('new search with time ' + this.lastSearchTime);
    this.selectedPos = -1;
    let res;
    if (this.products) {
      // res = await this.httpService.productsAutocomplete(value);
      this.listener = this.httpService.productsAutocompleteObserver(value).subscribe(r => {
        let status: string = r['status'];
        if (status.toLowerCase() !== 'ok') {
          return [];
        }
        this.options = r['result'];
      });
      // console.log(res);

    } else {


      // res = await this.httpService.autoComplete(value, this.customers, this.suppliers, this.chains);
      this.listener = this.httpService.autoCompleteObserver(value, this.customers, this.suppliers, this.chains).subscribe(res => {
        console.log(res);

        if (this.chains) {
          this.options = (res['result']['chains'] || []).map(i => {
            i.isChain = true;
            return i;
          });
          return;
        }

        const customers = (res['result']['customers'] || []).map(i => {
          i.isSupplier = false;
          return i;
        });

        const suppliers = (res['result']['suppliers'] || []).map(i => {
          i.isSupplier = true;
          return i;
        });


        this.options = [...suppliers, ...customers];
      });

      // console.log(res);

      // console.log('search ended, with time ' + this.lastSearchTime);

    }
  }

  onItemSelected(event, item: any) {
    // console.log(event);
    // console.log(item);
    this.selectedItem = item;

    console.log(item);
    let filterType = item.isSupplier ? 'supplierId' : 'customerId';
    let title = 'שם ' + (item.isSupplier ? 'ספק' : 'לקוח') + ' - ' + item.name;


    item.filterType = filterType;
    item.title = title;

    if (this.products) {
      item.filterType = 'productId';
      item.title = 'מזהה מוצר';
    }

    const timeSinceLastEmit = new Date().getTime() - this.lastEmitTime;

    if (timeSinceLastEmit < 300) {
      console.log('not emitting because last was ' + timeSinceLastEmit + ' ago');
      return;
    }

    console.log('emitting 1');

    this.itemSelected.emit(item);
    this.lastEmitTime = new Date().getTime();
  }

  onEnterClicked(text) {
    if (this.selectedPos >= 0) {
      // need to use options
      if (this.options && this.options.length > this.selectedPos) {
        console.log('using item in pos ', this.selectedPos);
        this.onItemSelected(this.options[this.selectedPos], this.options[this.selectedPos]);
        return;
      }
    }

    console.log('using search value', text);
    this.autocompleteTrigger.closePanel();

    console.log('emitting 2');
    this.itemSelected.emit({
      isFreeText: true,
      text
    });

    this.searchValue = '';
  }

  test(text) {
    console.log(text);
  }

  getTypeText(option) {
    if (option.isChain) {
      return '(רשת)';
    }

    if (option.isSupplier) {
      return '(ספק)';
    }

    return '(משתמש)';
  }
}
