<app-loader *ngIf="showLoader"></app-loader>

<mat-expansion-panel style="direction: rtl;" [ngStyle]="isFirstLevel ? {'width': '660px'} : {'width': '560px'}">
  <mat-expansion-panel-header>
    <div class="d-flex flex-fill">
      <div class="d-flex flex-row flex-fill">

        <img (click)="openImageDialogForCategory(category)" height="50" width="50" [src]="category.imageUrl"
             class="img rounded ml-3">

        <button (click)="editCreateCategory(null,category.id)" *ngIf="!isLeaf || isEmpty()"
                class="btn btn-sm btn-primary ml-2 mt-1 mb-1">הוסף תת קטגוריה
        </button>
        <p class="p-0 m-0">{{category.name}} {{getDesc()}}</p>
      </div>


      <div class="flex-fill  ml-3">
        <mat-icon class="mr-2 ml-2 text-primary" (click)="editCreateCategory(category ,category.id)">edit</mat-icon>
        <mat-icon class="text-danger" (click)="deleteCategory(category)">delete</mat-icon>
      </div>

    </div>
    <!-- <mat-panel-title>
       {{category.name}} {{getDesc()}}
     </mat-panel-title>
     <mat-panel-description>

     </mat-panel-description>-->
  </mat-expansion-panel-header>

  <ul *ngIf="hasProducts(); else nestedCategories" class="list-group p-0 m-0" style="direction: rtl">
    <!--  LEAF  - show products -->

    <li class="list-group-item p-0 mt-2" *ngFor="let product of category.products || []">
      <app-product [supplierId]="supplierId" [categoryId]="category.id" [product]="product" (onProductDelete)="deleteProduct($event)" (onProductEdit)="editCreateProduct($event)"></app-product>

     <!-- <div *ngIf="false" class="d-flex flex-row p-2">
        <img (click)="openImageDialog(product)" height="50" width="50" [src]="product.images.thumbUrl"
             class="img rounded ml-3">
        <div class="d-flex flex-column flex-fill justify-content-between">
          &lt;!&ndash; Created&ndash;&gt;
          <div class="d-flex flex-row justify-content-between">
            <p class="m-0 p-0">{{product.name}}</p>
          </div>


          &lt;!&ndash;Amount&ndash;&gt;
          <div class="d-flex flex-row justify-content-between">
            <p class="m-0 p-0">{{product.barcode}}</p>
          </div>

          &lt;!&ndash;Actions&ndash;&gt;
          <div class="d-flex flex-row justify-content-end">
            <button (click)="deleteProduct(product)" class="btn btn-sm btn-danger ml-2">מחק</button>
            <button (click)="editCreateProduct(product)" class="btn btn-sm btn-primary ml-2">ערוך</button>
            <button (click)="comparePrice(product)" class="btn btn-sm btn-primary">השוואת מחירים</button>
          </div>

          &lt;!&ndash;Compare Prices&ndash;&gt;
          <div *ngIf="productIdToCompare == product.id" class="d-flex flex-row justify-content-end mt-3">
            <div *ngFor="let price of otherPrices">
              <div class="d-flex flex-column align-items-center justify-content-center card ml-2 mr-2 p-3">
                <img (click)="openImageDialog(product)" height="50" width="50"
                     [src]="price.supplier?.images?.thumbUrl  || '/assets/no-image.png'"
                     class="img rounded">
                <p class="m-0 p-0 text-center small">{{price.supplier.name}}</p>
                <p class="m-0 p-0 text-center">{{formatPrice(price.price)}}</p>
                <p class="m-0 p-0 text-center small">{{price.distance}} מטר</p>
              </div>
            </div>
          </div>


        </div>
      </div>-->
    </li>

  </ul>

  <ng-template #nestedCategories>
    <app-category *ngFor="let child of category.children" [category]="child" [supplierId]="supplierId"
                  (categoryDeleted)="onCategoryDeleted(category)"
                  (onCategoryEdit)="onCategoryEdited($event)"></app-category>
  </ng-template>

  <button (click)="editCreateProduct(null)" *ngIf="hasProducts() || isEmpty()" class="btn btn-sm btn-success ml-2 mt-2">
    הוסף מוצר
  </button>

</mat-expansion-panel>
