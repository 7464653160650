import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatSnackBarVerticalPosition} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {Utils} from '../../../util/utils';
import {DataService} from '../../../service/data/data.service';
import {__await} from 'tslib';
import {ImageDialogComponent} from '../../views/image-dialog/image-dialog.component';

@Component({
  selector: 'app-edit-supplier-dialog',
  templateUrl: './edit-supplier-dialog.component.html',
  styleUrls: ['./edit-supplier-dialog.component.scss']
})
export class EditSupplierDialogComponent extends BaseDialogComponent<EditSupplierDialogComponent> {


  supplier;
  originalSupplier;
  isNewSupplier = false;

  voucherOptions = [
    {
      title: 'הביט',
      value: 'Habit'
    },
    {
      title: 'תו פלוס',
      value: 'Tav Plus'
    }, {
      title: 'רמי לוי',
      value: 'Rami Levi'
    }
  ];

  bankOptions = [
    {
      title: 'בנק ישראל',
      value: '99'
    },
    {
      title: 'בנק הפועלים',
      value: '12'
    },
    {
      title: 'בנק לאומי',
      value: '10'
    },
    {
      title: 'בנק דיסקונט',
      value: '11'
    },
    {
      title: 'בנק מזרחי טפחות',
      value: '20'
    },
    {
      title: 'הבנק הבינלאומי הראשון',
      value: '31'
    },
    {
      title: 'בנק אוצר החייל',
      value: '14'
    },
    {
      title: 'בנק מרכנטיל דיסקונט',
      value: '17'
    },
    {
      title: 'בנק הדואר',
      value: '09'
    },
    {
      title: 'בנק איגוד',
      value: '13'
    },
    {
      title: 'בנק מסד',
      value: '46'
    },
    {
      title: 'בנק פועלי אגודת ישראל',
      value: '52'
    },
    {
      title: 'בנק דיסקונט למשכנתאות',
      value: '90'
    },
    {
      title: 'בנק לאומי למשכנתאות בעמ',
      value: '77'
    },
    {
      title: 'בנק יהב לעובדי המדינה',
      value: '04'
    },
    {
      title: 'יובנק בעמ',
      value: '26'
    },
    {
      title: 'בנק אדנים',
      value: '06'
    },
    {
      title: 'בנק ירושלים',
      value: '54'
    },
    {
      title: 'בנק ערבי ישראלי',
      value: '34'
    }

  ];

  chainOptions;

  newConnectedClientPhone = '';


  selectedLevel = this.voucherOptions[0].value;
  selectedBankValue = this.bankOptions[0].value;
  selectedChainValue;
  notBelongToChain = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditSupplierDialogComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.isNewSupplier = data.supplier == null;
    if (!this.isNewSupplier) {
      this.supplier = JSON.parse(JSON.stringify(data.supplier));
      this.originalSupplier = data.supplier;
      this.notBelongToChain = !this.originalSupplier.chain;
    }
  }

  async ngOnInit() {

    await this.initSupplier();
    console.log('****** after init supplier');


    console.log('****** on init');
    // set the relevant chain
    const chainsRes = await this.httpService.getChains({}, {});
    this.chainOptions = chainsRes['result']['list'];
    this.chainOptions.unshift({
      hebrewName: 'סניף ללא רשת',
      id: '',
      linkedCustomers: [],
      name: 'סניף ללא רשת'
    });

    console.log('chains', this.chainOptions);
    let index = 0;
    if (this.supplier.chain) {
      index = this.chainOptions.findIndex(o => o.id === this.supplier.chain.id);
      index = Math.max(index, 0);
    }
    console.log('index is: ', index);
    this.selectedChainValue = this.chainOptions[index].id;
  }

  async initSupplier() {

    if (!this.isNewSupplier) {

      const t = await this.httpService.getSupplier(this.originalSupplier.id);
      this.supplier = t['result']['list'][0];
      console.log(this.supplier);
    }

    if (!this.supplier) {
      this.supplier = {
        apiPayment: {enable: false, profitPercentage: 0},
        bankAccountDetails: {bankNumber: this.selectedBankValue, branch: '', accountNumber: '', accountBeneficial: ''},
        buyOnline: false,
        city: '',
        deliveryEnabled: true,
        deliveryPrice: '',
        // distributionRadius: 10000,
        activityRadius: 10000,
        houseNumber: '',
        image: '',
        linkedCustomers: [],
        name: '',
        chain: '',
        branch: '',
        onlineProfitPercentage: 10,
        orderDistributionPercentage: 0.4,
        payAtRegisterEnabled: true,
        selfPickupEnabled: true,
        street: '',
        vouchers: [],
        zipcode: '',
        habitGoPricesEnabled: false
      };

    }


    console.log('************  p2');


    // console.log(JSON.stringify(this.supplier));
    console.log(this.supplier);

    // set the relevant bank number
    if (this.supplier.bankAccountDetails) {
      const index = this.bankOptions.findIndex(o => o.value === this.supplier.bankAccountDetails.bankNumber);
      this.selectedBankValue = this.bankOptions[Math.max(0, index)].value;
    }

    // make sure we have a valid linkedCustomers
    this.supplier.linkedCustomers = this.supplier.linkedCustomers || [];
    this.supplier.linkedCustomers = this.supplier.linkedCustomers.filter(c => !!c.name);

    if (!this.supplier.bankAccountDetails) {
      this.supplier.bankAccountDetails = {bankNumber: '12', branch: '', accountNumber: '', accountBeneficial: ''};
    }
  }


  async addLinkedClient() {

    this.supplier.linkedCustomers = this.supplier.linkedCustomers || [];
    const linkedCustomerNumbers = this.supplier.linkedCustomers.map(i => i.phone);
    if (this.newConnectedClientPhone) {
      linkedCustomerNumbers.push(Utils.formatPhone(this.newConnectedClientPhone));
    }
    this.supplier.linkedCustomerNumbers = linkedCustomerNumbers;

    const res = await this.httpService.updateSupplier(this.supplier);
    const status: string = res['status'];
    if (status.toLowerCase() == 'ok') {
      this.supplier = res['result'];
    } else {
      this.showSnackBar(status);
    }
    console.log(res);

    this.newConnectedClientPhone = '';
    // customerPhones
  }

  async removeLinkedClient(phone) {

    this.supplier.linkedCustomers = this.supplier.linkedCustomers || [];
    const linkedCustomerNumbers = this.supplier.linkedCustomers.map(i => i.phone);
    linkedCustomerNumbers.push(Utils.formatPhone(phone.value));
    this.supplier.linkedCustomerNumbers = linkedCustomerNumbers;

    const res = await this.httpService.updateSupplier(this.supplier);
    console.log(res);

    // customerPhones
  }

  addCategory(catName) {
    this.supplier.categories = this.supplier.categories || [];
    this.supplier.categories.push(catName.value);
    catName.value = '';
  }

  getLabel(value: number): string {
    return Math.round((value / 1000)).toString();
  }


  async addVoucher(profit) {
    console.log(this.selectedLevel);
    this.supplier.vouchers = this.supplier.vouchers || [];
    if (this.supplier.vouchers.map(v => v.voucherType).indexOf(this.selectedLevel) !== -1) {
      this.showSnackBar('שובר זה כבר קיים');
      return;
    }

    const profitVal = parseFloat(profit.value);

    if (profitVal < 0 || profitVal > 100) {
      this.showSnackBar('יש להזין אחוז רווח תקין');
      return;
    }


    this.supplier.vouchers.push(
      {
        voucherType: this.selectedLevel,
        profit: profitVal
      }
    );

    const res = await this.httpService.updateSupplier(this.supplier);
    console.log(res);
    profit.value = '';
  }


  async save() {

    // input check

    console.log('bank number', this.selectedBankValue);


    if (!this.supplier.name) {
      this.showSnackBar('יש להזין שם עסק');
      return;
    }

    if (!this.supplier.street || !this.supplier.houseNumber || !this.supplier.city) {
      this.showSnackBar('יש להזין כתובת חוקית');
      return;
    }

    this.error = '';
    this.supplier.chainId = this.selectedChainValue;
    this.supplier.bankAccountDetails.bankNumber = this.selectedBankValue;


    if (this.notBelongToChain || !this.selectedChainValue || this.selectedChainValue.id === '') {
      console.log('סניף ללא רשת');
      delete this.supplier.chain;
    }

    const res = this.isNewSupplier ? await this.httpService.addSupplier(this.supplier) :
      await this.httpService.updateSupplier(this.supplier);
    console.log(res);

    const status = res['status'] as string;
    if (status.toLowerCase() === 'ok') {
      this.dialogRef.close(res['result']);
      return;
    }

    // this.error = status;
    this.showSnackBar('שגיאה: ' + status);
    // this.originalSupplier = res['result'];
  }

  getOnOffText(preText, isEnabled) {
    const activeText = isEnabled ? 'פעיל' : 'לא פעיל';
    return preText + ' ' + '(' + activeText + ')';
  }

  getChainOptionValue(o) {
    const postText = (o.sdkClients && o.sdkClients[0]) ? (' (' + o.sdkClients[0].name + ')') : '';
    return o.hebrewName + postText;
  }

  onBankChange(event) {
    console.log(event);

  }

  async openImageDialogForCampaign() {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: this.supplier.defaultCampaignImage
      }
    });

    const file = await dialogRef.afterClosed().toPromise();
    if (!file) {
      return;
    }

    this.showLoader = true;
    const res = await this.httpService.uploadCampaignImage(this.supplier.id, null, file);
    console.log(res);
    this.showLoader = false;


    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }

    const p = res['result'];
  }
}
