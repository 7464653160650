import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';
import {ImageDialogComponent} from '../../views/image-dialog/image-dialog.component';
import {EditSupplierDialogComponent} from '../edit-supplier-dialog/edit-supplier-dialog.component';
import {ImageSearchDialogComponent} from '../image-search-dialog/image-search-dialog.component';

@Component({
  selector: 'app-edit-habit-product-dialog',
  templateUrl: './edit-habit-product-dialog.component.html',
  styleUrls: ['./edit-habit-product-dialog.component.scss']
})
export class EditHabitProductDialogComponent extends BaseDialogComponent<EditHabitProductDialogComponent> {

  product;
  originalProduct;
  showLoader = false;


  // selectedPackValue = 'unit';

  packOptions = [
    {
      name: 'יחידה',
      value: 'unit'
    },
    {
      name: 'גרם',
      value: 'gr'
    },
    {
      name: 'קילו',
      value: 'kg'
    },
    {
      name: 'מארז',
      value: 'pack'
    },
    {
      name: 'שק',
      value: 'bag'
    },
    {
      name: 'סנטימטר',
      value: 'cm'
    },
    {
      name: 'מארז של 2',
      value: 'pack2'
    },
    {
      name: 'מארז של 4',
      value: 'pack4'
    },
    {
      name: 'מארז של 6',
      value: 'pack6'
    },
    {
      name: 'מארז של 8',
      value: 'pack8'
    },
    {
      name: 'מארז של 10',
      value: 'pack10'
    },
    {
      name: 'מארז של 12',
      value: 'pack12'
    }, {
      name: 'מארז של 14',
      value: 'pack14'
    }, {
      name: 'מארז של 16',
      value: 'pack16'
    }];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditHabitProductDialogComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.isNew = data.product == null;
    if (this.isNew) {
      this.product = {
        name: '',
        barcode: '',
        isEnabled: true,
        manufacturer: '',
        unitOfMeasure: 'unit',
        kosherType: ''
      };
    } else {
      this.product = JSON.parse(JSON.stringify(data.product));
      this.originalProduct = data.product;
    }


  }

  ngOnInit() {
  }

  async save() {

    const res = this.isNew ? await this.httpService.addHabitProduct(this.product) :
      await this.httpService.updateHabitProduct(this.product);
    console.log(res);


    const status = res['status'] as string;
    if (status.toLowerCase() === 'ok') {
      this.dialogRef.close(res['result']);
      return;
    }

    this.dataService.okDialog('אירעה שגיאה: ' + status);
  }

  async pickImage(query) {

    // ;
    const link = await this.dialog.open(ImageSearchDialogComponent, {
      width: '90vw',
      maxWidth: '600px',
      height: '80vh',
      panelClass: 'dialog-style',
      data: {
        //  query: 'guitar'
        query: query
      }
    }).afterClosed().toPromise();

    console.log('after close', link);
    if (!link) {
      return;
    }

    this.product.images.thumbUrl = link;

    // download image
    // this.showLoader = true;

    // this.showLoader = false;

    this.openImageDialog();

  }

  async openImageDialog() {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: this.product.images.thumbUrl
      }
    });

    const file = await dialogRef.afterClosed().toPromise();
    if (!file) {
      return;
    }

    console.log(file);
    // this.uploadFile(file, supplier);

    this.showLoader = true;
    const res = await this.httpService.uploadProductImage(this.product.id, file);
    console.log(res);
    this.showLoader = false;


    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(status);
      return;
    }

    const p = res['result'];
    this.product.images.thumbUrl = p.images.thumbUrl;
  }
}
