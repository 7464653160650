import {Component, Inject, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http/http.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-image-search-dialog',
  templateUrl: './image-search-dialog.component.html',
  styleUrls: ['./image-search-dialog.component.scss']
})
export class ImageSearchDialogComponent implements OnInit {

  query = '';
  items = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public httpService: HttpService, public dialogRef: MatDialogRef<ImageSearchDialogComponent>, public dialog: MatDialog, public dataService: DataService) {
    this.query = data.query;

    console.log(this.query);
  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    this.items = await this.httpService.getGoogleImageResults(this.query);

  }

  close() {
    this.dialogRef.close(null);
  }

  select(link){

    this.dialogRef.close(link);
  }
}
