import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpService} from '../../../service/http/http.service';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {DataService} from '../../../service/data/data.service';
import {MatDialog} from '@angular/material';
import {EditSupplierBankTransferComponent} from '../../dialog/edit-supplier-bank-transfer/edit-supplier-bank-transfer.component';
import {EditBankTransferComponent} from '../../dialog/edit-bank-transfer/edit-bank-transfer.component';

@Component({
  selector: 'app-bank-transfers',
  templateUrl: './bank-transfers.component.html',
  styleUrls: ['./bank-transfers.component.scss']
})
export class BankTransfersComponent extends BaseListComponent {


  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dataService: DataService, dialog: MatDialog) {
    super(httpService, activatedRoute, dataService, dialog);
  }


  async loadData() {
    super.loadData();
    const res = await this.httpService.getBankTransfer(this.sortValue, this.filters);
    this.checkResults(res);
    this.results = this.results || [];
    this.results = this.results.concat(res['result'].list);    // console.log(res);
    this.results = this.results.filter(i => i != null);
    this.total = res['result'].total;
    this.afterLoadData(res['result'].list.length);
    // console.log(this.results);
  }


  async showDialog(transfer) {
    console.log(transfer);

    const dialogRef = this.dialog.open(EditBankTransferComponent, {
      width: '90vw',
      maxWidth: '600px',
      height: '80vh',
      panelClass: 'dialog-style',
      data: {
        transfer
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    console.log('after close', result);
    if (!result) {
      return;
    }

    if (transfer != null) {
      Object.assign(transfer, result);
    }

    this.loadData();
  }

  getClassName = (): String => 'BankTransfersComponent';
}



