<app-loader *ngIf="showLoader"></app-loader>

<div class="container">

  <div *ngIf="!isSupplier()" class="row mt-3" style="direction: rtl">
    <div *ngIf="false" class="col p-0 m-0">
      <div class="d-flex flex-row" style="direction: rtl">
        <input (keydown.enter)="search(searchField.value)" class="form-control ml-2 w-25" type="text"
               placeholder="הזן שם רשת" #searchField>
        <button (click)="search(searchField.value)" class="btn btn-primary">חפש</button>
      </div>
    </div>

    <app-search hint="הזן שם רשת" customers=false [chains]="true" [suppliers]="false" (itemSelected)="search($event)"></app-search>

  </div>


  <div class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">
    <span style="direction: rtl">
          <label class="ml-3">{{total.count}} רשתות</label>
          <label class="ml-3">{{formatPrice(total.total)}}</label>
      <button (click)="showChainDialog(null)" class="btn btn-success">רשת חדשה</button>
    </span>

  </div>


  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="loadData()">

    <table *ngIf="results" class="table table-hover mt-2" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>#</th>
        <th *ngIf="false" scope="col">תמונה</th>
        <th>שם בעברית</th>
        <th (click)="setSortValue('name')" scope="col" class="text-right">
          <span *ngIf="sortValue === 'name'">↑</span>
          <span *ngIf="sortValue === '-name'">↓</span>
          שם באנגלית
        </th>
        <th scope="col">מנהלי רשת</th>
        <th scope="col">פעולות</th>
      </tr>
      </thead>


      <tbody class="text-right">
      <tr *ngFor="let result of results; let i = index">
        <td>{{i}}</td>
        <td *ngIf="false">
          <input hidden type="file" #uploader (change)="uploadFile($event,result)">
          <img (click)="uploader.click()" height="80" width="80" [src]="result.image || '/assets/logo.png'"
               class="img rounded">
        </td>
        <td>{{result.hebrewName}} </td>
        <td
          (click)="toggleFilter('name', result.name,'שם - ' + result.name)">{{result.name}}
        </td>
        <td></td>
        <td>
          <div class="d-flex flex-row mt-2">
            <mat-icon (click)="showChainDialog(result)" style="cursor: pointer">edit</mat-icon>
          </div>
        </td>

      </tr>
      </tbody>
    </table>
  </div>

</div>
