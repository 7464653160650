import { Component, OnInit } from '@angular/core';
import {LogItem} from '../../model/LogItem';
import {LogService} from '../../service/logs/logs.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  logs: LogItem[] = [];
  color = 'red';

  constructor(private logService: LogService) {
    this.loadAllLogs();
  }

  loadAllLogs() {
    this.logService.getItems().subscribe(data => {
      this.logs = data;
      console.log(data);
    });
  }


  delete(log) {
    console.log(log);
    this.logService.deleteItem(log);
  }

  ngOnInit() {
  }

  getClass(logType) {
    switch (logType) {
      case 0:
        return 'badge badge-primary';
      case 1:
        return 'badge badge-danger';
      case 2:
        return 'badge badge-warning';
      case 3:
        return 'badge badge-success';
      default:
        return 'badge badge-info';
    }
  }

  getTitle(logType) {
    switch (logType) {
      case 0:
        return 'Info';
      case 1:
        return 'Error';
      case 2:
        return 'Warning';
      case 3:
        return 'Success';
      default:
        return 'Debug';
    }
  }

  searchForUser(value: string) {
    this.logService.getLogsForUser(value).subscribe(logs => {
      this.logs = logs;
    });
  }

}
