import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {HttpService} from '../../service/http/http.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {Utils} from '../../util/utils';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  serverClickCounter = 0;
  isTestServer = false;
  codeSent = false;
  phoneNumber;
  showLoader = false;


  @ViewChild('code', {static: false}) code: ElementRef;


  constructor(private httpService: HttpService, private router: Router, private snackBar: MatSnackBar, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.httpService.setServer(this.isLocal());
    this.isTestServer = this.isLocal();

  }

  async login(email, password) {
    const answer = await this.httpService.login(email, password);
    console.log(answer);
    if (!answer || !answer['result']['token']) {
      console.error('invalid user');
      return;
    }

    const token = answer['result']['token'];
    const role = answer['result']['role'];
    const name = answer['result']['name'];
    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    localStorage.setItem('name', name);
    // localStorage.setItem('role', 'supplier');

    this.httpService.updateOptions();
    this.router.navigateByUrl('');

    console.log(token);
  }

  async loginWithPhone(phone) {
    if (phone == '') {
      return;
    }

    this.showLoader = true;
    const answer = await this.httpService.loginWithPhone(Utils.formatPhone(phone));
    this.showLoader = false;

    console.log(answer);

    const status: string = answer['status'];
    if (status.toLowerCase() != 'ok') {
      this.showSnackBar('אירעה שגיאה');
      return;
    }

    // code was sent
    this.phoneNumber = Utils.formatPhone(phone);
    this.codeSent = true;


    this.changeDetectorRef.detectChanges(); // not required
    this.code.nativeElement.focus();

  }

  async phoneConfirmation(code) {
    if (code == '') {
      return;
    }

    this.showLoader = true;
    const answer = await this.httpService.phoneConfirmation(this.phoneNumber, code);
    this.showLoader = false;

    console.log(answer);

    const status: string = answer['status'];
    if (status.toLowerCase() != 'ok') {
      if (status.toUpperCase() == 'UNAUTHORIZED') {
        this.showSnackBar('הקוד שהוזן שגוי');
        return;
      }
      this.showSnackBar('אירעה שגיאה');
      return;
    }
    if (!answer || !answer['result']['token']) {
      console.error('invalid user');
      return;
    }

    const result = answer['result'];
    const token = result['token'];
    const role = result['role'];
    // const name = role === 'admin' ? result['name'] : (result['supplier']['name'] || result['chain']['name']);
    let name = '';
    let image = '';
    if (role === 'admin') {
      name = result['name'];
      image = undefined;
    } else {
      if (result['supplier']) {
        name = result['supplier']['name'];
        image = result['supplier']['image'];
      } else {
        name = result['chain']['name'];

        //  image =  result['supplier']['image']
      }
    }
    // const image = (role === 'admin') ? undefined : result['supplier']['image'];

    console.log('image is:', image);

    // answer['result']['supplier']//

    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    // localStorage.setItem('chain', result['supplier'] ? result['supplier']['chain'] : undefined);
    localStorage.setItem('chain', result['chain']);
    localStorage.setItem('name', name);
    localStorage.setItem('image', image);
    localStorage.setItem('showOfflineOrders', result['showOfflineOrders'] || false);
    localStorage.setItem('showOnlineOrders', result['showOnlineOrders'] || false);

    console.log(localStorage.getItem('showOfflineOrders'));

    // localStorage.setItem('role', 'supplier');

    this.httpService.updateOptions();
    this.router.navigateByUrl('');

    console.log(token);
  }


  serverClicked() {
    this.serverClickCounter++;
    if (this.serverClickCounter >= 5) {
      this.serverClickCounter = 0;
      this.isTestServer = !this.isTestServer;
      this.showSnackBar(this.isTestServer ? 'שרת טסטים פעיל' : 'שרת חי פעיל');
      this.httpService.setServer(this.isTestServer);
    }
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
      verticalPosition: 'top'
    });
  }

  isLocal = () => !environment.production;

}
