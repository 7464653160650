<div *ngIf="isNew" class="bg-success d-block text-center p-2">
  <h4 class="text-white p-0 m-0">יצירת קטגוריה</h4>
</div>

<div class="d-flex flex-column justify-content-center p-2" style="direction: rtl">


  <div class="d-flex flex-column justify-content-center p-3" style="direction: rtl">

    <!-- details -->

    <div class="row">

      <div class="col-12">
        <div class="d-flex flex-column align-items-center mt-2">
          <label class="m-0 p-0">שם קטגוריה</label>
          <input class="form-control input-width-sm mt-2" [(ngModel)]="category.name">
        </div>

      </div>

    </div>


    <div class="row mt-3">
      <div class="col-12">
        <button (click)="save()" class="btn btn-success btn-block">שמור</button>
      </div>

    </div>

  </div>
</div>
