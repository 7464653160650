import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-user-to-user',
  templateUrl: './user-to-user.component.html',
  styleUrls: ['./user-to-user.component.scss']
})
export class UserToUserComponent extends BaseListComponent {

  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dataService: DataService, dialog: MatDialog) {
    super(httpService, activatedRoute, dataService, dialog);
  }

  async loadData() {
    super.loadData();
    const res = await this.httpService.getUserToUserTransfer(this.sortValue, this.filters);
    this.checkResults(res);
    this.results = this.results || [];
    this.results = (res['result'].list || []).filter(i => i != null);
    this.total = res['result'].total;
    this.afterLoadData(res['result'].list.length);
  }


  getClassName = (): String => 'UserToUserComponent';


}
