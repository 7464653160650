<app-loader *ngIf="showLoader"></app-loader>

<div class="container">


  <div class="d-flex flex-row" style="direction: rtl">
    <input class="form-control w-25 ml-3" placeholder="קטגוריה חדשה" [(ngModel)]="newCategoryName">
    <button (click)="createCategory()" class="btn btn-sm btn-success">צור קטגורייה</button>
  </div>

  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       (scrolled)="loadData()">

    <table *ngIf="results" class="table table-hover mt-3" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>#</th>
        <th>שם קטגוריה</th>
        <th>רשימת ספקים</th>
        <th scope="col">פעולות</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let result of results; let i = index" class="text-right">
        <td>{{i}}</td>
        <td>
          <div class="d-flex flex-column">
            <p *ngIf="currentEditCategory!= result"> {{result.name}}</p>
            <input *ngIf="currentEditCategory == result" [(ngModel)]="result.name" placeholder="שם קטגוריה">
          </div>
        </td>
        <td>
          <div class="d-flex flex-row" style="overflow-x: scroll; max-width: 1200px">
            <div style="width: 100px;" class="card ml-2 mr-2" *ngFor="let supplier of result.suppliers || []">
              <div class="text-center" style="width: 100px; height: 100px;">
                <img class="img img-thumbnail" width="100px" height="100px" [src]="supplier.images.thumbUrl">
              </div>
              <div class="card-footer">
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <h6 style="height: 60px;" class="small text-center">{{supplier.name}}</h6>
                  <button (click)="deleteSupplier(supplier,result)" class="btn btn-sm btn-danger">הסרה</button>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div class="d-flex flex-row mt-2 align-items-center">
            <mat-icon (click)="currentEditCategory = result" style="cursor: pointer">edit</mat-icon>
            <button *ngIf="currentEditCategory == result" (click)="updateCategory(result)"
                    class="btn btn-sm btn-primary mr-3">שמירה
            </button>
            <button *ngIf="!currentEditCategory" (click)="findSupplier(result)" class="btn btn-sm btn-success mr-3">ספק
              חדש
            </button>

            <button *ngIf="!currentEditCategory" (click)="deleteCategory(result)" class="btn btn-sm btn-danger mr-3">מחיקה</button>
          </div>
        </td>

      </tr>
      </tbody>
    </table>
  </div>

</div>
