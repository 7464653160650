import {HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../service/data/data.service';
import {OfflineOrdersComponent} from '../../lists/offline-orders/offline-orders.component';
import {UserProfileDialogComponent} from '../../dialog/user-profile-dialog/user-profile-dialog.component';
import {MatDialog} from '@angular/material';
import {noop as _noop} from 'lodash-es';
import {OkDialogComponent} from '../../dialog/ok-dialog/ok-dialog.component';
import {BankTransfersComponent} from '../../lists/bank-transfers/bank-transfers.component';
import {ChainsComponent} from '../../lists/chains/chains.component';
import {SuppliersComponent} from '../../lists/suppliers/suppliers.component';
import {Utils} from '../../../util/utils';
import {getClassName} from 'codelyzer/util/utils';


export abstract class BaseListComponent implements OnInit, OnDestroy {

  showLoader = false;

  @Input()
  results;

  @Input()
  enableCashback = true;


  total;


  startDate = new Date();
  endDate = new Date();

  sortValue = '-created';
  filters = {};

  @Input()
  customerId: string;

  @Input()
  isExternalData = false;

  // pagination
  isInfiniteScrollDisabled = false;
  hasPagination = true;
  isLoadingMore = false;
  page = 0;
  RESPONSE_ITEM_COUNT = 50;
  scrollDelay = 1000;
  numOfItemsInLastCall;

  dateSubscription;


  constructor(public httpService: HttpService, private route: ActivatedRoute, public dataService: DataService, public dialog: MatDialog) {
    this.endDate = new Date();
    this.endDate.setHours(23, 59, 59);

    this.startDate = new Date();
    this.startDate.setDate(new Date().getDate() - (30 * 12));

  }

  ngOnInit() {

    this.resetPagination();

    this.route.queryParams.subscribe(params => {

      // add dates filter
      this.addFilter('startDate', this.startDate.getTime(), 'מתאריך - ' + this.getDateStr(this.startDate.getTime()));
      this.addFilter('endDate', this.endDate.getTime(), 'עד תאריך - ' + this.getDateStr(this.endDate.getTime()));


      let filterKey = params['filterKey'];
      let filterValue = params['filterValue'];
      let filterTitle = params['filterTitle'];

      if (filterKey && filterValue && filterTitle) {
        this.addFilter(filterKey, filterValue, filterTitle);
      }


      this.dateSubscription = this.dataService.currentDates.subscribe(newDates => {

        // console.log('dates has changed!');
        this.resetData();

        const defaultStartDate = new Date();
        defaultStartDate.setDate(new Date().getDate() - 30);

        this.startDate = newDates.startDate || defaultStartDate;
        this.endDate = newDates.endDate || new Date();

        this.addFilter('startDate', this.startDate.getTime(), 'מתאריך - ' + this.getDateStr(this.startDate.getTime()));
        this.addFilter('endDate', this.endDate.getTime(), 'עד תאריך - ' + this.getDateStr(this.endDate.getTime()));

        this.loadData();
      });


      // this.loadData();

    });


  }


  resetData() {
    this.results = undefined;
    this.total = undefined;
    this.resetPagination();
    this.page = 0;
  }

  resetFilters() {
    this.filters = {};
  }

  resetPagination() {
    this.numOfItemsInLastCall = null;
    this.isInfiniteScrollDisabled = false;
    this.page = 0;
    this.hasPagination = true;
    this.isLoadingMore = false;
  }


  async loadData() {
    // do not load data from server because it is external
    if (this.isExternalData) {
      return;
    }

    this.showLoader = true;
    // remove dates filter if this is a specific user page
    console.log('loading page number: ' + this.page + ' in ' + this.getClassName());

    // add page number
    this.addFilter('page', this.page, '');

    if (this.customerId) {
      delete this.filters['startDate'];
      delete this.filters['endDate'];
      this.addFilter('customerId', this.customerId, 'משתמש');
    }
  }

  afterLoadData(dataLength) {
    this.showLoader = false;

    // console.log('page number ' + this.page + ' was loaded');
    this.page += 1;
    this.isLoadingMore = false;
    this.hasPagination = dataLength >= this.RESPONSE_ITEM_COUNT;
    if (dataLength < this.RESPONSE_ITEM_COUNT) {
      // console.error('setting has pagination to false');
    }
  }

  getFullName(name) {
    if (!name) {
      return '';
    }
    if (!name.first || !name.last) {
      return '';
    }
    return name.first + ' ' + name.last;
  }

  setSortValue(value) {
    if (this.isExternalData) {
      // do not allow sort with external data
      return;
    }
    if (value.replace('-', '') === this.sortValue.replace('-', '')) {
      // just toggle direction
      const isMinus = this.sortValue.startsWith('-');
      this.sortValue = isMinus ? value : ('-' + value);
    } else {
      this.sortValue = value;
    }

    this.resetData();
    // this.resetPagination();
    this.loadData();
  }

  toggleFilter(name, value, present, remove = false) {
    if (this.isExternalData) {
      // do not allow sort with external data
      return;
    }
    // this.resetData();
    // console.log('value', value);
    // console.log('present', present);
    if (!this.filters[name]) {
      this.filters[name] = {
        value,
        present
      };
    } else {
      if (!remove) {
        return;
      }
      delete this.filters[name];
    }
    // console.log(this.filters);
    this.resetData();
    this.loadData();
  }

  addFilter(name, value, present) {
    this.filters[name] = {
      value,
      present
    };
    // console.log(this.filters);
    // this.loadData();
  }

  openInvoice(invoice) {
    console.log(invoice);
    if (!invoice || invoice == {}) {
      return;
    }
    window.open(invoice.link);
  }

  openRefund(refunds) {
    console.log(refunds);
    if (!refunds || refunds.length == 0) {
      return;
    }

    window.open(refunds[0].invoiceUrl);
    window.open(refunds[0].receiptUrl);
  }

  getDateStr(d: any, shortYear = false) {
    try {
      if (!d) {
        return '-';
      }
      const date = new Date(d);
      const day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
      const month = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)));

      const answer = day + '/' + month + '/' + (date.getFullYear() - (shortYear ? 2000 : 0));
      return answer;
    } catch (e) {
      return '-';
    }
  }

  getDateStrWithTime(d: any, shortYear = false) {
    try {
      if (!d) {
        return '-';
      }
      const date = new Date(d);
      const day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
      const month = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)));
      const hour = date.getHours();
      const minute = date.getMinutes();
      const answer = day + '/' + month + '/' + (date.getFullYear() - (shortYear ? 2000 : 0)) + ' ' + this.twoDigitNumber(hour) + ':' + this.twoDigitNumber(minute);
      return answer;
    } catch (e) {
      return '-';
    }
  }

  twoDigitNumber = (num) => ('0' + num).slice(-2);


  formatPrice(p) {
    if (p === undefined) {
      return '';
    }
    if (p === 0) {
      return '0₪';
    }
    return p.toFixed(2) + '₪';
  }

  getChipFilters() {
    const answer = Object.assign({}, this.filters);
    delete answer['startDate'];
    delete answer['endDate'];
    delete answer['page'];
    if (this.customerId) {
      delete answer['customerId'];
    }
    return answer;
  }

  isAdmin() {
    return localStorage.getItem('role') === 'admin';
  }

  isSupplier() {
    return localStorage.getItem('role') === 'supplier';
  }

  formatStatus(status): string {
    switch (status) {
      case 'pending':
        return 'ממתין';
      case 'completed':
        return 'הושלם';
      case 'rejected':
        return 'נדחה';
    }
    return '';
  }

  formatOrderStatus(status, isPaymentIngredients = false): string {
    switch (status) {
      case 'refunded':
        return 'בוצע זיכוי';
      case 'paid-in-store':
        return isPaymentIngredients ? 'שולם' : 'הושלם';
      case 'paid':
        return 'שולם';
    }
    return 'ADD STATUS - ' + status;
  }


  checkResults(res) {
    if (Utils.hasError(res)) {
      this.showLoader = false;
    }

    if (!res['result']) {
      res['result'] = {};
      res['results'].list = [];
      res['result'].total = {
        amount: 0,
        count: 0,
        futurePaymentsAmount: 0
      };
    }

    this.numOfItemsInLastCall = res.result.list?.length || 0;
    console.log('got ' + this.numOfItemsInLastCall + ' new items in ' + this.getClassName());
    /* if (res.result.list.length < this.RESPONSE_ITEM_COUNT) {
       this.isInfiniteScrollDisabled = true;
     }*/

    // toggle pagination if needed
    this.isInfiniteScrollDisabled = this.numOfItemsInLastCall < this.RESPONSE_ITEM_COUNT;

  }

  showUserProfileDialog(userId) {
    if (!this.isAdmin()) {
      return;
    }
    const dialogRef = this.dialog.open(UserProfileDialogComponent, {
      width: '90vw',
      height: '80vh',
      panelClass: 'dialog-style',

      data: {
        userId
      }
    });

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('after close', result);
      if (!result) {
        return;
      }
      this.loadData();
    });
  }

  async showOkDialog(message) {
    const dialogRef = this.dialog.open(OkDialogComponent, {
      width: '50vw',
      data: {
        message
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
  }


  abstract getClassName(): String;

  search(autoCompleteItem) {
    // console.log('base list search');
    if (!autoCompleteItem) {
      return;
    }

    // const className = this.constructor.name;
    const className = this.getClassName();
    // console.log('base list - class name', className);


    this.resetData();
    this.resetFilters();

    // console.log('base list  - isFreeText', autoCompleteItem.isFreeText);

    if (!autoCompleteItem.isFreeText) {
      this.addFilter(autoCompleteItem.filterType, autoCompleteItem.id, autoCompleteItem.title);
      this.loadData();
      return;
    }

    const value = autoCompleteItem.text;
    const isNum = /^\d+$/.test(value);

    switch (className) {
      case 'BankTransfersComponent':
      case 'UserToUserComponent':
      case 'LeverageListComponent':

        this.addFilter('customerName', autoCompleteItem.text, 'שם לקוח - ' + autoCompleteItem.text);
        break;

      case 'ChainsComponent':
      case 'CustomersComponent':
      case 'SuppliersComponent':

        if (isNum) {
          this.addFilter('phone', value, 'טלפון - ' + value);
        } else {
          this.addFilter('name', value, 'שם - ' + value);
        }
        break;


      case 'OfflineOrdersComponent':
      case 'OnlineOrdersComponent':
        this.addFilter('search', autoCompleteItem.text, 'חיפוש - ' + autoCompleteItem.text);
        break;

      case 'SupplierPaymentsComponent':
        this.addFilter('supplierName', autoCompleteItem.text, 'שם ספק - ' + autoCompleteItem.text);
        break;

      case 'HabitProductsListComponent':
        this.addFilter('name', autoCompleteItem.text, 'שם מוצר - ' + autoCompleteItem.text);
        break;
      default:
        console.log('base list', 'default');
        break;
    }


    this.loadData();
  }


  ngOnDestroy(): void {
    if (this.dateSubscription != null) {
      console.log('unsubscribe...');
      this.dateSubscription.unsubscribe();
    }
  }


}




