import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base-component/base.component';
import {HttpService} from '../../service/http/http.service';
import {DataService} from '../../service/data/data.service';
import {ActivatedRoute} from '@angular/router';
import {EditCreateCategoryComponent} from '../dialog/edit-create-category/edit-create-category.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-edit-online-store',
  templateUrl: './edit-online-store.component.html',
  styleUrls: ['./edit-online-store.component.scss']
})
export class EditOnlineStoreComponent extends BaseComponent {

  private sub: any;
  supplierId;
  allCategories;
  filteredProducts = [];
  fileToUpload;
  showLoader = false;
  searchBox = '';
  supplier;


  constructor(public dialog: MatDialog, httpService: HttpService, private dataService: DataService, private route: ActivatedRoute) {
    super(httpService);
  }

  async ngOnInit() {
    // this.loadData();
    this.sub = this.route.params.subscribe(params => {
      this.supplierId = params['id'];
      console.log(this.supplierId);

      this.loadData();
    });
  }

  async loadData() {
    const res = await this.httpService.getCategories(this.supplierId);
    console.log(res);
    this.allCategories = res['result'];
    this.updateFilter();

    this.supplier = await this.httpService.getOneSupplier(this.supplierId);
    console.log(this.supplier);
  }

  isEmpty() {
    return (this.allCategories || []).length === 0;
  }

  numOfCategories() {
    // return (this.allCategories || []).length;
    return (this.allCategories || []).length;
  }

  hasSubCategories = () => this.numOfCategories() === 0;
  productIdToCompare: any;
  otherPrices: any;

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onOpened(category, b: boolean) {

  }

  onCategoryDeleted(category) {
    // this.allCategories.splice(this.allCategories.indexOf(category), 1);
    this.allCategories.splice(this.allCategories.indexOf(category), 1);
    console.log('on category deleted', category);

  }

  async createCategory() {
    const dialogRef = this.dialog.open(EditCreateCategoryComponent, {
      panelClass: 'dialog-style-no-padding',
      width: '400px',
      maxWidth: '400px',
      // height: '80vh',
      data: {
        supplierId: this.supplierId,
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    console.log('after close', result);
    if (!result) {
      return;
    }

    this.allCategories.push(result);
  }

  onCategoryEdit(category) {
    // let index = this.allCategories.findIndex((c) => c.id === category.id);
    // this.allCategories[index].name = category.name;

    let index = this.allCategories.findIndex((c) => c.id === category.id);
    this.allCategories[index].name = category.name;
  }

  async downloadExcel() {
    this.httpService.getSupplierPricesFile(this.supplierId);
  }

  handleFileInput(file: any) {
    // console.log(file);
    if (file) {
      this.fileToUpload = file;
      this.uploadExcelFile();
    }
    //  console.log(file);
  }

  async uploadExcelFile() {
    console.log('upload');
    if (!this.fileToUpload) {
      return;
    }
    this.showLoader = true;
    let res;
    try {
      res = await this.httpService.uploadSupplierProductsFile(this.fileToUpload, this.supplierId);
    } catch (e) {
      console.log(e);
      this.showLoader = false;
    }
    console.log(res);
    this.showLoader = false;

    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(JSON.stringify(res));
      return;
    }

    this.dataService.okDialog('הפעולה בוצעה בהצלחה');

  }

  search(autoCompleteItem) {

  }

  async downloadPriceCompareExcel(changesOnly = false) {
    this.showLoader = true;
    await this.httpService.getComparePriceExcel(this.supplierId, changesOnly);
    this.showLoader = false;
  }

  async downloadInventory() {
    this.showLoader = true;
    await this.httpService.downloadInventoryExcel(this.supplierId);
    this.showLoader = false;
  }

  onSearchChanged(value) {
    // console.log(value);
    this.updateFilter();
  }

  private updateFilter() {
    this.filteredProducts = [];
    if (!this.searchBox || this.searchBox.toString().trim().length == 0) {
      // this.filteredCategories = [...this.allCategories];
      return;
    }

    console.log('filter is: ' + this.searchBox);

    this.allCategories.forEach(c => {
      if (!c.products || c.products.length === 0) {
        return;
      }

      // console.log('here: ' + c.name);
      c.products.forEach(p => {
        if (p.name.includes(this.searchBox)) {
          p.categoryId = c.id;
          this.filteredProducts.push(p);
          return;
        }

        if ((p.barcode || '').includes(this.searchBox)) {
          this.filteredProducts.push(p);
          return;
        }
      });
    });


  }


  formatPrice(p) {
    if (p === undefined) {
      return '';
    }
    if (p === 0) {
      return '0₪';
    }
    return p.toFixed(2) + '₪';
  }

  afterProductDeleted(product) {
    this.updateFilter();
  }

  test() {
    console.log('test');
  }


}
