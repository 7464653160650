import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.scss']
})
export class UserProfileDialogComponent extends BaseDialogComponent<UserProfileDialogComponent> {

  userId;
  user;
  details;

  detailedListFuturePayments = [];
  detailedListIncomingPayments = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UserProfileDialogComponent>, public httpService: HttpService,
              public snackBar: MatSnackBar, public dialog: MatDialog,public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog,dataService);

    this.userId = data.userId;
    // this.user = JSON.parse(JSON.stringify(data.user));
  }

  async ngOnInit() {
    const userData = await this.httpService.getOneCustomer(this.userId);
    if (!userData || !userData['result']) {
      alert('אירעה שגיאה');
      this.dialogRef.close();
      return;
    }

    this.user = userData['result'].list[0];
    console.log(this.user);

    const res = await this.httpService.getCustomerStatus(this.userId);
    this.details = res['result'];


    // init payments and returns
    if (!this.user.payments) {
      this.user.payments = {
        total: 0,
        list: []
      };
    }

    if (!this.user.returns) {
      this.user.returns = {
        total: 0,
        list: []
      };
    }
  }

  //972502769823

  async save() {
    console.log(this.user);
    const res = await this.httpService.updateCustomer(this.user);
    console.log(res);
    this.dialogRef.close(res);
  }

  async onOpened(item, isIncomingPayment) {
    if (isIncomingPayment) {
      this.detailedListIncomingPayments = [];
    } else {
      this.detailedListFuturePayments = [];
    }
    // console.log(item);
    const date = new Date(item.date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    var res = await this.httpService.getFuturePaymentPerMonth(year, month, this.userId, isIncomingPayment);
    if (isIncomingPayment) {
      this.detailedListIncomingPayments = res['result'];
    } else {
      this.detailedListFuturePayments = res['result'];
    }
    console.log(res);
  }
}
