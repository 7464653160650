import {OnInit} from '@angular/core';
import {HttpService} from '../../../service/http/http.service';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {OkDialogComponent} from '../../dialog/ok-dialog/ok-dialog.component';
import {DataService} from '../../../service/data/data.service';


export class BaseDialogComponent<T> implements OnInit {

  error = '';
  isNew;
  showLoader = false;


  constructor(public httpService: HttpService, public dialogRef: MatDialogRef<T>, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
  }

  ngOnInit() {
  }


  showSnackBar(message: string) {
    /*this.snackBar.open(message, '', {
      duration: 2000,
      verticalPosition: 'top'
    });*/

    this.showOkDialog(message, true);
  }

  async showOkDialog(message, isError) {
    const dialogRef = this.dialog.open(OkDialogComponent, {
      width: '50vw',
      data: {
        message,
        isError
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
  }


  formatDate(d: any) {
    try {
      if (!d) {
        return '-';
      }
      const date = new Date(d);
      const day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
      const month = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)));

      const answer = day + '/' + month + '/' + date.getFullYear();
      return answer;
    } catch (e) {
      return '-';
    }
  }

  formatPrice(p) {
    if (p === undefined) {
      return '';
    }
    if (p === 0) {
      return '0₪';
    }
    return p.toFixed(2) + '₪';
  }

  getFullName(name) {
    if (!name) {
      return '';
    }
    if (!name.first || !name.last) {
      return '';
    }
    return name.first + ' ' + name.last;
  }

  close(result = undefined) {
    this.dialogRef.close(result);
  }


}
