import {Component, HostListener, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';
import {UserProfileDialogComponent} from '../../dialog/user-profile-dialog/user-profile-dialog.component';
import {MatDialog} from '@angular/material';
import {RefundDialogComponent} from '../../dialog/refund-dialog/refund-dialog.component';

@Component({
  selector: 'app-offline-orders',
  templateUrl: './offline-orders.component.html',
  styleUrls: ['./offline-orders.component.scss']
})
export class OfflineOrdersComponent extends BaseListComponent {


  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dataService: DataService, dialog: MatDialog) {
    super(httpService, activatedRoute, dataService, dialog);
  }


  async loadData() {
    if (this.isExternalData) {
      return;
    }

    super.loadData();
    // console.log('loading data in offline orders with filter', this.filters);
    const res = await this.httpService.getOfflineOrders(this.sortValue, this.filters);
    this.checkResults(res);
    console.log(res);
    this.results = this.results || [];
    this.results.push(...res['result'].list);
    this.total = res['result'].total;
    this.afterLoadData(res['result'].list.length);
  }

  showUserProfileDialog(userId) {
    const dialogRef = this.dialog.open(UserProfileDialogComponent, {
      width: '1200px',
      height: '80vh',
      data: {
        userId
      }
    });

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('after close', result);
      if (!result) {
        return;
      }
      this.loadData();
    });
  }

  async createExcel() {
    console.log('test');
    this.showLoader = true;
    await this.httpService.getOfflineOrdersExcel(this.sortValue, this.filters, 'offline orders');
    this.showLoader = false;
    // console.log(res);
  }

  async showRefundDialog(item) {
    const dialogRef = this.dialog.open(RefundDialogComponent, {
      width: '90vw',
      maxWidth: '600px',
      height: '40vh',
      panelClass: 'dialog-style',
      data: {
        item,
        offlineOrderId: item.id
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    console.log('after close', result);
    if (!result) {
      return;
    }

    this.showOkDialog('הזיכוי בוצע בהצלחה');
    // this.loadData();
  }

  /* search(value) {
     console.log(value);
     if (!value) {
       return;
     }

     this.resetData();
     this.addFilter('search', value, 'חיפוש - ' + value);
     this.loadData();
   }*/

  getClassName = (): String => 'OfflineOrdersComponent';

  hasInvoice(result) {
    return !!result.invoice.link;
  }

  hasRefund(result) {
    return result.refunds.length > 0;
  }
}

