import {Component, OnInit} from '@angular/core';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../service/data/data.service';
import {MatDialog} from '@angular/material';
import {EditSupplierBankTransferComponent} from '../../dialog/edit-supplier-bank-transfer/edit-supplier-bank-transfer.component';
import {SupplierTransferDetailsComponent} from '../../dialog/supplier-transfer-details/supplier-transfer-details.component';
import {Utils} from '../../../util/utils';

@Component({
  selector: 'app-supplier-payments',
  templateUrl: './supplier-payments.component.html',
  styleUrls: ['./supplier-payments.component.scss']
})
export class SupplierPaymentsComponent extends BaseListComponent {

  fileToUpload;

  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dataService: DataService, dialog: MatDialog) {
    super(httpService, activatedRoute, dataService, dialog);
  }


  async loadData() {
    super.loadData();

    const res = await this.httpService.getSupplierPayments(this.sortValue, this.filters);
    console.log(res);
    this.checkResults(res);
    this.results = this.results || [];
    this.results = this.results.concat(res['result'].list);
    this.total = res['result'].total;
    this.afterLoadData(res['result'].list.length);
  }

  handleFileInput(file: any) {
    console.log('handleFileInput', file);
    if (file) {
      this.fileToUpload = file;
      this.uploadFile();
    }
    console.log(file);
  }

  async uploadFile() {
    console.log('upload');
    if (!this.fileToUpload) {
      return;
    }

    const res = await this.httpService.uploadSupplierPaymentsFile(this.fileToUpload);
    console.log(res);
  }

  async createExcel() {
    this.showLoader = true;
    await this.httpService.getSupplierPaymentsExcel(this.sortValue, this.filters, 'supplierPayments');
    // console.log(res);
    this.showLoader = false;

  }

  formatStatus(status): string {
    switch (status) {
      case 'pending':
        return 'ממתין לאסמכתא';
      case 'completed':
        return 'שולם';
      case 'rejected':
        return 'נדחה';
      case 'open':
        return 'פתוח';
    }
    return '';
  }

  async showDialog(transfer) {

    if (transfer.status === 'open') {
      this.dataService.okDialog('לא ניתן לערוך תשלום פתוח');
      return;
    }

    console.log(transfer);

    const dialogRef = this.dialog.open(EditSupplierBankTransferComponent, {
      width: '90vw',
      maxWidth: '600px',
      height: '80vh',
      panelClass: 'dialog-style',
      data: {
        transfer
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    // console.log('after close', result);
    if (!result) {
      return;
    }

    if (transfer != null) {
      Object.assign(transfer, result);
    }

    this.loadData();
  }

  async showPaymentIngredients(transfer) {

    this.dialog.open(SupplierTransferDetailsComponent, {
      width: '90vw',
      height: '80vh',
      panelClass: 'dialog-style',
      data: {
        transfer
      }
    });

    // this.httpService.updateSupplierPaymentTransferDetails(payment.id);
  }

  getClassName = (): String => 'SupplierPaymentsComponent';

  async openClose(transfer, isOpen) {
    let pos = this.results.indexOf(transfer);
    //console.log('index', pos);
    // console.log(transfer);
    let body = isOpen ? {
      supplierBankTransferId: transfer.id,
      open: true
    } : {
      supplierBankTransferId: transfer.id,
      close: true
    };

    const res = await this.httpService.updateSupplierPaymentTransfer(body);
    const item = res['result'];
    // console.log(item);
    if (!Utils.hasError(res)) {
      // console.log('new val');
      this.results[pos] = item;
      //  transfer = res['result'];
    }
    // await this.loadData();
  }
}
