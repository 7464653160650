<app-loader *ngIf="showLoader"></app-loader>

<div class="container">


  <div *ngIf="false" class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div class="row mt-3" style="direction: rtl">
    <div class="col p-0 m-0">
      <div class="d-flex flex-row" style="direction: rtl">
        <input class="form-control ml-2 w-25" type="text" placeholder="חפש או הזן טלפון חדש" [(ngModel)]="filter">
        <button (click)="addNumber()" class="btn btn-success">הוסף מספר</button>
      </div>
    </div>

    <app-search *ngIf="false" hint="הזן שם לקוח" suppliers=false (itemSelected)="search($event)"></app-search>

  </div>

  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">
 <span style="direction: rtl">
          <label class="ml-3">{{total.count}} עסקאות </label>
          <label class="ml-3">{{formatPrice(total.amount)}}</label>
    </span>
    <span style="cursor: pointer">
       <label style="color: #107C41; cursor: pointer">שמור כקובץ אקסל</label>
       <img src="assets/excel.png" width="35px" height="50px">
    </span>

  </div>

  <div>
    <table *ngIf="results" class="table table-hover mt-3" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>#</th>
        <th scope="col">תמונה</th>
        <th>שם</th>
        <th>טלפון</th>
        <th scope="col">פעולות</th>
      </tr>
      </thead>
      <tbody class="text-right">
      <tr *ngFor="let result of getFilteredList(); let i = index">
        <td>{{i}}</td>
        <td><img height="60" width="60" [src]="result.imageUrl || '/assets/logo.png'" class="img rounded"></td>
        <td>{{getFullName(result.name)}}</td>
        <td>{{result.phone}}</td>
        <td>
          <div class="d-flex flex-row mt-2">
            <mat-icon (click)="deleteNumber(result)" class="text-danger" style="cursor: pointer">delete_outline
            </mat-icon>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
