import {Component, OnInit} from '@angular/core';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material';
import {DataService} from '../../../service/data/data.service';
import {EditSupplierDialogComponent} from '../../dialog/edit-supplier-dialog/edit-supplier-dialog.component';
import {ImageDialogComponent} from '../../views/image-dialog/image-dialog.component';
import {CreateCampaignDialogComponent} from '../../dialog/create-campaign-dialog/create-campaign-dialog.component';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent extends BaseListComponent implements OnInit {

  openBalance;
  fileToUpload;


  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dialog: MatDialog, dataService: DataService) {
    super(httpService, activatedRoute, dataService, dialog);
  }


  ngOnInit() {
    super.ngOnInit();

    // no date filter on customers list
    delete this.filters['startDate'];
    delete this.filters['endDate'];
  }

  async loadData() {
    super.loadData();
    console.log('calling get suppliers with filters', this.filters);
    const res = await this.httpService.getSuppliers(this.sortValue, this.filters);
    this.checkResults(res);
    this.results = this.results || [];
    this.results = this.results.concat(res['result'].list);
    this.total = res['result'].total;

    console.log(res);
    // this.openBalance = res['result'].openBalance;
    // this.afterLoadData(res['result'].list.length);
    this.afterLoadData(this.results.length);

  }

  handleFileInput(file: any) {
    // console.log(file);
    if (file) {
      this.fileToUpload = file;
      this.uploadExcelFile();
    }
    //  console.log(file);
  }

  async uploadExcelFile() {
    console.log('upload');
    if (!this.fileToUpload) {
      return;
    }
    this.showLoader = true;
    const res = await this.httpService.uploadSuppliersFile(this.fileToUpload);
    console.log(res);
    this.showLoader = false;

    const status: string = res['status'];
    if (status.toLowerCase() !== 'ok') {
      this.dataService.okDialog(JSON.stringify(res));
      return;
    }
  }


  showSupplierDialog(supplier) {

    const dialogRef = this.dialog.open(EditSupplierDialogComponent, {
      width: '90vw',
      maxWidth: '600px',
      height: '80vh',
      panelClass: 'dialog-style',
      data: {
        supplier
      }
    });

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('after close', result);
      if (!result) {
        return;
      }

      if (supplier != null) {
        Object.assign(supplier, result);
      }

      this.loadData();
    });
  }

  async uploadFile(file, supplier) {
    // const file = event.target.files[0];
    // console.log(event.target.files[0]);
    const res = await this.httpService.updateSupplierImage(supplier.id, file);
    console.log(res);
    const status: string = res['status'];
    if (status.toLowerCase() != 'ok') {
      return;
    }
    supplier.image = res['result']['images']['thumbUrl'];

  }

  getClassName = (): String => 'SuppliersComponent';

  async createExcel() {
    this.showLoader = true;
    await this.httpService.getSuppliersExcel(this.sortValue, this.filters, 'suppliersList');
    // console.log(res);
    this.showLoader = false;
  }

  async openImageDialog(supplier) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: supplier.image
      }
      // width: '0vw',
      // maxWidth: '600px',
      // height: '80vh',
      // panelClass: 'dialog-style',
    });

    const file = await dialogRef.afterClosed().toPromise();
    if (!file) {
      return;
    }

    console.log(file);
    this.uploadFile(file, supplier);

  }

  getChainName(result) {
    if (!result || !result.chain) {
      return '';
    }
    return result.chain.hebrewName;
  }

  async createCampaign(supplier) {
    let chain = localStorage.getItem('chain');
    console.log(chain);
    const dialogRef = this.dialog.open(CreateCampaignDialogComponent, {
      width: '90vw',
      maxWidth: '700px',
      panelClass: 'dialog-style-no-height',
      data: {
        supplierId: supplier.id,
        isOffline: true
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    console.log('after close', result);
    if (!result) {
      return;
    }
  }
}
