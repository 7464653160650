import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent extends BaseListComponent implements OnInit {


  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dialog: MatDialog, dataService: DataService) {
    super(httpService, activatedRoute, dataService, dialog);
  }


  ngOnInit() {
    super.ngOnInit();

    // no date filter on customers list
    delete this.filters['startDate'];
    delete this.filters['endDate'];
  }

  async loadData() {
    super.loadData();
    const res = await this.httpService.getCustomers(this.sortValue, this.filters);
    this.checkResults(res);
    this.results = this.results || [];
    this.results = this.results.concat(res['result'].list);
    this.total = res['result'].total;
    this.afterLoadData(res['result'].list.length);
  }

  getAddressText(address) {
    if (!address || !address.city || !address.street) {
      return 'אין כתובת';
    }
    return address.street + ', ' + address.city;
  }



  getUserPayments(user) {
    if (!user) {
      return 0;
    }
    if (!user.payments) {
      user.payments = {
        total: 0,
        list: []
      };
      return 0;
    }

    return user.payments.total;
  }

  getUserReturns(user) {
    if (!user) {
      return 0;
    }
    if (!user.returns) {
      user.returns = {
        total: 0,
        list: []
      };
      return 0;
    }

    return user.returns.total || 0;
  }

  getClassName = (): String => 'CustomersComponent';

}
