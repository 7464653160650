import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-find-supplier',
  templateUrl: './find-supplier.component.html',
  styleUrls: ['./find-supplier.component.scss']
})
export class FindSupplierComponent extends BaseDialogComponent<FindSupplierComponent> {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<FindSupplierComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

  }

  ngOnInit() {
  }


  search(event) {
    console.log(event);
    if (!event) {
      return;
    }

    this.dialogRef.close(event.id);
  }
}
