
<div class="d-flex flex-column align-items-center justify-content-center mt-2 p-4">
  {{supplierName}}
</div>
<mat-tab-group class="p-3" mat-stretch-tabs selectedIndex="1" >
  <mat-tab label="קמפיינים הביט עבור חלוקה">
    <app-distribution-campaign-list [supplierId]="supplierId" [supplierName]="supplierName"></app-distribution-campaign-list>
  </mat-tab>

  <mat-tab label="קמפיינים עבור ספקים">
    <app-campaigns-list [supplierId]="supplierId" [supplierName]="supplierName" ></app-campaigns-list>
  </mat-tab>


</mat-tab-group>
