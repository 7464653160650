import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BaseListComponent} from '../../base/base-list/base-list.component';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';
import {MatDialog} from '@angular/material';
import {RefundDialogComponent} from '../../dialog/refund-dialog/refund-dialog.component';

@Component({
  selector: 'app-online-orders',
  templateUrl: './online-orders.component.html',
  styleUrls: ['./online-orders.component.scss']
})
export class OnlineOrdersComponent extends BaseListComponent {


  constructor(httpService: HttpService, activatedRoute: ActivatedRoute, dataService: DataService, dialog: MatDialog) {
    super(httpService, activatedRoute, dataService, dialog);
  }


  async loadData() {
    if (this.isExternalData) {
      return;
    }
    super.loadData();
    const res = await this.httpService.getOnlineOrders(this.sortValue, this.filters);
    this.checkResults(res);
    const newList = res['result'].list;
    console.log('new Data', newList);
    this.results = this.results || [];
    this.results = this.results.concat(newList);
    this.total = res['result'].total;
    this.afterLoadData(res['result'].list.length);
  }


  getSupplierNames(suppliers) {
    let answer = '';
    suppliers.forEach(s => answer += s.name + ', ');
    return answer;
  }


  async showRefundDialog(item) {
    console.log(item);
    const dialogRef = this.dialog.open(RefundDialogComponent, {
      width: '90vw',
      maxWidth: '600px',
      height: '40vh',
      panelClass: 'dialog-style',
      data: {
        item,
        onlineOrderId: item.id,
        isOnline: true
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    console.log('after close', result);
    if (!result) {
      return;
    }

    this.showOkDialog('הזיכוי בוצע בהצלחה');
    // this.loadData();
  }

  /* search(value) {
     if (!value) {
       return;
     }

     this.resetData();
     // this.addFilter('supplierName', value, 'שם ספק - ' + value);
     this.loadData();
   }*/

  getClassName = (): String => 'OnlineOrdersComponent';

}
