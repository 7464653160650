import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LogsComponent} from './components/logs/logs.component';
import {environment} from '../environments/environment.prod';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatAutocompleteModule,
  MatButtonModule, MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule, MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule, MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule
} from '@angular/material';
import {HttpService} from './service/http/http.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {ChartsModule} from 'ng2-charts';
import {LoginComponent} from './components/login/login.component';
import {MainComponent} from './components/main/main.component';
import {UserProfileDialogComponent} from './components/dialog/user-profile-dialog/user-profile-dialog.component';
import {OfflineOrdersComponent} from './components/lists/offline-orders/offline-orders.component';
import {OnlineOrdersComponent} from './components/lists/online-orders/online-orders.component';
import {UserToUserComponent} from './components/lists/user-to-user/user-to-user.component';
import {BankTransfersComponent} from './components/lists/bank-transfers/bank-transfers.component';
import {LeverageListComponent} from './components/lists/leverage-list/leverage-list.component';
import {CustomersComponent} from './components/lists/customers/customers.component';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {PaymentsManagementComponent} from './components/payments-management/payments-management.component';
import {SupplierPaymentsComponent} from './components/lists/supplier-payments/supplier-payments.component';
import {SuppliersComponent} from './components/lists/suppliers/suppliers.component';
import {EditSupplierDialogComponent} from './components/dialog/edit-supplier-dialog/edit-supplier-dialog.component';
import {LoaderComponent} from './components/views/loader/loader.component';
import {ScrollContainerComponent} from './components/views/scroll-container/scroll-container.component';
import {UsersLocationComponent} from './components/users-location/users-location.component';
import {AgmCoreModule} from '@agm/core';
import {ChainsComponent} from './components/lists/chains/chains.component';
import {EditChainDialogComponent} from './components/dialog/edit-chain-dialog/edit-chain-dialog.component';
import {EditBankTransferComponent} from './components/dialog/edit-bank-transfer/edit-bank-transfer.component';
import {EditSupplierBankTransferComponent} from './components/dialog/edit-supplier-bank-transfer/edit-supplier-bank-transfer.component';
import {RefundDialogComponent} from './components/dialog/refund-dialog/refund-dialog.component';
import {OkDialogComponent} from './components/dialog/ok-dialog/ok-dialog.component';
import {DialogLoaderComponent} from './components/views/dialog-loader/dialog-loader.component';
import {HttpConfigInterceptor} from './interceptor/http-interceptor';
import {YesNoDialogComponent} from './components/dialog/yes-no-dialog/yes-no-dialog.component';
import {SupplierTransferDetailsComponent} from './components/dialog/supplier-transfer-details/supplier-transfer-details.component';
import {SearchComponent} from './components/views/search/search.component';
import {WhiteListComponent} from './components/lists/white-list/white-list.component';
import {TransactionsComponent} from './components/lists/transactions/transactions.component';
import {EditOnlineStoreComponent} from './components/edit-online-store/edit-online-store.component';
import {CategoryComponent} from './components/views/category/category.component';
import {EditCreateCategoryComponent} from './components/dialog/edit-create-category/edit-create-category.component';
import {EditCreateProductComponent} from './components/dialog/edit-create-product/edit-create-product.component';

import {ImageDialogComponent} from './components/views/image-dialog/image-dialog.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {HabitProductsListComponent} from './components/lists/habit-products-list/habit-products-list.component';
import {EditHabitProductDialogComponent} from './components/dialog/edit-habit-product-dialog/edit-habit-product-dialog.component';
import {ImageSearchDialogComponent} from './components/dialog/image-search-dialog/image-search-dialog.component';
import {FuturePaymentsComponent} from './components/lists/future-payments/future-payments.component';
import {CreateCampaignDialogComponent} from './components/dialog/create-campaign-dialog/create-campaign-dialog.component';
import {CampaignsListComponent} from './components/lists/campaigns-list/campaigns-list.component';
import {DistributionCampaignListComponent} from './components/lists/distribution-campaign-list/distribution-campaign-list.component';
import {CampaignsHolderComponent} from './components/lists/campaigns-holder/campaigns-holder.component';
import {CreateDistributionCampaignDialogComponent} from './components/dialog/create-distribution-campaign-dialog/create-distribution-campaign-dialog.component';
import {DateTimePickerModule} from '@syncfusion/ej2-angular-calendars';
import {InvitesComponent} from './components/lists/invites/invites.component';
import {InviteesListDialogComponent} from './components/dialog/invitees-list-dialog/invitees-list-dialog.component';
import {CampaignReachoutListDialogComponent} from './components/dialog/campaign-reachout-list-dialog/campaign-reachout-list-dialog.component';
import { ImageUploadComponent } from './components/views/image-upload/image-upload.component';
import { HabitCategoriesComponent } from './components/lists/habit-categories/habit-categories.component';
import { FindSupplierComponent } from './components/dialog/find-supplier/find-supplier.component';
import { ProductComponent } from './components/views/product/product.component';
import { SupplierViewComponent } from './components/views/supplier-view/supplier-view.component';

export const firebaseConfig = environment.config;


@NgModule({
  declarations: [
    AppComponent,
    LogsComponent,
    DashboardComponent,
    OfflineOrdersComponent,
    OnlineOrdersComponent,
    UserToUserComponent,
    BankTransfersComponent,
    LeverageListComponent,
    CustomersComponent,
    LoginComponent,
    MainComponent,
    UserProfileDialogComponent,
    DatePickerComponent,
    PaymentsManagementComponent,
    SupplierPaymentsComponent,
    SuppliersComponent,
    EditSupplierDialogComponent,
    LoaderComponent,
    ScrollContainerComponent,
    UsersLocationComponent,
    ChainsComponent,
    EditChainDialogComponent,
    EditBankTransferComponent,
    EditSupplierBankTransferComponent,
    RefundDialogComponent,
    OkDialogComponent,
    DialogLoaderComponent,
    DialogLoaderComponent,
    YesNoDialogComponent,
    SupplierTransferDetailsComponent,
    SearchComponent,
    WhiteListComponent,
    TransactionsComponent,
    EditOnlineStoreComponent,
    CategoryComponent,
    EditCreateCategoryComponent,
    EditCreateProductComponent,
    ImageDialogComponent,
    HabitProductsListComponent,
    EditHabitProductDialogComponent,
    ImageSearchDialogComponent,
    FuturePaymentsComponent,
    CreateCampaignDialogComponent,
    CampaignsListComponent,
    DistributionCampaignListComponent,
    CampaignsHolderComponent,
    CreateDistributionCampaignDialogComponent,
    InvitesComponent,
    InviteesListDialogComponent,
    CampaignReachoutListDialogComponent,
    ImageUploadComponent,
    HabitCategoriesComponent,
    FindSupplierComponent,
    ProductComponent,
    SupplierViewComponent,
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBdVmZNxHQvJ01dKof10SAexPuHKXGWgzk'
    }),
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSelectModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatAutocompleteModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    HttpClientModule,
    ChartsModule,
    InfiniteScrollModule,
    ImageCropperModule,
    MatCheckboxModule,
    DateTimePickerModule
  ],
  providers: [HttpService, {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpConfigInterceptor,
    multi: true
  }],
  entryComponents: [UserProfileDialogComponent, EditSupplierDialogComponent, EditChainDialogComponent, EditBankTransferComponent,
    EditSupplierBankTransferComponent, RefundDialogComponent, OkDialogComponent, YesNoDialogComponent, SupplierTransferDetailsComponent, EditCreateCategoryComponent, EditCreateProductComponent, ImageDialogComponent,
    EditHabitProductDialogComponent, ImageSearchDialogComponent, CreateCampaignDialogComponent, CreateDistributionCampaignDialogComponent,
    InviteesListDialogComponent, CampaignReachoutListDialogComponent,FindSupplierComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
