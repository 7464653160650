import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../base/base-dialog/base-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {HttpService} from '../../../service/http/http.service';
import {DataService} from '../../../service/data/data.service';

@Component({
  selector: 'app-invitees-list-dialog',
  templateUrl: './invitees-list-dialog.component.html',
  styleUrls: ['./invitees-list-dialog.component.scss']
})
export class InviteesListDialogComponent extends BaseDialogComponent<InviteesListDialogComponent> {

  inviteItem;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InviteesListDialogComponent>,
              public httpService: HttpService, public snackBar: MatSnackBar, public dialog: MatDialog, public dataService: DataService) {
    super(httpService, dialogRef, snackBar, dialog, dataService);

    this.inviteItem = data.inviteItem;
    console.log(this.inviteItem);


   /* customer: {name: {…}, id: "5f901bd0b1e7490506a8b1df"}
    joined: "2020-10-21T11:30:24.274Z"
    rewardDate: "2020-10-21T11:30:29.766Z"
    rewardGiven: 12
    totalPayments: 0
    _id: "5f901bd0b1e7490506a8b1e0"*/
  }

  async ngOnInit() {

  }

}
