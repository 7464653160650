<div class="d-flex flex-row justify-content-center align-items-center">
  <mat-form-field style="width: 20em;" class="text-right">
    <input [(ngModel)]="searchValue" type="text" matInput
           [placeholder]="hint" class="text-right"
           (input)="onChange(value.value)" #value
           (keydown.arrowDown)="selectedPos  = selectedPos + 1;"
           (keydown.arrowUp)="selectedPos = selectedPos - 1;"
           (keydown.enter)="onEnterClicked(value.value)"
           [matAutocomplete]="auto">
  </mat-form-field>

  <mat-icon *ngIf="false" (click)="onEnterClicked(value.value)" class="">search</mat-icon>

</div>


<mat-autocomplete #auto="matAutocomplete" (change)="test('change')" (optionSelected)="test('optionSelected')">
  <mat-option *ngFor="let option of options"
              [value]="option.name"
              (onSelectionChange)="onItemSelected($event,option)">
    <div class="d-flex flex-row" style="direction: rtl">
      <div>
        <img height="30" width="30" [src]="(products? option.images.thumbUrl : option.imageUrl) || '/assets/logo.png'"
             class="img rounded ml-2">
      </div>
      <div class="h6 d-flex flex-column align-items-start justify-content-center">
        <p class="h6  m-0 p-0">{{option.name}}</p>
        <p *ngIf="option.phone" class="m-0 p-0 small">{{option.phone}}</p>
      </div>
      <p *ngIf="!products" class="m-0 p-0 flex-grow-1">{{getTypeText(option)}}</p>
    </div>

  </mat-option>
</mat-autocomplete>


<!--<div class="d-flex flex-row" style="direction: rtl">
  <input (keydown.enter)="search(searchField.value)" class="form-control ml-2 w-25" type="text"
         placeholder="חיפוש חופשי" #searchField>
  <button (click)="search(searchField.value)" class="btn btn-primary">חפש</button>
</div>-->
