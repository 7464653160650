<app-loader *ngIf="showLoader"></app-loader>

<div #container class="">

  <div class="row p-2  mt-1">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of getChipFilters() | keyvalue" [removable]="true" [selectable]="true"
                (removed)="toggleFilter(filter.key,filter.value,'',true)">
        {{filter.value.present}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div *ngIf="total" class="d-flex flex-row justify-content-between align-items-end" style="direction: rtl">


    <span style="direction: rtl">
          <label class="ml-3">{{total.count}} קמפיינים </label>
          <label class="ml-3">{{formatPrice(total.amount)}}</label>
    </span>
    <span *ngIf="false" style="cursor: pointer">
       <label style="color: #107C41; cursor: pointer">שמור כקובץ אקסל</label>
       <img src="assets/excel.png" width="35px" height="50px">
    </span>

  </div>

  <div class="row p-3" style="direction: rtl">
    <div class="d-flex flex-row align-items-end justify-content-end">
      <button *ngIf="!isAdmin() || supplierId" (click)="showCreateCampaignDialog(true,null)"
              class="btn btn-sm btn-primary ml-3">צור קמפיין קניות בחנות
      </button>
      <button *ngIf="!isAdmin() || supplierId" (click)="showCreateCampaignDialog(false,null)"
              class="btn btn-sm btn-primary">צור קמפיין קניות אונליין
      </button>
    </div>

  </div>


  <div>
    <table *ngIf="results" class="table table-hover mt-3" style="direction: rtl">
      <thead class="text-right">
      <tr>
        <th>#</th>
        <th scope="col">תמונה</th>
        <th scope="col">ספק</th>
        <th (click)="setSortValue('created')" scope="col">
          <span *ngIf="sortValue === 'created'">↑</span>
          <span *ngIf="sortValue === '-created'">↓</span>
          נוצר
        </th>
        <th scope="col">סוג קמפיין</th>
        <th scope="col">זמן התחלה</th>
        <th scope="col">זמן סיום</th>
        <th scope="col">עמלת הביט</th>
        <th scope="col">אחוז מימון</th>
        <th scope="col">פעולות</th>
      </tr>
      </thead>
      <tbody class="text-right">
      <tr *ngFor="let result of results; let i = index">
        <td>{{i}}</td>
        <td>

          <app-image-upload [src]="result.imageUrl" (onImageSelected)="uploadCampaignImage($event,result.supplier.id,result)" (onImageDelete)="deleteImage(result)"></app-image-upload>

          <!--<img (click)="openImageDialogForCampaign(result.supplier.id,result)" height="80" width="80"
               [src]="result.imageUrl || '/assets/no-image.png'"
               class="img rounded mt-3">-->
        </td>
        <td (click)="toggleFilter('supplierId', result.supplier.id,'ספק - '+result.supplier.name)">
          <div class="d-flex flex-column justify-content-center">
            <img style="height: auto; width: 50px" [src]="result.supplier.images?.thumbUrl" class="img rounded ml-3">
            <p>{{result.supplier.name}}</p>
          </div>
        </td>
        <td style="direction: ltr">{{getDateStr(result.created,true)}}</td>
        <td>{{(result.onlineDiscount && result.onlineDiscount.length > 0) ? 'קמפיין קניות אונליין' : 'קמפיין קניות בחנות'}}</td>
        <td style="direction: ltr">{{getDateStrWithTime(result.startDate,true)}}</td>
        <td style="direction: ltr">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <p *ngIf="false" class="m-0">{{getTimeToFinish(result)}}</p>
            {{getDateStrWithTime(result.endDate,true)}}
            <mat-progress-bar *ngIf="false" mode="determinate" value="{{getBarValue(result)}}"></mat-progress-bar>
          </div>

        </td>
        <td>{{math.round(result.offlineDiscount * 100)}}%</td>
        <td>{{math.round(result.futurePaymentsPercentage * 100)}}%</td>
        <td>
          <div class="d-flex flex-row mt-2">
            <mat-icon (click)="showCreateCampaignDialog(true,result)" style="cursor: pointer">edit</mat-icon>
            <mat-icon class="text-danger mr-3" (click)="delete(result)" style="cursor: pointer">delete</mat-icon>
            <button *ngIf="!isSupplier()" (click)="showSupplierDialog(result.supplier.id)"
                    class="btn btn-sm btn-primary">פרטי עסק
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <button (click)="loadData()" *ngIf="hasPagination" class="btn btn-sm btn-primary btn-block">טען עוד</button>
</div>
